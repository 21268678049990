import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DeviceService } from 'src/app/core/services/device.service';
import { MedicationService } from 'src/app/core/services/medication.service';
import { PatientService } from 'src/app/core/services/patient.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-patient-association-dialog',
  templateUrl: './patient-association-dialog.component.html',
  styleUrls: ['./patient-association-dialog.component.css'],
})
export class PatientAssociationDialogComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<PatientAssociationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public selectedPatientDetails: any,
    private _deviceService: DeviceService,
    private _medicationService: MedicationService,
    private _patientService: PatientService,
    private _langService: TranslateService,
    private _snackBar: MatSnackBar
  ) {
    this._langService
      .get([
        'GLOBAL.Select_Field_Alert',
        'GLOBAL.Patient_Association_Alert_Message',
        'GLOBAL.Association_Failed_Alert_Msg',
      ])
      .subscribe((ln) => {
        this.Select_Field_Alert =
          ln['GLOBAL.Patient_Reassignment_Alert_Message'];
        this.Patient_Association_Alert_Message =
          ln['GLOBAL.Patient_Association_Alert_Message'];
        this.Association_Failed_Alert_Msg =
          ln['GLOBAL.Association_Failed_Alert_Msg'];
      });
  }

  Select_Field_Alert;
  Patient_Association_Alert_Message;
  Association_Failed_Alert_Msg;

  patientOptions: any[] = [];
  deviceOptions: any[] = [];
  medicationOptions: any[] = [];
  selectedPatient: any = this.selectedPatientDetails.patient;
  selectedDevice: any = {};
  selectedMedication: any = {};
  selectedDrugSrc: any = '';
  patientFormControl = new FormControl({
    value: this.selectedPatient,
  });
  filteredPatientOptions: Observable<any[]>;
  medicationForpatient: Observable<any[]>;
  deviceFormControl = new FormControl();
  filteredDeviceOptions: Observable<any[]>;
  medicationFormControl = new FormControl({
    value: '',
    disabled: !this.selectedPatient,
  });
  DrugTypeFormControl = new FormControl({
    value: '',
    disabled: !this.selectedPatient,
  });
  filteredMedicationOptions: Observable<any[]>;
  medicationValues: Array<any>;
  medicatiopnTypeValues: Array<any>;

  ngOnInit(): void {
    this._deviceService.deviceListObs.subscribe((devices) => {
      this.deviceOptions = devices;
      this.filteredDeviceOptions = this.deviceFormControl.valueChanges.pipe(
        startWith(''),
        map((value) => this._filter3(value))
      );

      var resp = 'test';
    });

    this._patientService.patientListObs.subscribe((patients) => {
      this.patientOptions = patients.assigned; //change it to unassigned
      this.filteredPatientOptions = this.patientFormControl.valueChanges.pipe(
        startWith(''),
        map((value) => this._filter1(value))
      );
    });

    this._medicationService.getReferenceForMedicationSourse(
      'MEDICATION_SOURCE'
    );

    this._medicationService.DrugSrcListObs.subscribe((values) => {
      console.log('mediaction sourse values ', values);
      this.medicatiopnTypeValues = values;
      console.log('medication sours==ce ', this.medicatiopnTypeValues);
    });
  }

  savePatientAssociation() {
    if (
      !this.selectedPatient ||
      !this.selectedDevice ||
      !this.selectedMedication
    ) {
      this._snackBar.open(this.Select_Field_Alert);
      return;
    }

    const requestData = {
      patient_id: this.selectedPatient.id,
      device_id: this.selectedDevice.id,
      id: this.selectedMedication.id,
      type: this.selectedDrugSrc,
    };

    this._patientService.associatePatient(requestData).subscribe(
      (result) => {
        if (result) {
          alert(this.Patient_Association_Alert_Message);
          return;
        }

        alert(this.Association_Failed_Alert_Msg);
      },
      (err) => {
        alert('Association Failed');
      }
    );
  }

  private _filter1(value: any): any[] {
    if (value) {
      let filterValue = '';
      if (typeof value === 'string') {
        filterValue = value.toLowerCase();
      } else {
        filterValue = value.patient_name.toLowerCase();
      }

      return this.patientOptions.filter((option) =>
        option.patient_name.toLowerCase().includes(filterValue)
      );
    }

    return this.patientOptions;
  }

  private _filter2(value: any): any[] {
    if (value) {
      let filterValue = '';
      if (typeof value === 'string') {
        filterValue = value.toLowerCase();
      } else {
        filterValue = value.drug_displaytext.toLowerCase();
      }

      return this.medicationOptions.filter((option) =>
        option.medication.toLowerCase().includes(filterValue)
      );
    }

    return this.medicationOptions;
  }

  private _filter3(value: any): any[] {
    if (value) {
      let filterValue = '';
      if (typeof value === 'string') {
        filterValue = value.toLowerCase();
      } else {
        filterValue = value.display_text.toLowerCase();
      }

      return this.deviceOptions.filter((option) =>
        option.display_text.toLowerCase().includes(filterValue)
      );
    }

    return this.deviceOptions;
  }

  displayFn1(patient: any): string {
    return patient && patient.patient_name ? patient.patient_name : '';
  }

  async onPatientSelected(selected: MatAutocompleteSelectedEvent) {
    this.medicationFormControl.enable();
    this.selectedPatient = selected.option.value;
  }

  async getMedicationOrders() {
    this._medicationService.getMedicationForPatients(
      parseInt(this.selectedPatient.id)
    );

    this._medicationService.medicationForPatientObs.subscribe((values) => {
      console.log('values ', values);
      this.medicationValues = values;
      console.log('medicationValues ', this.medicationValues);
    });

    this._patientService.patientListObs.subscribe((patients) => {
      this.patientOptions = patients.assigned; //change it to unassigned
      this.filteredPatientOptions = this.patientFormControl.valueChanges.pipe(
        startWith(''),
        map((value) => this._filter1(value))
      );
    });
  }

  async getMedications() {
    this._medicationService.medicationListObs.subscribe((medications) => {
      this.medicationOptions = medications;
      this.filteredMedicationOptions =
        this.medicationFormControl.valueChanges.pipe(
          startWith(''),
          map((value) => this._filter2(value))
        );
    });
  }

  displayFn2(device: any): string {
    return device && device.display_text ? device.display_text : '';
  }

  onDeviceSelected(selected: MatAutocompleteSelectedEvent) {
    this.selectedDevice = selected.option.value;
  }

  onMediactionDrugSelected(selected: MatAutocompleteSelectedEvent) {
    this.selectedDrugSrc = selected.option.value.display_text;
    if (this.selectedDrugSrc == 'Prescription') {
      this.getMedicationOrders();
    } else {
      this.getMedications();
    }
  }

  displayFn3(medication: any): string {
    return medication && medication.medication ? medication.medication : '';
  }

  displayFn4(medication: any): string {
    return medication && medication.display_text ? medication.display_text : '';
  }

  onmedicationSelected(selected: MatAutocompleteSelectedEvent) {
    this.selectedMedication = selected.option.value;
  }
}
