import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { ListProps } from '../models/Hierarchy';
import { IResponse } from '../models/HttpRequest';
import { IMedication } from '../models/Medication';
import { FilterService } from './filter.service';

@Injectable({
  providedIn: 'root',
})
export class MedicationService {
  constructor(
    private _http: HttpClient,
    private _filterService: FilterService
  ) {
    //Listen for search text changes and fetch data accordingly
    this._setSearchListnerForMedicationList();
  }

  private _medicationList: BehaviorSubject<IMedication[]> = new BehaviorSubject<
    IMedication[]
  >([]);
  private _medicationForPatient: BehaviorSubject<any> =
    new BehaviorSubject<any>([]);
  private DrugSrcList: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  private _medicationListCount: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);
  public _medicationListProps: BehaviorSubject<ListProps> =
    new BehaviorSubject<ListProps>({
      page: 0,
      limit: 20,
      sortBy: '',
      sort: 'asc',
      filter: '',
    });
  private _medicationFilteredList: BehaviorSubject<IMedication[]> =
    new BehaviorSubject<IMedication[]>([]);
  private _medicationDetailId: BehaviorSubject<any> = new BehaviorSubject<any>(
    JSON.parse(localStorage.getItem('med_det_k')) || {}
  );

  readonly medicationDetailIdObs = this._medicationDetailId.asObservable();
  readonly medicationListObs = this._medicationList.asObservable();
  readonly medicationForPatientObs = this._medicationForPatient.asObservable();
  readonly DrugSrcListObs = this.DrugSrcList.asObservable();
  readonly medicationListCountObs = this._medicationListCount.asObservable();
  readonly medicationListPropsObs = this._medicationListProps.asObservable();
  readonly medicationFilteredListObs =
    this._medicationFilteredList.asObservable();

  private _loadMedicationList(
    listProps = this._medicationListProps.getValue()
  ) {
    this._http
      .post(`/api/v1/drug/listDrugs`, { listProps: listProps })
      .subscribe(
        (data: IResponse<any>) => {
          this._medicationList.next(data.response.drugs);
          this._medicationFilteredList.next(data.response.drugs);
          this._medicationListCount.next(data.response.total_count ?? 0);
        },
        (err) => {
          console.log('some error occured.');
        }
      );
  }

  getDrugDetail(body) {
    return this._http.post(`/api/v1/drug/drugDetail`, body).pipe(
      map((data: IResponse<any>) => {
        return data.response.drug;
      })
    );
  }

  /* start changes by KI */
  getMedicationForPatients = (patient_id) => {
    return this._http
      .post(`/api/v1/drug/listDrugsForPatient`, {
        patient_id,
      })
      .subscribe(
        (data: IResponse<any>) => {
          this._medicationForPatient.next(data.response.drugs);
        },
        (err) => {
          console.log('some error occured.');
        }
      );
  };

  getReferenceForMedicationSourse = (identifier: string) => {
    return this._http
      .post(`/api/v1/reference/getReference`, {
        identifier,
      })
      .subscribe(
        (data: IResponse<any>) => {
          this.DrugSrcList.next(data.response);
        },
        (err) => {
          console.log('some error occured.');
        }
      );
  };
  /* end changes by KI */

  passMedicationDetailId(param: any) {
    localStorage.setItem('med_det_k', JSON.stringify(param));
    this._medicationDetailId.next(param);
  }

  saveMedicationDetail(body: any) {
    return this._http.post(`/api/v1/drug/editdrug`, body).pipe(
      map((data: IResponse<any>) => {
        this._loadMedicationList();
        return data;
      })
    );
  }

  _setSearchListnerForMedicationList() {
    combineLatest([
      this._filterService.searchedValueObs,
      this.medicationListPropsObs,
    ]).subscribe((data: any) => {
      let listProps = this._medicationListProps.getValue();
      listProps.filter = data[0];
      this._loadMedicationList(listProps);
    });
  }

  getMedicationOrder(drugId: string, listProps: ListProps) {
    return this._http
      .post('/api/v1/drug/drugOrders', {
        drug_id: drugId,
        type: 'all',
        listProps: listProps,
      })
      .pipe<any>(map<any, any>((response) => response.response));
  }

  getDrugUtilization(drugId: number) {
    return this._http
      .post('/api/v1/drug/drugUtilization', { drug_id: drugId })
      .pipe<any>(map<any, any>((response) => response.response));
  }

  getDrugStastics(
    drug_id: any,
    concentration: any,
    concentration_unit: any,
    pump_type: any,
    dose_rate: any
  ) {
    return this._http
      .post('/api/v1/drug/drugStatistics', {
        drug_id: drug_id,
        concentration: concentration,
        concentration_unit: concentration_unit,
        pump_type: pump_type,
        rate_unit: dose_rate,
      })
      .pipe<any>(map<any, any>((response) => response.response));
  }

  fetchLibraryGraphData(
    drug_id: any,
    concentration: any,
    concentration_unit: any,
    pump_type: any,
    dose_rate: any
  ) {
    return this._http
      .post('/api/v1/drug/drugLibraryGraph', {
        drug_id: drug_id,
        concentration: concentration,
        concentration_unit: concentration_unit,
        pump_type: pump_type,
        rate_unit: dose_rate,
      })
      .pipe<any>(map<any, any>((response) => response.response));
  }

  saveConcentration(adminConcentration: {
    medicationAmounr: any;
    medicationUnit: any;
    diluentAmount: any;
  }) {
    throw new Error('Method not implemented.');
  }

  fetchDrugClass() {
    return this._http
      .post<any>('/api/v1/drug/listDrugClasses', {})
      .pipe(map((res) => res.response.drugClasses));
  }

  fetchDrugPriorites() {
    return this._http
      .post<any>('/api/v1/drug/listDrugPriorities', {})
      .pipe(map((res) => res.response.drugPriorites));
  }

  fetchDrugSchedules() {
    return this._http
      .post<any>('/api/v1/drug/listDrugSchedules', {})
      .pipe(map((res) => res.response.drugSchedules));
  }
  listDrugIndicators;

  fetchDrugUnits() {
    return this._http
      .post<any>('/api/v1/drug/listDrugUnits', {})
      .pipe(map((res) => res.response.drugUnits));
  }

  fetchDrugIndicator() {
    return this._http
      .post<any>('/api/v1/drug/listDrugIndicators', {})
      .pipe(map((res) => res.response.drugIndicators));
  }
}
