import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssignedPatientTableViewComponent } from './component/assigned-patient-table-view/assigned-patient-table-view.component';
import { UnassignedPatientTableViewComponent } from './component/unassigned-patient-table-view/unassigned-patient-table-view.component';
import { MatTableModule } from '@angular/material/table';
import { PatientHeaderComponent } from './component/patient-header/patient-header.component';
import { PatientListComponent } from './pages/patient-list/patient-list.component';
import { PatientRoutingModule } from './patient-routing.module';
import { DischargePatientComponent } from './component/discharge-patient/discharge-patient.component';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';
import { PatientEditorComponent } from './component/patient-editor/patient-editor.component';
import { PatientDetailFormComponent } from './component/patient-detail-form/patient-detail-form.component';
import { CurrentTabViewComponent } from './component/current-tab-view/current-tab-view.component';
import { HistoryTabViewComponent } from './component/history-tab-view/history-tab-view.component';
import { OrderTabViewComponent } from './component/order-tab-view/order-tab-view.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatSortModule } from '@angular/material/sort';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ReassignPatientBtnComponent } from './component/reassign-patient-btn/reassign-patient-btn.component';
import { MessageDialogComponent } from './component/message-dialog/message-dialog.component';
import { MatPaginatorModule } from '@angular/material/paginator';

import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { I18nService } from 'src/app/core/services/i18n-service.service';
import { PatientDetailComponent } from './pages/patient-detail/patient-detail.component';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AssignedPatientTableViewComponent,
    UnassignedPatientTableViewComponent,
    PatientHeaderComponent,
    PatientListComponent,
    DischargePatientComponent,
    PatientEditorComponent,
    PatientDetailFormComponent,
    CurrentTabViewComponent,
    HistoryTabViewComponent,
    OrderTabViewComponent,
    ReassignPatientBtnComponent,
    MessageDialogComponent,
    PatientDetailComponent,
  ],
  imports: [
    CommonModule,
    MatTableModule,
    PatientRoutingModule,
    MatSelectModule,
    MatFormFieldModule,
    MatIconModule,
    MatExpansionModule,
    MatDialogModule,
    MatDatepickerModule,
    MatInputModule,
    MatTabsModule,
    MatProgressBarModule,
    ReactiveFormsModule,
    MatTableModule,
    SharedModule,
    MatSortModule,
    MatAutocompleteModule,
    FormsModule,
    MatPaginatorModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],

  exports: [
    AssignedPatientTableViewComponent,
    UnassignedPatientTableViewComponent,
    ReassignPatientBtnComponent,
  ],
})
export class PatientModule {
  constructor(
    private translate: TranslateService,
    private i18nService: I18nService
  ) {
    this.translate.addLangs(i18nService.getLangs());
    this.i18nService.locale.subscribe((lang) => {
      this.translate.setDefaultLang(lang);
    });
  }
}
