import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { FilterService } from '../../services/filter.service';

declare const $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  search_value: any = '';

  constructor(
    private _filterService: FilterService,
    public authService: AuthService
  ) {}

  ngOnInit(): void {
    // This is intentionally empty
  }

  onSearchChange() {
    this._filterService.updateSearchString(this.search_value);

    let search_bar = document.getElementsByClassName('search_bar');
    if (this.search_value != '') {
      search_bar[0].classList.add('input_big');
    } else {
      if (this.search_value == '') {
        search_bar[0].classList.remove('input_big');
        $('.search_bar .mat-icon').removeClass('active_search');
      }
    }
  }

  search() {
    $('.search_bar .mat-icon').toggleClass('active_search');
  }
}
