import { Component, OnInit } from '@angular/core';
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';
import { DeviceService } from 'src/app/core/services/device.service';
import { PatientSelectedForReportView } from '../../component/patient-medication-table-view/patient-medication-table-view.component';

@Component({
  selector: 'app-general-report',
  templateUrl: './general-report.component.html',
  styleUrls: ['./general-report.component.css'],
})
export class GeneralReportComponent implements OnInit {
  exportAsExcel: ExportAsConfig = {
    type: 'xlsx', // the type you want to download
    elementIdOrContent: 'report_table', // the id of html/table element
  };
  exportAsPdf: ExportAsConfig = {
    type: 'pdf', // the type you want to download
    elementIdOrContent: 'pdf_container', // the id of html/table element
  };

  constructor(
    private exportAsService: ExportAsService,
    private _deviceService: DeviceService
  ) {
    // This is intentionally empty
  }

  displayedColumns: string[] = ['Time', 'Rate', 'Dose', 'VTBI', 'VI', 'Event'];
  dataSource: any[] = [];
  deviceObservation: any = {};

  ngOnInit(): void {
    this._loadDeviceObservation();
  }

  _loadDeviceObservation() {
    const reqData = JSON.parse(
      localStorage.getItem(PatientSelectedForReportView)
    );
    this._deviceService.getDeviceObservations(reqData).subscribe((data) => {
      this.deviceObservation = data;
      this.dataSource = data.observations;
      console.log(data);
    });
  }

  exportToExcel() {
    this.exportAsService
      .save(this.exportAsExcel, 'general-report')
      .subscribe(() => {
        //empty
      });
  }
  exportToPdf() {
    this.exportAsService
      .save(this.exportAsPdf, 'general-report')
      .subscribe(() => {
        //empty
      });
  }

  goBack() {
    window.history.back();
  }
}
