<div class="login_div">
  <div class="container-fluid h-100">
  <div class="row h-100">
    <div class="col-md-6 col-lg-6 h-100">
      <div class="login_logo">
        <h4>{{'HOME.pages-Login_Page-ivCare' | translate}} </h4>
      </div>

      <img src="../../../assets/img/login_img.png" class="login_image" alt="Login">

    </div>
    <div class="col-md-6 col-lg-6">
      <div class="login_detail">
        <h6>{{'HOME.pages-Login_Page-WelcometoivCare' | translate}} </h6>
        <h2>{{'HOME.pages-Login_Page-LogIntoyourAccount' | translate}}</h2>
        <form [formGroup]="loginForm">
          <label>{{'HOME.pages-Login_Page-UserId' | translate}}</label>
          <mat-form-field appearance="outline" class="w-100 mb-2">
            <input matInput placeholder="{{'HOME.pages-Login_Page-EnterEmail' | translate}}" formControlName="username" id="login_id">
            <mat-error class="eroro" *ngIf="loginForm.controls['username'].invalid">{{getErrorMessage('username')}}</mat-error>
          </mat-form-field>

          <label>{{'HOME.pages-Login_Page-Password' | translate}}</label>
          <mat-form-field appearance="outline" class="w-100 mb-2">
            <input  matInput placeholder="6+ {{'HOME.pages-Login_Page-Characters' | translate}}" formControlName="password" [type]="showPassword ? 'text': 'password'" id="password">
            <img *ngIf="showPassword"  (click)="toggleShowPassword()" src="../../../assets/img/login_eye.svg" class="view_icon" alt="Password Visible"/>
            <img *ngIf="!showPassword" (click)="toggleShowPassword()" src="../../../assets/img/login_show_eye.svg" class="view_icon" alt="Patient Hide"/>
            <mat-error *ngIf="loginForm.controls['password'].invalid">{{getErrorMessage('password')}}</mat-error>
          </mat-form-field>

          <button class="custom_btn w-100 mt-3" mat-raised-button [disabled]="loginForm.invalid || isSubmitting" color="primary"
          (click)="submit()" (onkeyup)="submit()" id="login_btn">{{'HOME.pages-Login_Page-Login' | translate}}</button>
        </form>
    </div>
    </div>
    </div>
    </div>
    <div class="footer d-flex">
      <p class="text-white">{{'HOME.pages-Login_Page-CpyrightText' | translate}} </p>
      <p>{{'HOME.pages-Login_Page-version' | translate}}</p>
    </div>
</div>
