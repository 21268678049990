import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class I18nService {
  private _locale: BehaviorSubject<string>;
  readonly locale: Observable<string>;
  private _langList = [
    { code: 'en', lang: 'English' },
    { code: 'hi', lang: 'हिन्दी' },
  ];
  private _selectedLang: string;

  constructor(private _langService: TranslateService) {
    let selectedLang = localStorage.getItem('lang') || 'en';
    this._selectedLang = selectedLang;
    this._locale = new BehaviorSubject<string>(selectedLang);
    this.locale = this._locale.asObservable();
    this._locale.next(selectedLang);
   
  }

  changeLocale(locale: string) {
    this._langService.use(locale);
    localStorage.setItem('lang', locale);
    this._selectedLang = locale;
    this._locale.next(locale);
  }

  getLangs() {
    return this._langList.map((val) => val.code);
  }

  getLangsDesc() {
    return this._langList;
  }

  getSelectedLang() {
    return this._selectedLang;
  }
}
