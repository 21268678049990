<div (mouseover)="onHover($event)" (mouseleave)="onHoverOut()" [ngClass]="getBoxClassString(patientDetail.alarm, patientDetail.stale_msg)" (click)="selectPatientForAssociation()">
   <div class="cnt_hdr" (click)="openPatientDetail()" id="room_header_view">
      <h3 [title]="patientDetail.patient_name">
         {{patientDetail.room + "-" + patientDetail.bed}}: {{patientDetail.patient_name}}

      </h3>
      <a class="" id="expand_icon">
         <img src="../../../../../assets/img/maximize.svg" class="expand_icon" alt="Patient Detail">
         <img src="../../../../../assets/img/maximize_blue.svg" class="expand_icon blue_expand" alt="Blue">
      </a>
   </div>
   <div class="ward_content">
      <div class="inner_scroller">
         <p *ngFor="let medication of patientDetail.medications">
            <span>{{medication.medication}}</span>
            <span>
               <label>{{medication.percentage_infused}}%</label>
               <div class="ward_progress_div">
                  <mat-progress-bar mode="determinate" [value]="medication.percentage_infused"></mat-progress-bar>
                  <div class="bar_value">
                  {{medication.time_remaining}}
                  </div>
               </div>

               <div class="alert_img ms-1">
                <img *ngIf="medication.alarm===null && !!medication.stale_msg"  src="../../../../../assets/img/hospital_bottle.svg" class="table_icon" alt="Delayed Data" id="bottle_icon">
                <img *ngIf="medication.alarm !==null" src="../../../../../assets/img/red_alert.svg" class="table_icon" alt="Alarm" id="alert_icon">
               </div>
            </span>
         </p>
      </div>
   </div>
</div>
