import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PatientService } from 'src/app/core/services/patient.service';

@Injectable({
  providedIn: 'root',
})
export class PatientEditorService {
  constructor(
    private patientService: PatientService,
    private _router: Router
  ) {}

  openDialog(patientId = '') {
    if (patientId != '') {
      this.patientService.currentOp = 'Edit';
      localStorage.setItem('currentOp', 'Edit');
    } else {
      this.patientService.currentOp = 'Add';
      localStorage.setItem('currentOp', 'Add');
    }
    this.patientService.passPatientDetailId(patientId);
    this._router.navigate(['patient/detail']);
  }
}
