<h2 mat-dialog-title class="text-center">{{data.title}}
</h2>
<div class="confirmation">
  <div class="d-flex mb-2">
    <div class="filter_div dialog_mat_select mr-3">
      <label>{{'HOME.shared-component-Bed_Selector-Room' | translate}}</label>
      <mat-form-field appearance="outline" >
        <input type="text"
               id="room_select"
               placeholder="{{'GLOBAL.Choose_an_option' | translate}}"
               matInput
               [formControl]="roomFormControl"
               [matAutocomplete]="roomInpAuto">
        <mat-autocomplete #roomInpAuto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onRoomSelected($event)">
          <mat-option [id]="'choose_room_select_' + i" *ngFor="let room of wardhierarchy; let i= index;"  [value]="room">
            {{room.display_text}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="filter_div dialog_mat_select">
      <label>{{'HOME.shared-component-Bed_Selector-Bed' | translate}}</label>
      <mat-form-field appearance="outline" >
        <input type="text"
               id="bed_select"
               placeholder="{{'GLOBAL.Choose_an_option' | translate}}"
               matInput
               [formControl]="bedFormControl"
               [matAutocomplete]="bedInpAuto">
        <mat-autocomplete #bedInpAuto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onBedSelected($event)">
          <ng-container *ngFor="let bed of dataBed; let i= index;">
            <mat-option [id]="'choose_bed_select_' + i" *ngIf="!bed.is_occupied"  [value]="bed">
              {{bed.display_text}}
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>
  <button id="bed_select_btn" type="button" class="custom_btn success_btn mr-3" [mat-dialog-close]="{room: selectedRoom, bed: selectedBed, ward: currentWard}">{{'HOME.shared-component-Bed_Selector-Select' | translate}}</button>
  <button id="cancel_bed_btn" type="button" class="custom_btn secondary_btn mr-3" [mat-dialog-close]="{canceled: true}">{{'HOME.shared-component-Bed_Selector-Cancel' | translate}}</button>
  <button *ngIf="data.assigned" id="unassign_bed_btn" type="button" class="custom_btn btn_cancel" [mat-dialog-close]="{unassigned: true}">{{'HOME.shared-component-Bed_Selector-Unassign' | translate}}</button>
</div>
