<div class="row">
    <div class="col-lg-7 col-md-12 bg-black cstm_padng">

        <div class="map-view">

            <app-map-card *ngFor="let room of wardhierarchy" [roomHierarchy]="room" (bedSelect)="onBedSelect($event)">
            </app-map-card>

        </div>
    </div>
    <div class="col-lg-5 col-md-12 mb_mar_tp_20 mr_tp_20">
        <div class="map_view_table bg-black">
            <div *ngIf="currentSideView === 'REMAINING_TIME_VIEW'">
                <h3 class="mb-1">{{'HOME.ward-pages-Map_View-RemainingTimes' | translate}}</h3>
                <app-remaining-time-view [data]="medicationsTimeVIew"></app-remaining-time-view>
            </div>

            <div *ngIf="currentSideView === 'PATIENT_VIEW' && (assignedBedSelected || !unassignedBedSelected)">
                <h3 class="mb-1">{{'HOME.ward-pages-Map_View-AssignedPatient' | translate}}</h3>
                <app-assigned-patient-table-view
                    [assignTo]="{room: unassignedSelectedBedDetail.room, bed: unassignedSelectedBedDetail.display_text, ward: currentWard.display_text}"
                    [selectedPatientId]="assignedSelectedBedDetail.patient?.id" [isMapView]="true">
                </app-assigned-patient-table-view>
            </div>

            <div
                *ngIf="currentSideView === 'PATIENT_VIEW' && unassignedBedSelected && unassignedSelectedBedDetail && !unassignedSelectedBedDetail.isOccupied && !assignedBedSelected">
                <h3 class="mb-1">{{'HOME.ward-pages-Map_View-UnassignedPatient' | translate}}</h3>
                <app-unassigned-patient-table-view
                    [assignTo]="{room: unassignedSelectedBedDetail.room, bed: unassignedSelectedBedDetail.display_text, ward: currentWard.display_text}">
                </app-unassigned-patient-table-view>
            </div>

        </div>

        <div class="bg-black room_info  custom_scroller height_200" id="room_info"> 
            <ul>
                <ng-container *ngFor="let alarm of medicationsTimeVIew">
                    <li class="bg-red" *ngIf="!!alarm.alarm">
                        <div class="room_no me-2">
                            <span>{{alarm.room}}-{{alarm.bed}}
                                <img id="detail_icon" src="../../../../../assets/img/alert.svg" class="table_icon ms-1" alt="Alert"
                                (click)="openDetail(alarm)">
                            </span>
                        </div>
                        <div class="room-detail me-2">
                            <span>{{alarm.pump}}
                                <img id="report_detail" src="../../../../../assets/img/chart_svg.svg" class="table_icon me-1" alt="Chart"
                                    (click)="openReportPage(alarm)">
                            </span>
                            <span>{{alarm.message}}
                                <img *ngIf="alarm.alarm===null && !!alarm.stale_msg"
                                    src="../../../../../assets/img/hospital_bottle_white.svg" class="table_icon ms-2" alt="Delayed Data">
                                <img *ngIf="alarm.alarm !==null" src="../../../../../assets/img/white_alert.svg"
                                    class="table_icon ms-2" alt="Alert">
                            </span>
                        </div>
                        <div class="room_medicine">
                            <span>{{alarm.medication}}</span>
                        </div>
                    </li>

                    <li class="bg-yellow" *ngIf="!!alarm.stale_msg">
                        <div class="room_no me-2">
                            <span>{{alarm.room}}-{{alarm.bed}}
                                <img id="info_icon" src="../../../../../assets/img/alert.svg" class="table_icon ms-1" alt="Alert"
                                    (click)="openPatientDetail(alarm.id)">
                            </span>
                        </div>
                        <div class="room-detail me-2">
                            <span>{{alarm.pump}}
                                <img id="report_icon" src="../../../../../assets/img/chart_svg.svg" class="table_icon me-1" alt="Chart"
                                    (click)="openReportPage(alarm)">
                            </span>
                            <span>{{'HOME.ward-pages-Map_View-StaleMessage' | translate}}
                                <img *ngIf="alarm.alarm===null && !!alarm.stale_msg"
                                    src="../../../../../assets/img/hospital_bottle_white.svg" class="table_icon ms-2" alt="Delayed Data">
                                <img *ngIf="alarm.alarm !==null" src="../../../../../assets/img/white_alert.svg"
                                    class="table_icon ms-2" alt="Alert">
                            </span>
                        </div>
                        <div class="room_medicine">
                            <span>{{alarm.medication}}</span>
                        </div>

                    </li>
                </ng-container>
            </ul>
        </div>
    </div>


</div>
