<div class="popup_box" id="event_filter_popup">
  <mat-dialog-content>
    <h2 mat-dialog-title class="text-center">{{'HOME.ward-component-Event_Filter_Dialog-EventFilter' | translate}}
      <mat-icon class="close_icon" id="close_btn" [mat-dialog-close]="true">close</mat-icon>
    </h2>
    <div class="dialog_row">
      <div class="patient_select">
        <div class="link_inner">
          <label class="filter_label">{{'HOME.ward-component-Event_Filter_Dialog-SelectDataRange' | translate}}</label>
          <div class="date_select">
            <mat-form-field appearance="outline" class="w-100">
              <mat-date-range-input [rangePicker]="picker">
                <input  matStartDate placeholder="{{'HOME.ward-component-Event_Filter_Dialog-StartDate' | translate}}" [(ngModel)]="startDate">
                <input  matEndDate placeholder="{{'HOME.ward-component-Event_Filter_Dialog-EndDate' | translate}}" [(ngModel)]="endDate">
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <label class="filter_label">{{'HOME.ward-component-Event_Filter_Dialog-EventTypes' | translate}}</label>
    <div class="event-filter-sec custom_scroller">

      <div class="checkboxes">
        <mat-checkbox class="example-margin" [checked]="allComplete" [color]="'primary'"
          [indeterminate]="someComplete()" (change)="setAll($event.checked)">
          {{'HOME.ward-component-Event_Filter_Dialog-All' | translate}}
        </mat-checkbox>
      </div>
      <ng-container *ngFor="let event of eventList">
      <label class="filter_label w-100">{{event.type}}</label>
      <div class="checkboxes">
        <ul>
          <li *ngFor="let sub_event of event.subtype">
            <mat-checkbox id="event_type_select" [(ngModel)]="sub_event.completed" [color]="sub_event.color" (ngModelChange)="updateAllComplete()">
              {{sub_event.type}}
            </mat-checkbox>
          </li>
        </ul>
      </div>
    </ng-container>

    </div>

    <div class="event_btns d-flex justify-content-center w-100 mb-3">
      <button id="clearall_btn" type="button" class="custom_btn btn-secondary mr-3" (click)="clearAllSelection()">
        {{'HOME.ward-component-Event_Filter_Dialog-ClearAll' | translate}}
      </button>

      <button id="apply_btn" type="button" class="custom_btn btn-success" (click)="applyFilter()">
        {{'HOME.ward-component-Event_Filter_Dialog-Apply' | translate}}
      </button>

    </div>



  </mat-dialog-content>
</div>
