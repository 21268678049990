<div class="patient_data d-flx-spc-btwn">
  <div class="pump_header d-flx-spc-btwn me-2 mb-d-blck">
    <label
      ><span class="txt_blue"
        >{{ "HOME.ward-component-Pump_Chart-CurrentStatus" | translate }} :
      </span>
      {{ "HOME.ward-component-Pump_Chart-InUse" | translate }}
    </label>
    <label
      ><span class="txt_blue"
        >{{ "HOME.ward-component-Pump_Chart-Patient" | translate }} :
      </span>
      {{ devicePatient?.patient_name }}</label
    >
    <label
      ><span class="txt_blue"
        >{{ "HOME.ward-component-Pump_Chart-StartDate" | translate }} :
      </span>
      {{ devicePatient?.start_date }}</label
    >

    <label
      ><span class="txt_blue"
        >{{ "HOME.ward-component-Pump_Chart-RemainingTime" | translate }} :
      </span>
      {{ devicePatient?.time_remaining }}</label
    >
  </div>
  <button id="back_btn" type="button" class="icon_btn mb-3 hover-effect" (click)="goBack()">
    <img
      src="../../../../../assets/img/back.png"
      class="table_icon"
      alt="Back"
    />
  </button>
</div>
<div id="pump_chart" class="charts_info bg-black">
  <div
    class="patient_info d-flx-spc-btwn bg-blue-grey align-items-center p-2 mb-d-inline"
  >
    <label
      ><span class="txt_blue">
        {{ "HOME.ward-component-Pump_Chart-Patients" | translate }} :
      </span>
      {{ devicePatient.patient_name }}</label
    >

    <label class="mob_w_100"
      ><span class="txt_blue"
        >{{ "HOME.ward-component-Pump_Chart-Age" | translate }} : </span
      >{{ devicePatient?.age }} {{ "HOME.ward-component-Pump_Chart-Years" | translate }}
    </label>
    <label class="mob_w_100"
      ><span class="txt_blue"
        >{{ "HOME.ward-component-Pump_Chart-Height" | translate }} :
      </span>
      {{ devicePatient?.height }}</label
    >
    <label class="mob_w_100"
      ><span class="txt_blue"
        >{{ "HOME.ward-component-Pump_Chart-Weight" | translate }} :
      </span>
      {{ devicePatient?.weight }}</label
    >
    <button
      id="download_btn"
      type="button"
      class="icon_btn hover-effect"
      [matMenuTriggerFor]="beforeMenu"
    >
      <img src="../../../../../assets/img/download.svg" alt="Download" />
    </button>
    <mat-menu #beforeMenu="matMenu" xPosition="before" class="bg-black">
      <button id="pdf_download_btn" (click)="exportToPdf('pump_chart', 'pump-charts')" mat-menu-item>
        <img src="../../../../../assets/img/pdf.png" alt="PDF" />
        {{ "HOME.ward-component-Event_Feed-PDFFile" | translate }}
      </button>
      <button id="excel_download_btn" (click)="exportEventsToExcel()" mat-menu-item>
        <img src="../../../../../assets/img/excel.png" alt="Excel" />
        {{ "HOME.ward-component-Event_Feed-ExcelSheet" | translate }}
      </button>
    </mat-menu>

    <mat-menu #volumeDelivered="matMenu">
      <button
        id="volume_pdf_download"
        mat-menu-item
        (click)="exportToPdf('volume_delivered', 'volume-delivered')"
      >
        <img src="../../../../../assets/img/pdf.png" alt="PDF" />
        {{ "HOME.ward-component-Event_Feed-PDFFile" | translate }}
      </button>
      <button  id="volume_excel_download" mat-menu-item (click)="exportVolumeToExcel()">
        <img src="../../../../../assets/img/excel.png" alt="Excel" />
        {{ "HOME.ward-component-Event_Feed-ExcelSheet" | translate }}
      </button>
    </mat-menu>

    <mat-menu #dose="matMenu">
      <button id="dose_pdf_download" mat-menu-item (click)="exportToPdf('dose', 'pressure')">
        <img src="../../../../../assets/img/pdf.png" alt="PDF"/>
        {{ "HOME.ward-component-Event_Feed-PDFFile" | translate }}
      </button>
      <button  id="dose_excel_download" mat-menu-item (click)="exportDevicePressureToExcel()">
        <img src="../../../../../assets/img/excel.png" alt="Excel"/>
        {{ "HOME.ward-component-Event_Feed-ExcelSheet" | translate }}
      </button>
    </mat-menu>

    <mat-menu #rate="matMenu">
      <button id="rate_pdf_download" mat-menu-item (click)="exportToPdf('rate', 'rate')">
        <img src="../../../../../assets/img/pdf.png" alt="PDF" />
        {{ "HOME.ward-component-Event_Feed-PDFFile" | translate }}
      </button>
      <button id="rate_excel_download" mat-menu-item (click)="exportDoseRateToExcel()">
        <img src="../../../../../assets/img/excel.png" alt="Excel" />
        {{ "HOME.ward-component-Event_Feed-ExcelSheet" | translate }}
      </button>
    </mat-menu>
  </div>
  <div id="volume_delivered" class="chart-row">
    <div class="chart_div">
      <app-pump-line-chart
        [height]="300"
        [width]="1100"
        [data]="dataSource"
        [yLabel]="'volume_delivered'"
        [yAxisLabel]="('HOME.ward-component-Event_Feed-Volumedelivered' | translate)+' (ml)'"
      ></app-pump-line-chart>
    </div>
    <div class="chart_info bg-light-blue">
      <label>
        {{ "HOME.ward-component-Pump_Chart-Volume" | translate }}<br />
        (ml)
      </label>
      <button
        id="volume_download_btn"
        type="button"
        class="icon_btn hover-effect"
        [matMenuTriggerFor]="volumeDelivered"
      >
        <img src="../../../../../assets/img/download.svg" alt="Download"/>
      </button>
    </div>
  </div>
  <div id="rate" class="chart-row">
    <div class="chart_div">
      <app-pump-line-chart
        [height]="300"
        [width]="1100"
        [data]="dataSource"
        [yLabel]="'rate'"
        [yAxisLabel]="('HOME.ward-component-Event_Feed-DoseRate' | translate)+' (mg/hr)'"
      ></app-pump-line-chart>
    </div>
    <div class="chart_info bg-light-blue">
      <label>
        {{ "HOME.ward-component-Pump_Chart-DoseRate" | translate }} <br />
        (mg/hr)
      </label>
      <button
        id="rate_download_btn"
        type="button"
        class="icon_btn hover-effect"
        [matMenuTriggerFor]="rate"
      >
        <img src="../../../../../assets/img/download.svg" alt="Download"/>
      </button>
    </div>
  </div>
  <div id="dose" class="chart-row">
    <div class="chart_div">
      <app-pump-line-chart
        [yLabel]="'event_desc'"
        [yAxisLabel]="('HOME.ward-component-Event_Feed-Pressure' | translate)+' (mmHg)'"
        [data]="devicePressureData"
        [height]="300"
        [width]="1100"
      >
      </app-pump-line-chart>
    </div>
    <div class="chart_info bg-light-blue">
      <label>
        {{ "HOME.ward-component-Pump_Chart-Pressure" | translate }} <br />
        (mmHg)
      </label>
      <button
        id="dose_download_btn"
        type="button"
        class="icon_btn hover-effect"
        [matMenuTriggerFor]="dose"
      >
        <img src="../../../../../assets/img/download.svg" alt="Download"/>
      </button>
    </div>
  </div>
</div>
