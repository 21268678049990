<div class="patient_edit_popup">
    <div class="popup_header d-flx-spc-btwn align-items-center mb-d-inline">
        <app-hierarchy-filter></app-hierarchy-filter>
        <div class="filter_div right_side_filter mb_mar_btm">
            <div class="popup_btn">
                <button id="save_patient" type="button" class="icon_btn me-2" [mat-dialog-close]="true"
                    (click)="savePatientDetail()">{{'HOME.patient-component-Patient_Editor-SavePatient' | translate}}</button>
                <button id="back_btn" type="button" class="icon_btn hover-effect" (click)="goBack()">
                    <img src="../../../../../assets/img/back.png" class="table_icon" alt="Back">
                </button>
            </div>
        </div>
    </div>

    <div class="row " id="editFormPatient">
        <div class="col-lg-4 col-md-12">
            <app-patient-detail-form [isSaved]="isSaved" (patientData)="OnFormData($event)"></app-patient-detail-form>
        </div>

        <div *ngIf="patientService.currentOp=='Edit'" class="col-lg-8 col-md-12 mt_20">
            <div class="tab_div bg-black">
                <mat-tab-group animationDuration="0ms" mat-align-tabs="start">

                    <mat-tab label="{{'HOME.patient-component-Patient_Editor-Current' | translate}}" class="tab_label">
                        <app-current-tab-view></app-current-tab-view>
                    </mat-tab>

                    <mat-tab label="{{'HOME.patient-component-Patient_Editor-History' | translate}}" class="tab_label">
                        <app-history-tab-view></app-history-tab-view>
                    </mat-tab>

                    <mat-tab label="{{'HOME.patient-component-Patient_Editor-Orders' | translate}}" class="tab_label">
                        <app-order-tab-view></app-order-tab-view>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>

    </div>
</div>
