import { Component, OnInit } from '@angular/core';
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';
import { DeviceService } from 'src/app/core/services/device.service';
import { PatientSelectedForReportView } from '../../component/patient-medication-table-view/patient-medication-table-view.component';

@Component({
  selector: 'app-pca-report',
  templateUrl: './pca-report.component.html',
  styleUrls: ['./pca-report.component.css'],
})
export class PcaReportComponent implements OnInit {
  exportAsExcel: ExportAsConfig = {
    type: 'xlsx', // the type you want to download
    elementIdOrContent: 'report_table', // the id of html/table element
  };
  exportAsPdf: ExportAsConfig = {
    type: 'pdf', // the type you want to download
    elementIdOrContent: 'container', // the id of html/table element
  };
  constructor(
    private exportAsService: ExportAsService,
    private _deviceService: DeviceService
  ) {
    // This is intentionally empty
  }

  ngOnInit(): void {
    this._loadDeviceObservation();
  }

  displayedColumns: string[] = [
    'Time',
    'Dose',
    'PCALockoutTime',
    'PrescribedMax',
    'Continuous',
    'Loading',
    'PCA',
    'RequestedNotDelivered',
    'TotalAdministered',
    'VTBI',
    'Event',
  ];
  dataSource: any[] = [];
  deviceObservation: any = {};

  _loadDeviceObservation() {
    const reqData = JSON.parse(
      localStorage.getItem(PatientSelectedForReportView)
    );
    this._deviceService.getDeviceObservations(reqData).subscribe((data) => {
      this.deviceObservation = data;
      this.dataSource = data.observations;
      this.dataSource.shift();
      console.log(data);
    });
  }

  exportToExcel() {
    this.exportAsService
      .save(this.exportAsExcel, 'pca-report')
      .subscribe(() => {
        //empty
      });
  }
  exportToPdf() {
    this.exportAsService
      .save(this.exportAsPdf, 'pca-report')
      .subscribe(() => {
        //empty
      });
  }

  goBack() {
    window.history.back();
  }
}
