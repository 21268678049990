import { Injectable } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserFeedbackDialogComponent } from 'src/app/modules/ward/component/user-feedback-dialog/user-feedback-dialog.component';
import { IHierarchy } from '../models/Hierarchy';
import { IMapViewState, IPatient } from '../models/Patient';
import { PatientService } from './patient.service';
import { MatSnackBar } from '@angular/material/snack-bar';

export enum ViewType {
  PatientView = 'PATIENT_VIEW',
  RemainingTimeView = 'REMAINING_TIME_VIEW',
}

@Injectable({
  providedIn: 'root',
})
export class MapViewService {
  constructor(
    private _patientService: PatientService,
    private dialog: MatDialog,
    private _langService: TranslateService,
    private _snackBar: MatSnackBar
  ) {
    this._langService
      .get([
        'GLOBAL.Patient_Reassignment_Alert_Message',
        'GLOBAL.Reassignment_Failed_Alert_Msg',
        'GLOBAL.Assign_Patient_Dialog_Title',
        'GLOBAL.Assign_Patient_Dialog_Msg_Assign',
        'GLOBAL.Assign_Patient_Dialog_Msg_To',
        'GLOBAL.ReAssign_Patient_Dialog_Title',
      ])
      .subscribe((ln) => {
        console.log(ln);

        this.Reassignment_Alert_Msg =
          ln['GLOBAL.Patient_Reassignment_Alert_Message'];
        this.Reassignment_Failed_Alert_Msg =
          ln['GLOBAL.Reassignment_Failed_Alert_Msg'];
        this.ReAssign_Patient_Dialog_Title =
          ln['GLOBAL.ReAssign_Patient_Dialog_Title'];
        this.Assign_Patient_Dialog_Title =
          ln['GLOBAL.Assign_Patient_Dialog_Title'];
        this.Assign_Patient_Dialog_Msg_Assign =
          ln['GLOBAL.Assign_Patient_Dialog_Msg_Assign'];
        this.Assign_Patient_Dialog_Msg_To =
          ln['GLOBAL.Assign_Patient_Dialog_Msg_To'];
      });
  }

  private Reassignment_Alert_Msg;
  private Reassignment_Failed_Alert_Msg;
  private Assign_Patient_Dialog_Title;
  private Assign_Patient_Dialog_Msg_Assign;
  private Assign_Patient_Dialog_Msg_To;
  private ReAssign_Patient_Dialog_Title;

  private assignedBedSelected: boolean;
  private assignedSelectedBedDetail: any;
  private unassignedBedSelected: boolean;
  private unassignedSelectedBedDetail: any;
  private _mapViewUIStateStore: IMapViewState = {
    isReassignmentMode: false,
    selectedPatientDetail: {},
    currentSideView: ViewType.RemainingTimeView,
  };
  private _mapViewUIState: BehaviorSubject<IMapViewState> = new BehaviorSubject(
    this._mapViewUIStateStore
  );
  private _mapViewRoomList: BehaviorSubject<IHierarchy[]> = new BehaviorSubject(
    []
  );

  public mapViewUIStateObs = this._mapViewUIState.asObservable();
  public mapViewRoomListObs = this._mapViewRoomList.asObservable();
  userFeedBackDialogRef: MatDialogRef<UserFeedbackDialogComponent>;

  public getRoomList(data: {
    identifier: string;
    ent_type: string;
    multilevel: boolean;
  }) {
    const obs1 = this._patientService.getWardChildrenWithStatus(data);
    const obs2 = this.mapViewUIStateObs;

    return combineLatest<Observable<any[]>, Observable<IMapViewState>>([
      obs1,
      obs2,
    ]).pipe(
      map((combinedResult) => {
        const roomList = combinedResult[0];
        let isReassignmentMode = combinedResult[1].isReassignmentMode;

        if (!isReassignmentMode) {
          return roomList;
        }

        let filteredList = [];
        roomList.forEach((currRoom) => {
          let tempRoom = JSON.parse(JSON.stringify(currRoom));
          tempRoom.children = [];
          currRoom.children.forEach((currBed) => {
            if (
              !currBed.isOccupied ||
              (currBed.patient.room ===
                this._mapViewUIStateStore.selectedPatientDetail?.room &&
                currBed.patient.bed ===
                  this._mapViewUIStateStore.selectedPatientDetail?.bed)
            ) {
              tempRoom.children.push(currBed);
            }
          });
          if (tempRoom.children.length > 0) {
            filteredList.push(tempRoom);
          }
        });
        return filteredList;
      })
    );
  }

  public setReassignmentMode(selectedPatientDetail: Partial<IPatient>) {
    this._mapViewUIStateStore.isReassignmentMode = true;
    this._mapViewUIStateStore.selectedPatientDetail = selectedPatientDetail;
    this._mapViewUIState.next(this._mapViewUIStateStore);
    console.log(this._mapViewUIStateStore);
  }

  public clearReassignmentMode() {
    this._mapViewUIStateStore.isReassignmentMode = false;
    this._mapViewUIState.next(this._mapViewUIStateStore);
  }

  toggleSideView(view?: ViewType, forcedToggle?: boolean) {
    if (forcedToggle) {
      this._mapViewUIStateStore.isReassignmentMode = false;
    }
    if (view) {
      this._mapViewUIStateStore.currentSideView = view;
      this._mapViewUIState.next(this._mapViewUIStateStore);
      return;
    }
    if (this._mapViewUIStateStore.currentSideView === ViewType.PatientView) {
      this._mapViewUIStateStore.currentSideView = ViewType.RemainingTimeView;
      this._mapViewUIState.next(this._mapViewUIStateStore);
      return;
    }

    this._mapViewUIStateStore.currentSideView = ViewType.PatientView;
    this._mapViewUIState.next(this._mapViewUIStateStore);
  }

  /**
   * handleBedSelection
   */
  public handleBedSelection(bedDetail: any) {
    if (bedDetail.patient.id) {
      if (!this._mapViewUIStateStore.isReassignmentMode) {
        this.clearReassignmentMode();
        this.assignedBedSelected = true;
        this.assignedSelectedBedDetail = bedDetail;
      }
    } else {
      this.unassignedBedSelected = true;
      this.unassignedSelectedBedDetail = bedDetail;

      if (this._mapViewUIStateStore.isReassignmentMode) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
          title: this.ReAssign_Patient_Dialog_Title,
          message: `${this.Assign_Patient_Dialog_Msg_Assign} ${this._mapViewUIStateStore.selectedPatientDetail?.patient_name} ${this.Assign_Patient_Dialog_Msg_To} ${bedDetail.room}-${bedDetail.display_text}?`,
        };

        this.userFeedBackDialogRef = this.dialog.open(
          UserFeedbackDialogComponent,
          dialogConfig
        );

        this.userFeedBackDialogRef.afterClosed().subscribe((data) => {
          if (data) {
            const reqData = {
              id: this._mapViewUIStateStore.selectedPatientDetail?.id,
              point_of_care: bedDetail.ward,
              room: bedDetail.room,
              bed: bedDetail.display_text,
            };
            this._patientService.assignPatient(reqData).subscribe((result) => {
              if (result) {
                this._snackBar.open(this.Reassignment_Alert_Msg);
                this.clearReassignmentMode();
                return;
              }
              this._snackBar.open(this.Reassignment_Failed_Alert_Msg);
            });
          }
        });
      }
    }
  }
}
