import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime } from 'rxjs/operators';
import { IHierarchy } from 'src/app/core/models/Hierarchy';
import { IPatient } from 'src/app/core/models/Patient';
import { PatientService } from 'src/app/core/services/patient.service';
import { UserFeedbackDialogComponent } from 'src/app/modules/ward/component/user-feedback-dialog/user-feedback-dialog.component';
import { BedSelectorComponent } from 'src/app/shared/component/bed-selector/bed-selector.component';
import { PatientEditorService } from '../../services/patient-editor.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-discharge-patient',
  templateUrl: './discharge-patient.component.html',
  styleUrls: ['./discharge-patient.component.css'],
})
export class DischargePatientComponent implements OnInit {
  constructor(
    private _patientService: PatientService,
    public patientEditorService: PatientEditorService,
    private dialog: MatDialog,
    private _langService: TranslateService,
    private _snackbar: MatSnackBar
    
  ) {
    this._langService
      .get([
        'GLOBAL.Patient_Reassignment_Alert_Message',
        'GLOBAL.Reassignment_Failed_Alert_Msg',
        'GLOBAL.Select_Room_Bed_Alert',
        'GLOBAL.Assign_Patient_Dialog_Title',
        'GLOBAL.Assign_Patient_Dialog_Msg_Assign',
        'GLOBAL.Assign_Patient_Dialog_Msg_To',
      ])
      .subscribe((ln) => {
        this.Patient_Assignment_Alert_Message =
          ln['GLOBAL.Patient_Reassignment_Alert_Message'];
        this.Patient_Assignment_Failed_Message =
          ln['GLOBAL.Reassignment_Failed_Alert_Msg'];
        this.Select_Room_Bed_Alert = ln['GLOBAL.Select_Room_Bed_Alert'];
        this.Assign_Patient_Dialog_Title =
          ln['GLOBAL.Assign_Patient_Dialog_Title'];
        this.Assign_Patient_Dialog_Msg_Assign =
          ln['GLOBAL.Assign_Patient_Dialog_Msg_Assign'];
        this.Assign_Patient_Dialog_Msg_To =
          ln['GLOBAL.Assign_Patient_Dialog_Msg_To'];
      });
    this.searchControl = new FormControl();
    this.searchControl.valueChanges.pipe(debounceTime(500)).subscribe(res => {
      this.applyFilter(res);
    });
  }

  private Patient_Assignment_Alert_Message;
  private Patient_Assignment_Failed_Message;
  private Select_Room_Bed_Alert;
  private Assign_Patient_Dialog_Title;
  private Assign_Patient_Dialog_Msg_Assign;
  private Assign_Patient_Dialog_Msg_To;

  dataSource = new MatTableDataSource([]);
  userFeedBackDialogRef: MatDialogRef<UserFeedbackDialogComponent>;
  selectBedDialogRef: MatDialogRef<BedSelectorComponent>;
  @Input() assignTo: { room?: string; bed?: string; ward?: string } = {};
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngOnInit(): void {
    this.getDischargePatients();
  }

  getDischargePatients(){
    this._patientService
      .getPatientList('discharged', this.listProps)
      .subscribe((res) => {
        this.dataSource = new MatTableDataSource(res.patients);
        this.totalCount = res.total_count===undefined ? 0 : res.total_count;
      });
  }

  displayedColumns: string[] = [
    'action',
    'date',
    'patient_name',
    'age',
    'height',
    'weight',
    'discharge',
  ];
  listProps = {
    page: 0,
    limit: 20,
    sortBy: '',
    sort: 'asc',
    filter: '',
  };
  totalCount: number = 0;
  searchControl: FormControl;

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.listProps.filter = filterValue;
    this.getDischargePatients();
  }

  onPageChange(event: PageEvent) {
    this.listProps = {
      ...this.listProps,
      page: event.pageIndex,
      limit: event.pageSize,
    };
    this.getDischargePatients();
  }

  onSort(sort: Sort) {
    this.listProps = {
      ...this.listProps,
      sortBy: sort.active,
      sort: sort.direction,
    };
    this.getDischargePatients();
  }

  openFeedbackDialog(type: string, selectedPatient: IPatient) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    if (type === 'ASSIGN') {
      if (!this.assignTo.bed || !this.assignTo.room) {
        dialogConfig.data = {
          title: this.Select_Room_Bed_Alert,
        };
        this.selectBedDialogRef = this.dialog.open(
          BedSelectorComponent,
          dialogConfig
        );

        this.selectBedDialogRef
          .afterClosed()
          .subscribe(
            (data: {
              room?: IHierarchy;
              bed?: IHierarchy;
              ward?: IHierarchy;
              canceled?: boolean;
            }) => {
              if (!data.canceled) {
                if (!!data.room.id && !!data.bed.id) {
                  this.assignTo.bed = data.bed.display_text;
                  this.assignTo.room = data.room.display_text;
                  this.assignTo.ward = data.ward.display_text;

                  this.openFeedbackDialog(type, selectedPatient);
                } else {
                  this._snackbar.open(this.Select_Room_Bed_Alert);
                  return;
                }
              } else {
                return;
              }
            }
          );
      } else {
        dialogConfig.data = {
          title: this.Assign_Patient_Dialog_Title,
          message: `${this.Assign_Patient_Dialog_Msg_Assign} ${selectedPatient.patient_name} ${this.Assign_Patient_Dialog_Msg_To} ${this.assignTo.room}-${this.assignTo.bed}?`,
        };

        this.userFeedBackDialogRef = this.dialog.open(
          UserFeedbackDialogComponent,
          dialogConfig
        );

        this.userFeedBackDialogRef.afterClosed().subscribe((data) => {
          if (
            type === 'ASSIGN' &&
            data &&
            this.assignTo.room &&
            this.assignTo.bed
          ) {
            const reqData = {
              id: selectedPatient.id,
              point_of_care: this.assignTo.ward,
              room: this.assignTo.room,
              bed: this.assignTo.bed,
            };
            this._patientService.assignPatient(reqData).subscribe((result) => {
              if (result) {
                this._snackbar.open(this.Patient_Assignment_Alert_Message);
                return;
              }
              this._snackbar.open(this.Patient_Assignment_Failed_Message);
            });
          } else if (
            type === 'ASSIGN' &&
            !data &&
            (this.assignTo.room || this.assignTo.bed)
          ) {
            this.assignTo.bed = undefined;
            this.assignTo.room = undefined;
            this.assignTo.ward = undefined;
          }
        });
      }
    }
  }
}
