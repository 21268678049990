import { Component, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { FilterService } from 'src/app/core/services/filter.service';
import { MapViewService } from 'src/app/core/services/map-view.service';

@Component({
  selector: 'app-base-layout',
  templateUrl: './base-layout.component.html',
  styleUrls: ['./base-layout.component.css'],
})
export class BaseLayoutComponent implements OnInit {
  constructor(
    private _filterService: FilterService,
    public mapViewService: MapViewService
  ) {
    // This is intentionally empty
  }

  selectedTabIndex: number = 0;
  selectedSortingCriteria: string = '';

  ngOnInit(): void {
    this._filterService.sortByObs.subscribe((data) => {
      this.selectedSortingCriteria = data;
    });
  }

  toggleSideView() {
    this.mapViewService.toggleSideView(undefined, true);
  }

  onTabChange(e: MatTabChangeEvent) {
    if (this.selectedTabIndex === 2) {
      this.mapViewService.clearReassignmentMode();
      this.mapViewService.toggleSideView();
    }
    this.selectedTabIndex = e.index;
  }

  onSortingCriteriaChange(selected: MatSelectChange) {
    this._filterService.updateSortBy(selected.value);
  }

  clearReassignmentMode() {
    this.mapViewService.clearReassignmentMode();
  }
}
