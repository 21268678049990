import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  constructor() {
    // This is intentionally empty
  }

  private _toolTipRefList: any[] = [];
  private _activeTooltipRef: any;
  private _isLocked$ = new BehaviorSubject<boolean>(false);
  public isLocked = this._isLocked$.asObservable();

  addTooltipRef(el: any) {
    this._toolTipRefList.push(el);
  }

  updateActiveTooltipRef(el: any) {
    this._activeTooltipRef = el;
  }

  clearActiveTooltip() {
    if (!!this._activeTooltipRef) {
      this._activeTooltipRef = undefined;
    }
  }

  async disposeTooltip() {
    if (!!this._activeTooltipRef) {
      console.log('DISPOSING TOOLTIP', this._activeTooltipRef);

      this._isLocked$.next(true);
      setTimeout(() => {
        this._isLocked$.next(false);
      }, 3000);

      await this._activeTooltipRef.hide();
      await this._activeTooltipRef.dispose();
    }
  }
}
