import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IHierarchy } from '../models/Hierarchy';

const Hierarchy_API_URL =
  '/admincore/api/v1/enthierarchy/getEnterprise';
const GET_Childern_API_URL =
  '/admincore/api/v1/enthierarchy/getChildren';

@Injectable({
  providedIn: 'root',
})
export class EnterpriseService {
  constructor(private _http: HttpClient) {
    this._loadEnterpriseHierarchy();
  }

  private _enterpriseHierarchy: BehaviorSubject<IHierarchy[]> =
    new BehaviorSubject<IHierarchy[]>([]);
  private _childHierarchies: BehaviorSubject<IHierarchy[]> =
    new BehaviorSubject<IHierarchy[]>([]);
  private _selectedHospital: BehaviorSubject<IHierarchy> =
    new BehaviorSubject<IHierarchy>(null);
  private _selectedWard: BehaviorSubject<IHierarchy> =
    new BehaviorSubject<IHierarchy>(null);

  readonly enterpriseHierarchy = this._enterpriseHierarchy.asObservable();
  readonly childHierarchies = this._childHierarchies.asObservable();
  readonly selectedHospital = this._selectedHospital.asObservable();
  readonly selectedWard = this._selectedWard.asObservable();

  private _selectedHospitalStore: IHierarchy = null;
  private _selectedWardStore: IHierarchy = null;

  private _loadEnterpriseHierarchy() {
    this._http.get(Hierarchy_API_URL).subscribe(
      (data: any) => {
        const hospitalId = localStorage.getItem('hs_id');
        if (hospitalId !=null && hospitalId !=undefined) {
          const hs = data.item.find((val) => val.id === parseInt(hospitalId));
          if (hs) {
            this.updateHospitalSelection(hs);
          } else {
            const hs1 = data.item[0];
            this.updateHospitalSelection(hs1);
            localStorage.setItem('hs_id', hs1.id);
          }
        } else {
          const hs = data.item[0];
          this.updateHospitalSelection(hs);
          localStorage.setItem('hs_id', hs.id);
        }
        this._loadChildHierarchy({
          identifier: this._selectedHospitalStore.identifier,
          ent_type: this._selectedHospitalStore.ent_type,
        });
        this._enterpriseHierarchy.next(data.item);
        this._selectedHospital.next(this._selectedHospitalStore);
      },
      (err) => {
        console.log('some error occured.');
      }
    );
  }

  getEnterpriseHierarchy(): Observable<IHierarchy[]> {
    return this._enterpriseHierarchy.asObservable();
  }

  getChildHierarchies(): Observable<IHierarchy[]> {
    return this._childHierarchies.asObservable();
  }

  private _loadChildHierarchy(item: { identifier: string; ent_type: string }) {
    item.ent_type = 'Enterprise';
    const requestData = {
      item: item,
    };
    this._http
      .post(GET_Childern_API_URL, requestData)
      .subscribe((data: any) => {
        const wardId = localStorage.getItem('wd_id');
        if (!!wardId) {
          const wd = data.item.find((val) => val.id === parseInt(wardId));
          if (wd) {
            this.updateWardSelection(wd);
            this._childHierarchies.next(data.item);
            this._selectedWard.next(this._selectedWardStore);
          } else {
            this.updateWardSelection(data.item[0]);
            localStorage.setItem('wd_id', data.item[0].id);
            this._childHierarchies.next(data.item);
            this._selectedWard.next(this._selectedWardStore);
          }
        } else {
          this.updateWardSelection(data.item[0]);
          localStorage.setItem('wd_id', data.item[0].id);
          this._childHierarchies.next(data.item);
          this._selectedWard.next(this._selectedWardStore);
        }
      });
  }

  getWardHierarchy(item: {
    identifier: string;
    ent_type: string;
    multilevel: boolean;
  }) {
    item.ent_type = 'Enterprise';
    return this._http.post(GET_Childern_API_URL, { item: item });
  }

  updateHospitalSelection(selectedHospital: IHierarchy) {
    console.log(selectedHospital);
    this._selectedHospitalStore = selectedHospital;
    localStorage.setItem('hs_id', selectedHospital.id);
    this._selectedHospital.next(selectedHospital);
    this._loadChildHierarchy(this._selectedHospitalStore);
  }

  updateWardSelection(selectedWard: IHierarchy) {
    localStorage.setItem('wd_id', selectedWard.id);
    this._selectedWardStore = selectedWard;
    this._selectedWard.next(selectedWard);
  }
}
