<div class="events-div" id="events">
  <div class="pump_header text-center">
    <label>{{ devicePatient?.device_name }}</label>
  </div>
  <h5 class="bg-blue-grey brdr-rad-top pt-1">
    {{ "HOME.ward-component-Event_Feed-Events" | translate }}
  </h5>
  <div class="input-group search_table bg-blue-grey p-2">
    <span id="basic-addon1" class="input-group-text">
      <img src="../../../../../assets/img/search_icon.svg" class="table_icon" alt="Search"
    /></span>
    <input
      id="search_input"
      type="text"
      placeholder="{{ 'GLOBAL.Search_Placeholder' | translate }}"
      class="form-control me-0"
      (keyup)="filterAlarmList($event.target.value)"
    />
    <button
      id="event_filter"
      type="button"
      class="icon_btn ms-2 me-2 hover-effect"
      (click)="openEventFilterDialog()"
    >
      <img src="../../../../../assets/img/filter.svg" alt="Filter" />
    </button>

    <button
      id="event_download"
      type="button"
      class="icon_btn hover-effect"
      [matMenuTriggerFor]="beforeMenu"
    >
      <img src="../../../../../assets/img/download.svg" alt="Download"/>
    </button>
    <mat-menu #beforeMenu="matMenu" xPosition="before" class="bg-black">
      <button (click)="exportEventsToExcel()" mat-menu-item id="download_excel">
        <img src="../../../../../assets/img/excel.png" alt="Excel" />
        {{ "HOME.ward-component-Event_Feed-ExcelSheet" | translate }}
      </button>

      <button
        id="download_pdf"
        (click)="exportToPdf('wards-events-table', 'events-filter')"
        mat-menu-item
      >
        <img src="../../../../../assets/img/pdf.png" alt="PDF"/>
        {{ "HOME.ward-component-Event_Feed-PDFFile" | translate }}
      </button>
      <button
        id="download_csv"
        (click)="exportToCsv('event_table', 'events-filter')"
        mat-menu-item
      >
        <img src="../../../../../assets/img/csv.png" alt="CSV" />
        {{ "HOME.ward-component-Event_Feed-CSV" | translate }}
      </button>
    </mat-menu>
  </div>
  <div *ngIf="alarmList.length !== 0">
    <div
      id="wards-events-table"
      *ngFor="let element of alarmList; let arrayIndex = index"
      class="events bg-dark-black brdr-rad-bottom p-2 custom_scroller"
    >
      <div class="event_list">
        <h5 class="bg-light-blue brdr-rad-top p-2">
          {{ element.date | date: "dd:MM:YYYY" }}
        </h5>

        <table [attr.id]="'event_table-' + arrayIndex" aria-label="Device Observations data">
          <tr
            *ngFor="let elementChild of element.observations; let i = index"
            class="label_grey"
            [ngClass]="
              elementChild.event_type !== 'ALERTS'
                ? 'label_grey'
                : 'label_purple'
            "
          >
            <th>{{ elementChild.event_time | date: "h:mm a" }}</th>
            <td>
              {{ elementChild.event_sub_type }} :
              {{ elementChild.event_desc }}
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div *ngIf="alarmList.length === 0">
    <p style="text-align: center; margin: 10px">
      {{
        "HOME.ward-component-Pump_Line_Chart-DataNotAvailable!!!" | translate
      }}
    </p>
  </div>
</div>
