import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css'],
})
export class LoginPageComponent implements OnInit {
  loginForm = new FormGroup({
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
  });
  isSubmitting: boolean;
  showPassword: boolean = false;

  constructor(
    private _notify: MatSnackBar,
    private _router: Router,
    private authService: AuthService
  ) {
    this.isSubmitting = false;
  }

  ngOnInit(): void {
    if (this.authService.isAuthenticated()) {
      this._router.navigate(['/ward']);
    }
  }

  getErrorMessage(controlName: string): string {
    if (controlName === 'username') {
      if (this.loginForm.controls[controlName].hasError('required')) {
        return 'Username is required.';
      }
    }

    if (controlName === 'password') {
      if (this.loginForm.controls[controlName].hasError('required')) {
        return 'Password is required.';
      }
    }
    return '';
  }

  submit() {
    this.isSubmitting = true;

    this.authService
      .authenticate(
        this.loginForm.value.username,
        this.loginForm.value.password
      )
      .subscribe((res) => {
        if (!res.success) {
          this.isSubmitting = false;
          this.loginForm.reset();
          this._notify.open(res.msg, 'close', {
            horizontalPosition: 'right',
            verticalPosition: 'top',
            duration: 3000,
          });
          return;
        }

        this._router.navigate(['/ward']);
      });
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }
}
