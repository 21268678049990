import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import {
  PatientSelectedForReportView,
  PumpType,
} from '../patient-medication-table-view/patient-medication-table-view.component';

export const Selected_Patient_For_Detail_Page_Key = 'slt_p_dt_id';
export const Selected_Device_For_Pump_Detail_Page_Key = 'slt_p_pm_dt_id';

@Component({
  selector: 'app-remaining-time-view',
  templateUrl: './remaining-time-view.component.html',
  styleUrls: ['./remaining-time-view.component.css'],
})
export class RemainingTimeViewComponent implements OnInit, AfterViewInit {
  constructor(private _router: Router) {
    // This is intentionally empty
  }

  @Input() set data(value: any[]) {
    this.dataSource = new MatTableDataSource<any[]>(value);
    if (!!this.sort)
      this.sort.sort({ id: null, start: 'desc', disableClear: false });
    this.dataSource.sort = this.sort;
    this.dataSource.sortData = (data: any[], sort: MatSort) => {
      return data.sort((a, b) => {
        if (sort.direction === 'asc') {
          return this.sortASC(a, b, sort.active);
        }

        return this.sortDESC(a, b, sort.active);
      });
    };
  }
  dataSource: MatTableDataSource<any[]>;
  @ViewChild(MatSort) sort: MatSort;

  ngOnInit(): void {
    // This is intentionally empty
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  displayedColumns: string[] = [
    'pump',
    'room',
    'patient_name',
    'drug_type',
    'drug_class',
    'medication',
    'concentration',
    'percentage_infused',
    'time_remaining',
    'rate',
    'volume_tbi',
    'volume_delivered',
    'message',
  ];

  openPatientDetail(id) {
    localStorage.setItem(Selected_Patient_For_Detail_Page_Key, id);
    if (this._router.url === '/ward') {
      this._router.navigate(['ward/patient-detail']);
    }

    if (this._router.url === '/alert') {
      this._router.navigate(['alert/patient-detail']);
    }
  }

  openPumpDetailView() {
    // This is intentionally empty
  }

  getRowClassString(alarm: string, stale_msg: string) {
    if (alarm) {
      return 'bg_red_row';
    } else if (stale_msg) {
      return 'bg_yellow_row';
    } else {
      return '';
    }
  }

  openReportPage(patient) {
    const path = this._getReportPath(patient.pump);
    const patientReportInput = {
      deviceId: patient.device_id,
      medicationId: patient.medication_id,
      patientId: patient.id,
    };
    localStorage.setItem(
      PatientSelectedForReportView,
      JSON.stringify(patientReportInput)
    );
    this._router.navigate([path]);
  }

  _getReportPath(pumpType: string): string {
    let path = '';

    if (pumpType.includes(PumpType.TCI)) {
      path = '/ward/patient/tci-report';
    } else if (pumpType.includes(PumpType.PCA)) {
      path = '/ward/patient/pca-report';
    } else {
      path = '/ward/patient/general-report';
    }

    return path;
  }

  sortASC(a: any, b: any, key: string) {
    if (!!a.alarm && !b.alarm) {
      return -1;
    }

    if (!a.alarm && !!b.alarm) {
      return 1;
    }

    if (!!a.stale_msg && !b.stale_msg) {
      return -1;
    }

    if (!a.stale_msg && !!b.stale_msg) {
      return 1;
    }

    return this.sortStringASC(a[key], b[key]);
  }

  sortDESC(a: any, b: any, key: string) {
    if (!!a.alarm && !b.alarm) {
      return -1;
    }

    if (!a.alarm && !!b.alarm) {
      return 1;
    }

    if (!!a.stale_msg && !b.stale_msg) {
      return -1;
    }

    if (!a.stale_msg && !!b.stale_msg) {
      return 1;
    }

    return this.sortStringDESC(a[key], b[key]);
  }

  sortStringASC(a: string, b: string) {
    if (a > b) {
      return -1;
    }
    if (a < b) {
      return 1;
    }
    return 0;
  }

  sortStringDESC(a: string, b: string) {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  }

  openDetail(item) {
    localStorage.setItem(
      Selected_Device_For_Pump_Detail_Page_Key,
      JSON.stringify(item)
    );

    const path = '/ward/patient/pump-detail/';
    this._router.navigate([path]);
  }
}
