import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PatientService } from 'src/app/core/services/patient.service';
import {
  PatientSelectedForReportView,
  PumpType,
} from 'src/app/modules/ward/component/patient-medication-table-view/patient-medication-table-view.component';
import { Selected_Device_For_Pump_Detail_Page_Key } from 'src/app/modules/ward/component/remaining-time-view/remaining-time-view.component';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-order-tab-view',
  templateUrl: './order-tab-view.component.html',
  styleUrls: ['./order-tab-view.component.css'],
})
export class OrderTabViewComponent implements OnInit {
  constructor(
    private patientService: PatientService,
    private dialog: MatDialog,
    private _router: Router
  ) {
    // This is intentionally empty
  }

  patientDetail: any;
  patientObservable: Subscription;
  displayedColumns: string[] = [
    'action',
    'drug_type',
    'drug_name',
    'concentration',
    'total_volume',
    'dose_rate',
    'volume_tbi',
    'time',
    'order_Date',
    'admin_date',
    'status',
  ];
  dataSource: MatTableDataSource<any[]>;
  @ViewChild(MatSort) sort: MatSort;
  listProps = {
    page: 0,
    limit: 20,
    sortBy: '',
    sort: 'asc',
    filter: '',
  };
  totalCount: number = 0;
  searchControl: FormControl;
  patientId: string;
  
  ngOnInit(): void {
    this.patientObservable = this.patientService.patientDetailIdObs.subscribe(
      (res) => {
        this.patientId = res;
        this.getPatientDetail(res);
      }
    );
    this.searchControl = new FormControl();
    this.searchControl.valueChanges.pipe(debounceTime(500)).subscribe(res => {
      this.applyFilter(res);
    });
  }

  getPatientDetail(id) {
    this.patientService
      .getPatientMedicationOrderDetailById(id, "all", this.listProps)
      .subscribe((res) => {
        this.patientDetail = res;
        this.dataSource = new MatTableDataSource<any[]>(this.patientDetail.orders);
        this.totalCount=this.patientDetail.total_count!==undefined ? this.patientDetail.total_count : 0;
      });
  }

  openReportPage(patient) {
    const path = this._getReportPath(patient.pump);
    const patientReportInput = {
      deviceId: patient.device_id,
      medicationId: patient.medication_id,
      patientId: patient.id,
    };
    localStorage.setItem(
      PatientSelectedForReportView,
      JSON.stringify(patientReportInput)
    );
    this.dialog.closeAll();
    this._router.navigate([path]);
  }

  _getReportPath(pumpType: string): string {
    let path = '';

    if (pumpType.includes(PumpType.TCI)) {
      path = '/ward/patient/tci-report';
    } else if (pumpType.includes(PumpType.PCA)) {
      path = '/ward/patient/pca-report';
    } else {
      path = '/ward/patient/general-report';
    }

    return path;
  }

  openDetail(item) {
    localStorage.setItem(
      Selected_Device_For_Pump_Detail_Page_Key,
      JSON.stringify(item)
    );
    const path = '/ward/patient/pump-detail/';
    this.dialog.closeAll();
    this._router.navigate([path]);
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.listProps.filter = filterValue;
    this.getPatientDetail(this.patientId);
  }

  onPageChange(event: PageEvent) {
    this.listProps = {
      ...this.listProps,
      page: event.pageIndex,
      limit: event.pageSize,
    };
    this.getPatientDetail(this.patientId);
  }

  onSort(sort: Sort) {
    this.listProps = {
      ...this.listProps,
      sortBy: sort.active,
      sort: sort.direction,
    };
    this.getPatientDetail(this.patientId);
  }
}
