import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from 'rxjs';

// import { Option } from "./option.model";
import { StyleManagerService } from "./style-manager.service";


@Injectable()
export class ThemeService {
  private _selectedTheme: BehaviorSubject<string>;
  readonly selectedTheme: Observable<string>;

  private _default: string;

  constructor(
    private http: HttpClient,
    private styleManager: StyleManagerService
  ) {
    let _selectedTheme = localStorage.getItem('theme') || 'custom';
    this._default = _selectedTheme;

    this._selectedTheme = new BehaviorSubject<string>(_selectedTheme);
    this.selectedTheme = this._selectedTheme.asObservable();
    this._selectedTheme.next(_selectedTheme);
  }
  

  getThemeOptions(): Observable<Array<any>> {
    return this.http.get<Array<any>>("assets/options.json");
  }

  setTheme(themeToSet) {
    localStorage.setItem('theme', themeToSet);
    this.styleManager.setStyle(
      "theme",
      `assets/css/${themeToSet}.css`
    );
  }

  getSelectedTheme() {
    return this.getThemeFromStorage(); 
  }

  setDefaultTheme(theme){
    this.setTheme(theme);
  }


  getThemeFromStorage(){
    return localStorage.getItem('theme')
  }

}
