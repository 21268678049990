import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-view',
  templateUrl: './table-view.component.html',
  styleUrls: ['./table-view.component.css'],
})
export class TableViewComponent implements OnInit {
  constructor() {
    // This is intentionally empty
  }
  @Input() data: any;

  displayedColumns: string[] = [
    'pump',
    'room',
    'type',
    'patient',
    'class',
    'medication',
    'infused',
    'remaining',
    'rate',
    'vtbi',
    'vi',
    'message',
  ];

  ngOnInit(): void {
    // This is intentionally empty
  }
  openPumpDetailView() {
    console.log('gvjfjhvfhfhfhgchgc');
  }

  getRowClassString(alarm: string, stale_msg: string) {
    if (alarm) {
      return 'bg_red_row';
    } else if (stale_msg) {
      return 'bg_yellow_row';
    } else {
      return '';
    }
  }
}
