import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { RotationService } from 'src/app/core/services/rotation.service';
import { SettingService } from 'src/app/core/services/setting.service';
import { PatientAssociationService } from '../../services/patient-association.service';
import { PatientAssociationDialogComponent } from '../patient-association-dialog/patient-association-dialog.component';

const MAP_VIEW_TAB_INDEX = 2;

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css'],
})
export class FilterComponent implements OnInit {
  constructor(
    private dialog: MatDialog,
    public pageRotationService: RotationService,
    public settingService:SettingService,
    private _patientAssociationService: PatientAssociationService
  ) {
    // This is intentionally empty
  }

  selectedRotationTime: string;
  patientAssociationDialogRef: MatDialogRef<PatientAssociationDialogComponent>;
  @Input() currentTabIndex: number;
  mapViewTabIndex: number = MAP_VIEW_TAB_INDEX;
  @Output() toggleSideViewEvent = new EventEmitter<void>();
  isAssignmentView: boolean = false;
  setting:any={};

  ngOnInit(): void {
    this.selectedRotationTime = this.pageRotationService
      .getCurrentRotationTime()
      .toString();

      this.settingService.getAppSetting().subscribe(data=>{
        this.setting = data;
        this.selectedRotationTime = localStorage.getItem("rotationTime");
        this.pageRotationService.updateRotationTime(parseInt(this.selectedRotationTime)); 
      })
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {};
    dialogConfig.data['patient'] =
      this._patientAssociationService.getSelectedPatient();

    this.patientAssociationDialogRef = this.dialog.open(
      PatientAssociationDialogComponent,
      dialogConfig
    );

    this.patientAssociationDialogRef
      .afterClosed()
      .subscribe((data) => console.log('Dialog output:', data));
  }

  changeRotationValue(e) {
    localStorage.setItem("rotationTime", e.value);
    this.pageRotationService.updateRotationTime(parseInt(e.value));
  }

  toggleSideView() {
    this.isAssignmentView = !this.isAssignmentView;
    this.toggleSideViewEvent.emit();
  }

  onSelectionChange(e) {
    console.log(e);
  }

  refreshData() {
    this.pageRotationService.refreshDataEvent();
  }
}
