<section [ngClass]="_authService.isAuthenticated() ? 'main' : 'login_page'">
    <div class="main_wrapper">
            <app-header *ngIf="_authService.isAuthenticated()">
                
            </app-header>
            <div class="inner_body custom_scroller">

                <!-- too inject the content -->
                <ng-content select="router-outlet">

                </ng-content>
            </div>
            

            <app-footer></app-footer>
    </div>
</section>
