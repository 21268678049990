<div class="d-flex">
<div class="filter_div hospital_dropdown_class">
  <label>{{'HOME.shared-component-Hierarchy_Filter-Hospital' | translate}}</label>
  <mat-form-field appearance="outline" class="mr-3 custom_mat_select">
    <mat-select id="hospital_filter" placeholder="{{'GLOBAL.Choose_an_option' | translate}}" [value]="selectedEnterprise" (selectionChange)="onHospitalChange($event)">
      <mat-option [value]="hierarchy" [id]="'select_hospital_' + i" *ngFor="let hierarchy of enterprises let i = index">
        {{hierarchy.display_text}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div class="filter_div ward_dropdown_class">
  <label>{{'HOME.shared-component-Hierarchy_Filter-Ward' | translate}}</label>
  <mat-form-field appearance="outline" class="mr-3 custom_mat_select">
    <mat-select id="ward_filter" placeholder="{{'GLOBAL.Choose_an_option' | translate}}" [value]="selectedWard" (selectionChange)="onWardChange($event)">
      <mat-option [value]="ward" [id]="'select_ward_' + i" *ngFor="let ward of wards; let i = index"> {{ward.display_text}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
</div>
