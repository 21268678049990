import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PatientService } from 'src/app/core/services/patient.service';
import { PatientEditorService } from '../../services/patient-editor.service';

@Component({
  selector: 'app-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.css'],
})
export class PatientListComponent implements OnInit {
  constructor(
    public patientEditorService: PatientEditorService,
    public patientService: PatientService,
    public router:Router
  ) {
    // This is intentionally empty
  }
  
  panelOpenState = false;
  step = 0;
  unassignedPatientList = false;
  dischargedPatientList = false;


  ngOnInit(): void {
    // This is intentionally empty
  }

  setStep(index: number) {
    this.step = index;
  }

  goToPatientDetail() {
    this.router.navigateByUrl('/patient/detail');
  }


}
