<div class="patient_form bg-black p-3" [formGroup]="patientDetailForm">
  <h3 class="text-white">
    {{ "HOME.patient-component-Patient_Detail_Form-PatientName" | translate }}
  </h3>

  <div class="row">
    <div class="col col-md-6">
      <div class="form_field">
        <label>
          {{
            "HOME.patient-component-Patient_Detail_Form-FirstName" | translate
          }}
        </label>
        <mat-form-field appearance="outline" class="mb-2">
          <input
            id="first_name_input"
            matInput
            placeholder="{{
              'HOME.patient-component-Patient_Detail_Form-First' | translate
            }}"
            type="text"
            formControlName="first_name"
          />
        </mat-form-field>
      </div>

      <div class="form_field">
        <label
          >{{
            "HOME.patient-component-Patient_Detail_Form-MiddleName" | translate
          }}
        </label>
        <mat-form-field appearance="outline" class="mb-2">
          <input
            id="middle_name_input"
            matInput
            placeholder="{{
              'HOME.patient-component-Patient_Detail_Form-Middle' | translate
            }}"
            type="text"
            formControlName="middle_name"
          />
        </mat-form-field>
      </div>

      <div class="form_field">
        <label
          >{{
            "HOME.patient-component-Patient_Detail_Form-LastName" | translate
          }}
        </label>
        <mat-form-field appearance="outline" class="mb-2">
          <input
            id="last_name_input"
            matInput
            placeholder="{{
              'HOME.patient-component-Patient_Detail_Form-Last' | translate
            }}"
            type="text"
            formControlName="last_name"
          />
        </mat-form-field>
      </div>

      <div class="form_field">
        <label>
          {{
            "HOME.patient-component-Patient_Detail_Form-DateOfBirth" | translate
          }}
        </label>
        <mat-form-field appearance="outline" class="mb-2">
          <input
            id="date_select_input"
            matInput
            placeholder="Choose a date"
            type="date"
            (change)="onDOBChange($event)"
            formControlName="dob"
          />
        </mat-form-field>
      </div>

      <div class="form_field">
        <label>
          {{ "HOME.patient-component-Patient_Detail_Form-Age" | translate }}
        </label>
        <p class="mb-2">
          {{ patientDetailForm.value.age }}
          {{ "HOME.patient-component-Patient_Detail_Form-Years" | translate }}
        </p>
      </div>


      <!-- <div class="profile_details align-items-center row mb-2">
        <label class = "col-4">{{
          "Gender" | translate
        }}</label>
        <mat-form-field appearance="outline" class="ml-5 custom_mat_select col-8">
          <mat-select
            id="gender_select"
            placeholder="{{ 'GLOBAL.Choose_an_option' | translate }}"
            formControlName="gender"
          >
            <mat-option value="M">Male</mat-option>
            <mat-option value="F">Female</mat-option>
            <mat-option value="T">Transgender</mat-option>
            <mat-option value="N">Not Given</mat-option>



          </mat-select>
        </mat-form-field>
      </div> -->

      
      <div class="profile_details align-items-center row">
        <label class = "col-4">{{'Gender' | translate}}</label>
        <mat-form-field appearance="outline" class="ml-5 custom_mat_select col-8">
          <mat-select id="ward_filter" placeholder="{{'GLOBAL.Choose_an_option' | translate}}" formControlName="gender" (selectionChange)="onWardChange($event)">
            <mat-option [value]="ref.identifier" *ngFor="let ref of reference"> {{ref.display_text}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      
      <div class="form_field">
        <label
          >{{ "HOME.patient-component-Patient_Detail_Form-Height" | translate }}
        </label>
        <mat-form-field appearance="outline" class="mb-2">
          <input
            id="height_input"
            matInput
            placeholder="{{
              'HOME.patient-component-Patient_Detail_Form-Height' | translate
            }}"
            formControlName="height"
          />
          <span class="measure">{{
            "HOME.patient-component-Patient_Detail_Form-Cm" | translate
          }}</span>
        </mat-form-field>
      </div>

      <div class="form_field">
        <label
          >{{ "HOME.patient-component-Patient_Detail_Form-Weight" | translate }}
        </label>
        <mat-form-field appearance="outline" class="mb-2">
          <input
            id="weight_input"
            matInput
            placeholder="{{
              'HOME.patient-component-Patient_Detail_Form-Weight' | translate
            }}"
            formControlName="weight"
          />
          <span class="measure">{{
            "HOME.patient-component-Patient_Detail_Form-Kg" | translate
          }}</span>
        </mat-form-field>
      </div>
    </div>

    <!-- ---------------------------------------- -->

    <div class="col col-md-6">
      <div class="form_field">
        <label>
          {{ "Visit Number" | translate }}
        </label>
        <mat-form-field appearance="outline" class="mb-2">
          <input
            id="visit_number"
            matInput
            placeholder="{{ 'Visit Number' | translate }}"
            type="text"
            formControlName="visit_number"
          />
        </mat-form-field>
      </div>

      <div class="form_field">
        <label>
          {{ "Admission Date" | translate }}
        </label>
        <mat-form-field appearance="outline" class="mb-2">
          <input
            id="admission_dttm"
            matInput
            placeholder="Choose a date"
            type="date"
            formControlName="admission_dttm"
          />
        </mat-form-field>
      </div>
      <!--     
      <div class="form_field">
        <label
          >{{ "HOME.patient-component-Patient_Detail_Form-MiddleName" | translate }}
        </label>
        <mat-form-field appearance="outline" class="mb-2">
          <input
            id="middle_name_input"
            matInput
            placeholder="{{
              'HOME.patient-component-Patient_Detail_Form-Middle' | translate
            }}"
            type="text"
            formControlName="middle_name"
          />
        </mat-form-field>
      </div> -->
      <div class="profile_details align-items-center row">
        <label class = "col-4">{{'Point Of Care' | translate}}</label>
        <mat-form-field appearance="outline" class="ml-5 custom_mat_select col-8">
          <mat-select id="ward_filter" placeholder="{{'GLOBAL.Choose_an_option' | translate}}" formControlName="poc" (selectionChange)="onWardChange($event)">
            <mat-option [value]="ward.identifier" *ngFor="let ward of wards"> {{ward.display_text}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- <div *ngIf="show_room" class="profile_details align-items-center row">
        <label class = "col-4">{{'Room' | translate}}</label>
        <mat-form-field appearance="outline" class="ml-5 custom_mat_select col-8">
          <mat-select id="ward_filter" placeholder="{{'GLOBAL.Choose_an_option' | translate}}" formControlName="poc" (selectionChange)="onRoomChange($event)">
            <mat-option [value]="ward" *ngFor="let ward of wards"> {{ward.display_text}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="show_room && show_bed" class="profile_details align-items-center row">
        <label class = "col-4">{{'Bed' | translate}}</label>
        <mat-form-field appearance="outline" class="ml-5 custom_mat_select col-8">
          <mat-select id="ward_filter" placeholder="{{'GLOBAL.Choose_an_option' | translate}}" formControlName="poc" (selectionChange)="onWardChange($event)">
            <mat-option [value]="ward" *ngFor="let ward of wards"> {{ward.display_text}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->

      <!-- <div class="form_field">
        <label
          >{{ "HOME.patient-component-Patient_Detail_Form-LastName" | translate }}
        </label>
        <mat-form-field appearance="outline" class="mb-2">
          <input
            id="last_name_input"
            matInput
            placeholder="{{
              'HOME.patient-component-Patient_Detail_Form-Last' | translate
            }}"
            type="text"
            formControlName="last_name"
          />
        </mat-form-field>
      </div>  -->
    </div>

    <!-- ---------------------------------------- -->
  </div>

  <div class="row mt-4 mb-4 id_row">
    <div class="col-lg-6 col-md-6">
      <label class="filter_label">{{
        "HOME.patient-component-Patient_Detail_Form-Ids" | translate
      }}</label>
      <div class="ids">
        <div class="d-flx-spc-btwn" *ngFor="let id of ids; let i = index">
          <span>{{ id?.type }}</span> <span>{{ id?.id }}</span>
          <span (click)="removeIds(i)">
            <mat-icon>close</mat-icon>
          </span>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-md-6">
      <!-- <div class="filter_div">
        <label>{{
          "HOME.patient-component-Patient_Detail_Form-Type" | translate
        }}</label>
        <mat-form-field appearance="outline" class="custom_mat_select">
          <mat-select
            id="type_select"
            placeholder="{{ 'GLOBAL.Choose_an_option' | translate }}"
            formControlName="type"
            
          >
            <mat-option value="SSN">SSN</mat-option>
            <mat-option value="DL">DL</mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->


      <div class="profile_details align-items-center row">
        <label class = "col-4">{{"HOME.patient-component-Patient_Detail_Form-Type" | translate}}</label>
        <mat-form-field appearance="outline" class="ml-5 custom_mat_select col-8">
          <mat-select id="type_select" placeholder="{{'GLOBAL.Choose_an_option' | translate}}" formControlName="type" (selectionChange)="onWardChange($event)">
            <mat-option [value]="ide.identifier" *ngFor="let ide of patient_ide_type"> {{ide.display_text}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <label class="filter_label"
        >{{ "HOME.patient-component-Patient_Detail_Form-Id" | translate }}
      </label>
      <div class="form_field">
        <mat-form-field appearance="outline" class="mb-3 w-100">
          <input
            id="form_id_input"
            matInput
            placeholder="{{
              'HOME.patient-component-Patient_Detail_Form-EnterId' | translate
            }}"
            formControlName="ids"
          />
        </mat-form-field>
      </div>

      <button
        id="save_id_btn"
        type="button"
        class="success_btn custom_btn"
        (click)="savePatienId()"
      >
        {{ "HOME.patient-component-Patient_Detail_Form-SaveId" | translate }}
      </button>
    </div>
  </div>
</div>
