import { Injectable } from '@angular/core';
import { IPatient } from 'src/app/core/models/Patient';

@Injectable({
  providedIn: 'root',
})
export class PatientAssociationService {
  constructor() {
    // This is intentionally empty
  }

  private _selectedPatient: IPatient;

  selectPatient(patient: IPatient) {
    this._selectedPatient = patient;
    console.log(this._selectedPatient);
  }

  getSelectedPatient() {
    return this._selectedPatient;
  }
}
