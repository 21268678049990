import { Component, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IPatient } from 'src/app/core/models/Patient';
import { PatientService } from 'src/app/core/services/patient.service';
import { Selected_Patient_For_Detail_Page_Key } from '../../component/room-card-view/room-card-view.component';
import { UserFeedbackDialogComponent } from '../../component/user-feedback-dialog/user-feedback-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-patient-detail-view',
  templateUrl: './patient-detail-view.component.html',
  styleUrls: ['./patient-detail-view.component.css'],
})
export class PatientDetailViewComponent implements OnInit {
  constructor(
    private dialog: MatDialog,
    private _patientService: PatientService,
    private _router: Router,
    private _langService: TranslateService,
    private _snackBar: MatSnackBar
  ) {
    this._langService
      .get([
        'GLOBAL.Patient_Reassignment_Alert_Message',
        'GLOBAL.Reassignment_Failed_Alert_Msg',
        'GLOBAL.Discharge_Patient_Alert_Message',
        'GLOBAL.Discharge_Patient_Failed_Message',
        'GLOBAL.Assign_Patient_Dialog_Title',
        'GLOBAL.Assign_Patient_Dialog_Msg_Assign',
        'GLOBAL.Assign_Patient_Dialog_Msg_To',
        'GLOBAL.Discharge_Patient_Dialog_Title',
        'GLOBAL.Discharge_Patient_Dialog_Msg_Part1',
        'GLOBAL.Discharge_Patient_Dialog_Msg_Part2'
      ])
      .subscribe((ln) => {
        this.Reassignment_Alert_Msg =
          ln['GLOBAL.Patient_Reassignment_Alert_Message'];
        this.Reassignment_Failed_Alert_Msg =
          ln['GLOBAL.Reassignment_Failed_Alert_Msg'];
        this.Discharge_Patient_Alert_Message =
          ln['GLOBAL.Discharge_Patient_Alert_Message'];
        this.Discharge_Patient_Failed_Message =
          ln['GLOBAL.Discharge_Patient_Failed_Message'];
          this.Assign_Patient_Dialog_Title =
          ln['GLOBAL.Assign_Patient_Dialog_Title'];
        this.Assign_Patient_Dialog_Msg_Assign =
          ln['GLOBAL.Assign_Patient_Dialog_Msg_Assign'];
        this.Assign_Patient_Dialog_Msg_To =
          ln['GLOBAL.Assign_Patient_Dialog_Msg_To'];
        this.Discharge_Patient_Dialog_Title =
          ln['GLOBAL.Discharge_Patient_Dialog_Title'];
        this.Discharge_Patient_Dialog_Msg_Part1 =
          ln['GLOBAL.Discharge_Patient_Dialog_Msg_Part1'];
        this.Discharge_Patient_Dialog_Msg_Part2 =
          ln['GLOBAL.Discharge_Patient_Dialog_Msg_Part2'];
      });
  }

  private Reassignment_Alert_Msg;
  private Reassignment_Failed_Alert_Msg;
  private Discharge_Patient_Alert_Message;
  private Discharge_Patient_Failed_Message;
  private Assign_Patient_Dialog_Title;
  private Assign_Patient_Dialog_Msg_Assign;
  private Assign_Patient_Dialog_Msg_To;
  private Discharge_Patient_Dialog_Title;
  private Discharge_Patient_Dialog_Msg_Part1;
  private Discharge_Patient_Dialog_Msg_Part2;

  selectedPatient: IPatient;

   //alerts message string from lang file


  ngOnInit(): void {
    const selectedPatientId = localStorage.getItem(
      Selected_Patient_For_Detail_Page_Key
    );

    if (!selectedPatientId) {
      this._router.navigate(['/ward']);
    }

    console.log('#1', selectedPatientId);
    this._patientService
      .getPatientMedicationDetailById(selectedPatientId)
      .subscribe((data) => {
        console.log('#2', data);
        this.selectedPatient = data;
      });
  }

  userFeedBackDialogRef: MatDialogRef<UserFeedbackDialogComponent>;

  openFeedbackDialog(type: string) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    if (type === 'REASSIGN') {
      dialogConfig.data = {
        title: this.Assign_Patient_Dialog_Title,
        message: `${this.Assign_Patient_Dialog_Msg_Assign} ${this.selectedPatient.patient_name} ${this.Assign_Patient_Dialog_Msg_To} ${this.selectedPatient.room}-${this.selectedPatient.bed}?`,
      };
    }

    if (type === 'DISCHARGE') {
      dialogConfig.data = {
        title: this.Discharge_Patient_Dialog_Title,
        message: `${this.Discharge_Patient_Dialog_Msg_Part1} ${this.selectedPatient.patient_name}${this.Discharge_Patient_Dialog_Msg_Part2}`,
      };
    }

    this.userFeedBackDialogRef = this.dialog.open(
      UserFeedbackDialogComponent,
      dialogConfig
    );

    this.userFeedBackDialogRef.afterClosed().subscribe((data) => {
      if (type === 'REASSIGN' && data) {
        const reqData = {
          id: this.selectedPatient.id,
          point_of_care: false,
          room: '301',
          bed: '02',
        };
        this._patientService.assignPatient(reqData).subscribe((result) => {
          if (result) {
            this._snackBar.open(this.Reassignment_Alert_Msg);
            return;
          }
          this._snackBar.open(this.Reassignment_Failed_Alert_Msg);
        });
      }

      if (type === 'DISCHARGE' && data) {
        this._patientService
          .dischargePatient(this.selectedPatient.id)
          .subscribe((result) => {
            if (result) {
              this._snackBar.open(this.Discharge_Patient_Alert_Message);
              this._router.navigate(['/ward']);
              return;
            }
            this._snackBar.open(this.Discharge_Patient_Failed_Message);
          });
      }
    });
  }
}
