import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { IHierarchy } from 'src/app/core/models/Hierarchy';
import { EnterpriseService } from 'src/app/core/services/enterprise.service';
import { PatientService } from 'src/app/core/services/patient.service';

@Component({
  selector: 'app-bed-selector',
  templateUrl: './bed-selector.component.html',
  styleUrls: ['./bed-selector.component.css'],
})
export class BedSelectorComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _enterpriseService: EnterpriseService,
    private patientService: PatientService,
    private _langService: TranslateService
  ) {
    this._langService
      .get(['GLOBAL.Choose_an_option', 'GLOBAL.Bed_Not_Available_Msg'])
      .subscribe((ln) => {
        this.chooseAnOptionMsg = ln['GLOBAL.Choose_an_option'];
        this.bedNotAvailableMsg = ln['GLOBAL.Bed_Not_Available_Msg'];
      });
  }

  chooseAnOptionMsg;
  bedNotAvailableMsg;

  roomFormControl = new FormControl();
  bedFormControl = new FormControl();
  selectedRoom: Partial<IHierarchy> = {};
  selectedBed: Partial<IHierarchy> = {};
  currentWard: IHierarchy;
  dataBed: IHierarchy[] = [];
  wardhierarchy: IHierarchy[] = [];

  ngOnInit(): void {
    this._enterpriseService.selectedWard.subscribe((ward) => {
      if (!!ward) {
        this.currentWard = ward;
        const requestData = {
          identifier: ward.identifier,
          ent_type: ward.ent_type,
          multilevel: true,
        };
        this.patientService
          .getWardChildrenWithStatus(requestData)
          .subscribe((data) => {
            this.wardhierarchy = data.filter(
              (val) => val.children.filter((t) => !t.is_occupied).length > 0
            );
          });
      }
    });
  }

  displayFn(hierarchy: IHierarchy): string {
    return hierarchy && hierarchy.display_text ? hierarchy.display_text : '';
  }

  onRoomSelected(selected: MatAutocompleteSelectedEvent) {
    this.selectedRoom = selected.option.value;

    this.dataBed = this.selectedRoom.children;
  }

  onBedSelected(selected: MatAutocompleteSelectedEvent) {
    this.selectedBed = selected.option.value;
  }
}
