import { Component, HostListener } from '@angular/core';
import { AuthService } from './core/services/auth.service';
import { UserActivityService } from './core/services/user-activity.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  constructor(
    private _userActivityService: UserActivityService,
    private _authService: AuthService
  ) {
    this._userActivityService.setActivityWatch();
  }

  title = 'Infusion Central';

  @HostListener('window:keydown')
  @HostListener('window:mousedown')
  @HostListener('window:mousemove')
  @HostListener('window:wheel')
  checkUserActivity() {
    this._userActivityService.setActivityWatch();
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler($event) {
    //this._authService.logout();
  }
}
