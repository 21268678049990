<div class="map_table assigned_table">
  <div class="search_table">
    <div class="input-group mb-3">
      <span class="input-group-text" id="basic-addon1">
        <img src="../../../../../assets/img/search_icon.svg" class="table_icon" alt="Search" /> </span>
      <input id="unassigned_search" type="text" class="form-control" placeholder="{{'GLOBAL.Search_Placeholder' | translate}}"
        [formControl]="searchControl">
    </div>
  </div>
  <div class="map_table tab_view_table">
    <table mat-table [dataSource]="dataSource" matSort class="w_900" aria-label="Patient List data"
      (matSortChange)="onSort($event)" id="unassigned_patient_table">
      <ng-container matColumnDef="admit_date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{'HOME.patient-component-Unassigned_Patient_Table_View-AdmitDate' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.admit_date}} </td>
      </ng-container>


      <ng-container matColumnDef="patient_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{'HOME.patient-component-Unassigned_Patient_Table_View-Patient' | translate}} </th>
        <td mat-cell *matCellDef="let element" class="min-wdt_150"> {{element.patient_name}}
        </td>
      </ng-container>

      <ng-container matColumnDef="age">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{'HOME.patient-component-Unassigned_Patient_Table_View-Age' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.age}}
          {{'HOME.patient-component-Unassigned_Patient_Table_View-Years' | translate}}</td>
      </ng-container>


      <ng-container matColumnDef="height">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{'HOME.patient-component-Unassigned_Patient_Table_View-Height' | translate}}</th>
        <td mat-cell *matCellDef="let element"> {{element.height}}
        </td>
      </ng-container>


      <ng-container matColumnDef="weight">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{'HOME.patient-component-Unassigned_Patient_Table_View-Weight' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.weight}}
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>
          {{'HOME.patient-component-Unassigned_Patient_Table_View-Action' | translate}} </th>
        <td mat-cell *matCellDef="let element">

          <div class="table_icon_div">
            <img id="dialog_open_btn" src="../../../../../assets/img/ward_icon.svg" (click)="openFeedbackDialog('ASSIGN', element)"
              class="table_icon" alt="Ward">
            <img id="patient_editor_btn" src="../../../../../assets/img/edit.svg" class="table_icon" alt="Edit"
              (click)="patientEditorService.openDialog(element.id)">

          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="9999">
          {{'HOME.patient-component-Unassigned_Patient_Table_View-NoDataAvailable' | translate}}
        </td>
      </tr>
    </table>
  </div>
</div>
<div class="custom_pagination">
  <mat-paginator [length]="totalCount" [pageSizeOptions]="[20, 30, 50, 100]" aria-label="Select page"
    (page)="onPageChange($event)">
  </mat-paginator>
</div>