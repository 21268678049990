import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  Observable,
  of,
  pipe,
} from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { IHierarchy, ListProps } from '../models/Hierarchy';
import { IResponse } from '../models/HttpRequest';
import { IPatient } from '../models/Patient';
import { EnterpriseService } from './enterprise.service';
import { FilterService } from './filter.service';
import { RotationService } from './rotation.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class PatientService {
  constructor(
    private _http: HttpClient,
    private _enterpriseService: EnterpriseService,
    private _filterService: FilterService,
    private _rotationService: RotationService
  ) {
    //Hierarchy filter listener
    //Listen for hiearchy filter changes and fetch data accordingly
    this._setHierarchyFilterListner();

    //Search filter listner
    //Listen for search text changes and fetch data accordingly
    this._setSearchListnerForPatientMedication();
    this._setSearchListnerForMedicationTimeView();
    this._setSearchListnerForPatientList();

    //sorting listener
    this._setSortCriteriaListner();

    //refresh data listener
    this._rotationService.refreshDataObs.subscribe((data) => {
      this._loadPatientList();
      this._loadPatientMedicationData();
      this._loadmedicationTimeViewData();
    });

    //fetch data after updating pagination, filter or sorting
    this.patientFilterListPropsObs.subscribe((data) => {
      this._loadPatientList();
    });
  }

  public currentOp: string = localStorage.getItem('currentOp');
  public _patientMedicationId: any;
  private _patientMedicationList: BehaviorSubject<IPatient[]> =
    new BehaviorSubject<IPatient[]>([]);
  private _medicationTimeView: BehaviorSubject<any[]> = new BehaviorSubject<
    any[]
  >([]);
  private _patientList: BehaviorSubject<any> = new BehaviorSubject<any>({});
  private _hierarchyFilter: any = {};

  private _sortBy: string = '';
  private _patientMedicationFilteredList: BehaviorSubject<any[]> =
    new BehaviorSubject<any[]>([]);
  private _medicationTimeViewFilteredList: BehaviorSubject<any[]> =
    new BehaviorSubject<any[]>([]);
  private _patientFilteredList: BehaviorSubject<any[]> = new BehaviorSubject<
    any[]
  >([]);
  public _patientFilteredListProps: BehaviorSubject<ListProps> =
    new BehaviorSubject<ListProps>({
      page: 0,
      limit: 20,
      sortBy: '',
      sort: 'asc',
      filter: '',
    });
  private _patientDetailId: BehaviorSubject<any> = new BehaviorSubject<any>(
    localStorage.getItem('pt_det_k') || {}
  );

  public assignedPatientCount = 0;
  public dischargePatientCount = 0;
  public unassignedPatientCount = 0;

  readonly patientMedicationListObs =
    this._patientMedicationList.asObservable();
  readonly patientMedicationFilteredListObs =
    this._patientMedicationFilteredList.asObservable();
  readonly medicationTimeViewObs = this._medicationTimeView.asObservable();
  readonly medicationFilteredTimeViewObs =
    this._medicationTimeViewFilteredList.asObservable();
  readonly patientListObs = this._patientList.asObservable();
  readonly patientFilteredListObs = this._patientFilteredList.asObservable();
  readonly patientDetailIdObs = this._patientDetailId.asObservable();
  readonly patientFilterListPropsObs =
    this._patientFilteredListProps.asObservable();

  private _setHierarchyFilterListner() {
    this._enterpriseService.selectedHospital.subscribe((val) => {
      this._setHospitalFilter(val);
    });
    this._enterpriseService.selectedWard.subscribe((val) => {
      this._setWardFilter(val);
    });
  }

  private _setHospitalFilter(selection: IHierarchy) {
    if (!!selection) {
      if (
        (!!this._hierarchyFilter['hospital'] &&
          selection.id !== this._hierarchyFilter['hospital'].id) ||
        !this._hierarchyFilter['hospital']
      ) {
        this._hierarchyFilter['hospital'] = {
          id: selection.id,
          display_text: selection.display_text,
        };
      }
    }
  }

  private _setWardFilter(selection: IHierarchy) {
    if (!!selection) {
      if (
        (!!this._hierarchyFilter['ward'] &&
          selection.id !== this._hierarchyFilter['ward'].id) ||
        (!this._hierarchyFilter['ward'] && !!selection.id)
      ) {
        this._hierarchyFilter['ward'] = {
          id: selection.id,
          display_text: selection.identifier,
        };

        //fetch the data after setting the filter
        this._loadPatientList();
        this._loadPatientMedicationData();
        this._loadmedicationTimeViewData();
      }
    }
  }

  _setSearchListnerForPatientMedication() {
    combineLatest([
      this._filterService.searchedValueObs,
      this.patientMedicationListObs,
    ])
      .pipe(
        map((combinedResult) => {
          const searchedText = combinedResult[0].toLowerCase().trim();
          const patientMedications = combinedResult[1];

          return patientMedications.filter((val: IPatient) =>
            (val.room + '-' + val.bed + ': ' + val.patient_name)
              .toLowerCase()
              .includes(searchedText)
          );
        })
      )
      .subscribe((data: any) => {
        this._patientMedicationFilteredList.next(data);
      });
  }

  _setSearchListnerForMedicationTimeView() {
    combineLatest([
      this._filterService.searchedValueObs,
      this.medicationTimeViewObs,
    ])
      .pipe(
        map((combinedResult) => {
          const searchedText = combinedResult[0].toLowerCase().trim();
          const medicationTimeView = combinedResult[1];

          return medicationTimeView.filter((val: IPatient) =>
            (val.room + '-' + val.bed + ': ' + val.patient_name)
              .toLowerCase()
              .includes(searchedText)
          );
        })
      )
      .subscribe((data: any) => {
        this._medicationTimeViewFilteredList.next(data);
      });
  }

  _setSearchListnerForPatientList() {
    combineLatest([this._filterService.searchedValueObs, this.patientListObs])
      .pipe(
        map((combinedResult) => {
          const searchedText = combinedResult[0].toLowerCase().trim();
          const assignedPatientList = combinedResult[1].assigned;
          const dischargedPatientList = combinedResult[1].discharged;
          const unassignedPatientList = combinedResult[1].unassigned;

          return {
            assigned: assignedPatientList
              ? assignedPatientList.filter((val: IPatient) =>
                  (val.room + '-' + val.bed + ': ' + val.patient_name)
                    .toLowerCase()
                    .includes(searchedText)
                )
              : [],
            discharged: dischargedPatientList
              ? dischargedPatientList.filter((val: IPatient) =>
                  val.patient_name.toLowerCase().includes(searchedText)
                )
              : [],
            unassigned: unassignedPatientList
              ? unassignedPatientList.filter((val: IPatient) =>
                  val.patient_name.toLowerCase().includes(searchedText)
                )
              : [],
          };
        })
      )
      .subscribe((data: any) => {
        this.assignedPatientCount = data.assigned.length;
        this.unassignedPatientCount = data.unassigned.length;
        this.dischargePatientCount = data.discharged.length;
        this._patientFilteredList.next(data);
      });
  }

  private _loadPatientList() {
    if (Object.keys(this._hierarchyFilter).length === 0) {
      return;
    }
    this._http
      .post(`/api/v1/patient/listPatients`, {
        type: 'all',
        filter: this._hierarchyFilter,
        sortBy: this._sortBy,
        listProps: this._patientFilteredListProps.getValue(),
      })
      .subscribe(
        (data: IResponse<any>) => {
          this._patientList.next(data.response.patients);
        },
        (err) => {
          console.log('some error occured.');
        }
      );
  }

  getPatientList(type: string, listProps?: ListProps): Observable<any> {
    if (Object.keys(this._hierarchyFilter).length === 0) {
      return;
    }
    var filter = _.cloneDeep(this._hierarchyFilter);

    if (type == 'unassigned' && this._hierarchyFilter.ward) {
      delete filter.ward;
    }

    return this._http
      .post('/api/v1/patient/listPatients', {
        type: type,
        filter: filter,
        listProps: listProps,
      })
      .pipe<any>(
        map<any, any>((response) => {
          if (type == 'unassigned') {
            this.unassignedPatientCount = response.response.patients.length;
          } else if (type == 'discharged') {
            this.dischargePatientCount = response.response.total_count;
          } else if (type == 'assigned') {
            this.assignedPatientCount = response.response.total_count;
          }

          return response.response;
        })
      );
  }

  private _loadPatientMedicationData() {
    if (Object.keys(this._hierarchyFilter).length === 0) {
      return;
    }
    this._http
      .post(`/api/v1/patient/listPatientMedications`, {
        filter: this._hierarchyFilter,
        sortBy: this._sortBy,
      })
      .subscribe(
        (data: IResponse<any>) => {
          this._patientMedicationList.next(data.response.patientMedications);
        },
        (err) => {
          console.log('some error occured.');
        }
      );
  }

  private _loadmedicationTimeViewData() {
    if (Object.keys(this._hierarchyFilter).length === 0) {
      return;
    }
    this._http
      .post(`/api/v1/patient/listPatientMedicationsLinear`, {
        filter: this._hierarchyFilter,
        sortBy: this._sortBy,
      })
      .subscribe(
        (data: IResponse<any>) => {
          this._medicationTimeView.next(data.response.patientMedications);
        },
        (err) => {
          console.log('some error occured.');
        }
      );
  }

  getPatientMedicationDetailById(patientId: string): Observable<IPatient> {
    return this._patientMedicationList.pipe<IPatient[], IPatient>(
      map((items) => items.filter((item) => item.id === patientId)),
      map((items) => {
        return items[0];
      })
    );
  }

  getPatientMedicationOrderDetailById(
    patientId: string,
    type: string,
    listProps?: ListProps
  ): Observable<any> {
    return this._http
      .post('/api/v1/drug/drugOrders', {
        patient_id: patientId,
        type: type,
        listProps: listProps,
      })
      .pipe<any>(map<any, any>((response) => response.response));
  }

  getPatientByAdmissionStatus(status: string) {
    return this._patientFilteredList.pipe(pipe(map((items) => items[status])));
  }

  getPatientByAdmissionStatusCount(status: string) {
    return this._patientFilteredList.pipe(
      pipe(map((items) => items[status + 'Count']))
    );
  }

  getPatientDetailById(body: any) {
    return this._http.post(`/api/v1/patient/patientDetail`, body).pipe(
      map((data: IResponse<any>) => {
        return data.response.patient;
      })
    );
  }

  passPatientDetailId(id: any) {
    localStorage.setItem('pt_det_k', id);
    this._patientDetailId.next(id);
  }

  savePatientDetail(body: any) {
    return this._http
      .post(`/api/v1/patient/savePatient`, { patient: body })
      .pipe(
        map((data: IResponse<any>) => {
          this._loadPatientList();
          return data;
        })
      );
  }

  //Put here due to circular dependency btw enterprise and patient service
  getWardChildrenWithStatus(item: {
    identifier: string;
    ent_type: string;
    multilevel: boolean;
  }): Observable<any[]> {
    const obs1 = this.patientListObs;
    const obs2 = this._enterpriseService.getWardHierarchy(item);

    return combineLatest<Observable<any>, Observable<any>>([obs1, obs2]).pipe(
      map((combinedResult) => {
        const patientList = combinedResult[0].assigned;
        let roomList = JSON.parse(JSON.stringify(combinedResult[1].item)); //Don't override the orignal data

        if (patientList)
          patientList.forEach((val) => {
            const room = val.room;
            const bed = val.bed;

            if (roomList)
              roomList.forEach((currRoom) => {
                currRoom.children.forEach((currBed) => {
                  if (
                    currRoom.display_text.toString() === room &&
                    currBed.display_text === bed
                  ) {
                    currBed['isOccupied'] = true;
                    currBed['patient'] = val;
                  } else if (currBed['isOccupied'] === undefined) {
                    currBed['isOccupied'] = false;
                    currBed['patient'] = {};
                  }
                });
              });
          });
        return roomList;
      })
    );
  }

  associatePatient(associationDetail: {
    device_id: string;
    patient_id: string;
    id?: string;
  }) {
    return this._http
      .post('/api/v1/alarm/associatePatient', associationDetail)
      .pipe(
        map<any, boolean>(
          (response) => {
            if (response.success) {
              this._loadPatientList();
              this._loadPatientMedicationData();
              this._loadmedicationTimeViewData();
              return true;
            }
            return false;
          },
          catchError((error, caught) => {
            return of(false);
          })
        )
      );
  }

  dischargePatient(patientId: string) {
    return this._http
      .post('/api/v1/patient/dischargePatient', { id: patientId })
      .pipe(
        map<any, boolean>(
          (response) => {
            if (response.success) {
              this._loadPatientList();
              this._loadPatientMedicationData();
              this._loadmedicationTimeViewData();
              return true;
            }
            return false;
          },
          catchError((error, caught) => {
            return EMPTY;
          })
        )
      );
  }

  // dischargePatientByDeviceID(id_list: any, patient_id,force_discharge) {
  //   return this._http.post(`/api/v1/patient/dissociatePatientBulk`, {
  //     id_list,
  //     patient_id,
  //     force_discharge
  //   });
  // }

  dischargePatientByDeviceID(id_list: any, patient_id, force_discharge) {
    console.log('checking id list  ', id_list);
    return this._http
      .post('/api/v1/patient/dissociatePatientBulk', {
        id_list,
        patient_id,
        force_discharge,
      })
      .pipe(
        map<any, boolean>(
          (response) => {
            if (response.success) {
              this._loadPatientList();
              this._loadPatientMedicationData();
              this._loadmedicationTimeViewData();
              return true;
            }
            return false;
          },
          catchError((error, caught) => {
            return EMPTY;
          })
        )
      );
  }

  assignPatient(data: any) {
    return this._http.post('/api/v1/patient/assignBedToPatient', data).pipe(
      map<any, boolean>(
        (response) => {
          if (response.success) {
            this._loadPatientList();
            this._loadPatientMedicationData();
            this._loadmedicationTimeViewData();
            return true;
          }
          return false;
        },
        catchError((error, caught) => {
          return EMPTY;
        })
      )
    );
  }

  unAssignPatient(data: any) {
    return this._http.post('/api/v1/patient/unAssignBedToPatient', data).pipe(
      map<any, boolean>(
        (response) => {
          if (response.success) {
            this._loadPatientList();
            this._loadPatientMedicationData();
            this._loadmedicationTimeViewData();
            return true;
          }
          return false;
        },
        catchError((error, caught) => {
          return EMPTY;
        })
      )
    );
  }

  private _setSortCriteriaListner() {
    this._filterService.sortByObs.subscribe((criteria) => {
      this._sortBy = criteria;

      this._loadPatientList();
      this._loadPatientMedicationData();
      this._loadmedicationTimeViewData();
    });
  }
}
