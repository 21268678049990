<div class="map_table" id="table_view_outer">
   <table mat-table [dataSource]="data" class="w_1200" aria-label="Patient Order data">
      <ng-container matColumnDef="room">
         <th mat-header-cell *matHeaderCellDef> {{'HOME.ward-component-Table_View-Room' | translate}} </th>
         <td mat-cell *matCellDef="let element"> {{element.room}} </td>
      </ng-container>
      <ng-container matColumnDef="type">
         <th mat-header-cell *matHeaderCellDef> {{'HOME.ward-component-Table_View-Type' | translate}} </th>
         <td mat-cell *matCellDef="let element"> {{element.type}} </td>
      </ng-container>
      <ng-container matColumnDef="patient">
         <th mat-header-cell *matHeaderCellDef> {{'HOME.ward-component-Table_View-Patient' | translate}} </th>
         <td mat-cell *matCellDef="let element"> {{element.patient}} </td>
      </ng-container>
      <ng-container matColumnDef="class">
         <th mat-header-cell *matHeaderCellDef> {{'HOME.ward-component-Table_View-Class' | translate}} </th>
         <td mat-cell *matCellDef="let element"> {{element.type_class}} </td>
      </ng-container>
      <ng-container matColumnDef="medication">
         <th mat-header-cell *matHeaderCellDef> {{'HOME.ward-component-Table_View-Medication' | translate}} </th>
         <td mat-cell *matCellDef="let element"> {{element.medication}} </td>
      </ng-container>
      <ng-container matColumnDef="infused">
         <th mat-header-cell *matHeaderCellDef> {{'HOME.ward-component-Table_View-%Remaining' | translate}} </th>
         <td mat-cell *matCellDef="let element" class="text-end"> {{element.infused}}</td>
         <td mat-cell *matCellDef="let element" class=""> {{element.percentage_infused}}</td>
      </ng-container>
      <ng-container matColumnDef="remaining">
         <th mat-header-cell *matHeaderCellDef> {{'HOME.ward-component-Table_View-Remaining' | translate}} </th>
         <td mat-cell *matCellDef="let element">
            <mat-progress-bar mode="determinate" [value]='element.percentage_infused'></mat-progress-bar>
            <div class="bar_value">
               {{element.time_remaining}}
            </div>
         </td>
      </ng-container>
      <ng-container matColumnDef="rate">
         <th mat-header-cell *matHeaderCellDef> {{'HOME.ward-component-Table_View-Rate' | translate}} </th>
         <td mat-cell *matCellDef="let element" class="text-end"> {{element.rate}} </td>
      </ng-container>
      <ng-container matColumnDef="vtbi">
         <th mat-header-cell *matHeaderCellDef> {{'HOME.ward-component-Table_View-VTBI' | translate}} </th>
         <td mat-cell *matCellDef="let element" class="text-end"> {{element.volume_tbi}} </td>
      </ng-container>
      <ng-container matColumnDef="vi">
         <th mat-header-cell *matHeaderCellDef> {{'HOME.ward-component-Table_View-VI' | translate}} </th>
         <td mat-cell *matCellDef="let element"> {{element.volume_delivered}} </td>
      </ng-container>
      <ng-container matColumnDef="pump">
         <th mat-header-cell *matHeaderCellDef> {{'HOME.ward-component-Table_View-Pump' | translate}} </th>
         <td mat-cell *matCellDef="let element">
            <div class="table_icon_div">
               <img id="report_icon" src="../../../../../assets/img/chart_svg.svg" class="table_icon" alt="Chart"> {{element.pump}}
               <img id="alert_icon" src="../../../../../assets/img/alert.svg" class="table_icon" alt="Alert"
                  [routerLink]="'/ward/patient/1/pump-detail/' + 1">
            </div>
         </td>
      </ng-container>
      <ng-container matColumnDef="message">
         <th mat-header-cell *matHeaderCellDef> {{'HOME.ward-component-Table_View-Message' | translate}} </th>
         <td mat-cell *matCellDef="let element"> {{element.message}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="getRowClassString()"></tr>
   </table>
</div>