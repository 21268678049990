import {
  Component,
  OnInit,
  ElementRef,
  Input,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-pump-line-chart',
  templateUrl: './pump-line-chart.component.html',
  styleUrls: ['./pump-line-chart.component.css'],
})
export class PumpLineChartComponent implements OnInit {
  @Input() height: number;
  @Input() width: number;

  @Input() data: any[] = [];
  @Input() yLabel: string;
  @Input() yAxisLabel: string;
  dataNotAvailable: boolean = false;
  constructor(
    private container: ElementRef,
    private _langService: TranslateService
  ) {
    this._langService
      .get([
        'HOME.ward-component-Event_Feed-Time'
      ])
      .subscribe((ln) => {
        this.Ward_Component_Event_Feed_Time =
          ln['HOME.ward-component-Event_Feed-Time'];
      });
  }

  private Ward_Component_Event_Feed_Time;

  ngOnInit(): void {
    // This is intentionally empty
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        if (propName == 'data') {
          if (this.data.length > 0) {
            this.dataNotAvailable = false;
            this.createGraph();
          } else if (!changes[propName].isFirstChange()) {
            this.dataNotAvailable = true;
          }
        }
      }
    }
  }

  createGraph() {
    const margin = { top: 10, right: 10, bottom: 80, left: 80 };
    const width = this.width - margin.left - margin.right;
    const height = this.height - margin.top - margin.bottom;

    let timeData = d3.map(this.data, (d) => {
      return d.time.split(' ')[1];
    });

    // X scale will use the index of our data
    let xScale = d3
      .scaleBand()
      .domain(timeData)
      .range([0, width - margin.left - margin.right]); // output

    // Y scale will use the randomly generate number
    let yScale = d3
      .scaleLinear()
      .domain([
        0,
        d3.max(this.data, (d) => {
          if (this.yLabel === 'volume_tbi-volume_delivered') {
            return d.volume_tbi - d.volume_delivered;
          } else if (this.yLabel === 'event_desc') {
            return parseInt(d[this.yLabel].split(' ')[0]);
          } else {
            return d[this.yLabel];
          }
        }),
      ]) // input
      .range([height - margin.top - margin.bottom, 0]); // output

    // 7. d3's line generator
    let line = d3
      .line()
      .x(function (d, i) {
        return xScale(d.x);
      })
      .y(function (d) {
        return yScale(d.y);
      })
      .curve(d3.curveMonotoneX); // apply smoothing to the line

    const dataset = this.data.map((d) => {
      let time = d.time.split(' ')[1];
      let y;
      if (this.yLabel === 'volume_tbi-volume_delivered') {
        y = d.volume_tbi - d.volume_delivered;
      } else if (this.yLabel === 'event_desc') {
        y = parseInt(d[this.yLabel].split(' ')[0]);
      } else {
        y = d[this.yLabel];
      }
      return { y, x: time };
    });

    let svg = d3
      .select(this.container.nativeElement)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .attr('viewBox', [0, 0, width, height])
      .attr('style', 'max-width: 100%; height: 100%; height: intrinsic;')
      .style('font', '.8em sans-serif')

      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    // Call the x axis in a group tag
    svg
      .append('g')
      .attr('class', 'x axis')
      .attr(
        'transform',
        `translate(0, ${height - margin.top - margin.bottom} )`
      )
      .call(
        d3.axisBottom(xScale).tickValues(
          xScale.domain().filter(function (d, i) {
            let mod = Math.ceil(timeData.length / 15);
            return !(i % mod);
          })
        )
      )
      .selectAll('text')
      .style('text-anchor', 'end')
      .attr('dx', '-.5em')
      .attr('dy', '-.55em')
      .style('font-size', '1.5em')
      .attr('transform', 'rotate(-65)');
    // Create an axis component with d3.axisBottom

    // Call the y axis in a group tag
    svg
      .append('g')
      .attr('class', 'y axis')
      .call(d3.axisLeft(yScale).ticks([5]))
      .selectAll('text')

      .style('font-size', '1.5em'); // Create an axis component with d3.axisLeft

    svg
      .append('path')
      .datum(dataset)
      .attr('fill', 'none')
      .attr('stroke', '#ffffff')
      .attr('stroke-width', 1)
      .attr('d', line);

    svg
      .append('g')
      .append('text')
      .attr('x', width / 2 - margin.left - margin.right)
      .attr('y', height - margin.bottom + margin.bottom / 2 + 20)
      .style('fill', '#ffffff')
      .style('font-size', '1.5em')

      .text(this.Ward_Component_Event_Feed_Time);

    svg
      .append('g')
      .append('text')
      .style('fill', '#ffffff')
      .attr('text-anchor', 'middle')
      .style('font-size', '1.5em')
      .attr(
        'transform',
        `translate(${-margin.left / 2},${height / 2}) rotate(270)`
      )
      .text(`${this.yAxisLabel}`);
  }
}
