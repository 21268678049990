<div class="popup_box" id="patient_dialog">
  <mat-dialog-content>
    <h2 id="close_dialog_btn" mat-dialog-title>{{'HOME.ward-component-Patient_Association-LinkPatient' | translate}} <mat-icon
        class="close_icon" [mat-dialog-close]="true">close</mat-icon>
    </h2>
    <div class="dialog_row mb-d-inline">

      <div class="patient_select">
        <div class="link_inner">
          <label>{{'HOME.ward-component-Patient_Association-Patient' | translate}}</label>
          <div class="dialog_mat_select">
            <mat-form-field appearance="outline">
              <input id="patient_select" type="text" placeholder="{{'GLOBAL.Choose_an_option' | translate}}" matInput [formControl]="patientFormControl"
                [matAutocomplete]="patientInpAuto">
              <mat-autocomplete #patientInpAuto="matAutocomplete" [displayWith]="displayFn1"
                (optionSelected)="onPatientSelected($event)">
                <mat-option [id]="'link_patient_' + i" *ngFor="let patient of filteredPatientOptions | async; let i = index" [value]="patient">
                  {{patient.patient_name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="patient_detail" *ngIf="!!selectedPatient">
        <p>{{selectedPatient?.patient_name}}</p>
        <p>{{'HOME.ward-component-Patient_Association-Room' | translate}} {{selectedPatient?.room + '-' +
          selectedPatient?.bed}}</p>
        <p>{{'HOME.patient-component-Discharge_Patient-AdmitDate' | translate}} {{selectedPatient?.admit_date}}</p>
      </div>

    </div>

    <div class="dialog_row mb-d-inline">

      <div class="patient_select">
        <div class="link_inner">
          <label>{{'HOME.ward-component-Patient_Association-Device' | translate}}</label>
          <div class="dialog_mat_select">
            <mat-form-field appearance="outline">
              <input id="device_select" type="text" placeholder="{{'GLOBAL.Choose_an_option' | translate}}" matInput [formControl]="deviceFormControl"
                [matAutocomplete]="deviceInpAuto">
              <mat-autocomplete #deviceInpAuto="matAutocomplete" [displayWith]="displayFn2"
                (optionSelected)="onDeviceSelected($event)">
                <mat-option [id]="'select_device_' + i" *ngFor="let device of filteredDeviceOptions | async; let i = index" [value]="device">
                  {{device.display_text}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="patient_detail" *ngIf="!!selectedDevice">
        <p>{{selectedDevice.display_text}}</p>
        <p>{{selectedDevice.model}}{{"   "}}{{selectedDevice.type}}</p>
      </div>

    </div>


    <!-- Start changes by KI (optionSelected)="onDeviceSelected($event)"-->
    <div class="dialog_row mb-d-inline">

      <div class="patient_select">
        <div class="link_inner">
          <label>{{'HOME.ward-component-Patient_Association-DrugSrc' | translate}}</label>
          <div class="dialog_mat_select">
            <mat-form-field appearance="outline">
              <input id="medication_select" type="text"
              [value]="selectedDrugSrc"
              placeholder="{{'GLOBAL.Choose_an_option' | translate}}" matInput
                [matAutocomplete]="DrugSrcInpAuto">
              <mat-autocomplete #DrugSrcInpAuto="matAutocomplete" [displayWith]="displayFn3"
                (optionSelected)="onMediactionDrugSelected($event)">
                <mat-option *ngFor="let medication of medicatiopnTypeValues" [value]="medication">
                  {{medication.display_text}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
      </div>

      <!-- <div class="patient_select">
        <div class="link_inner">
          <label>{{'HOME.ward-component-Patient_Association-DrugSrc' | translate}}</label>
          <div class="dialog_mat_select">
            <mat-form-field appearance="outline">
              <input 
              [value]="selectedDrugSrc"
              (optionSelected)="onMediactionDrugSelected($event)"
              type="text" placeholder="{{'GLOBAL.Choose_an_option' | translate}}" matInput [formControl]="DrugTypeFormControl"
                [matAutocomplete]="DrugSrcAuto">
              <mat-autocomplete #DrugSrcAuto="matAutocomplete" [displayWith]="displayFn4"
                                >
                <mat-option *ngFor="let medicationtype of medicatiopnTypeValues" [value]="medicationtype">
                  {{medicationtype.ref_type_display_text}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
      </div> -->

    </div>
    <!-- end changes by KI -->

    <div  *ngIf="selectedDrugSrc!='' && selectedDrugSrc=='Prescription'" class="dialog_row mb-d-inline">

      <div class="patient_select">
        <div class="link_inner">
          <label>{{'HOME.ward-component-Patient_Association-Medication' | translate}}Prescription</label>
          <div class="dialog_mat_select">
            <mat-form-field appearance="outline">
              <input id="prescription_select" type="text"
              [value]="selectedMedication.prescription_number"
              placeholder="{{'GLOBAL.Choose_an_option' | translate}}" matInput [formControl]="medicationFormControl"
                [matAutocomplete]="medicInpAuto">
              <mat-autocomplete #medicInpAuto="matAutocomplete" [displayWith]="displayFn3"
                (optionSelected)="onmedicationSelected($event)">
                <mat-option *ngFor="let medication of medicationValues" [value]="medication">
                  {{medication.prescription_number}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="patient_detail" *ngIf="!!selectedMedication">
        <p>{{selectedMedication.drug_code}}</p>
        <p>{{selectedMedication.drug_displaytext}}</p>
        <p>{{selectedMedication.prescription_number}}</p>
      </div>

    </div>

    <div *ngIf="selectedDrugSrc!='' && selectedDrugSrc =='Drug Library'"   class="dialog_row mb-d-inline">

      <div  class="patient_select">
        <div class="link_inner">
          <label>{{'HOME.ward-component-Patient_Association-Medication' | translate}}</label>
          <div class="dialog_mat_select">
            <mat-form-field appearance="outline">
              <input id="medication_select" type="text" placeholder="{{'GLOBAL.Choose_an_option' | translate}}" matInput [formControl]="medicationFormControl"
                [matAutocomplete]="medicInpAuto">
              <mat-autocomplete #medicInpAuto="matAutocomplete" [displayWith]="displayFn3"
                (optionSelected)="onmedicationSelected($event)">
                <mat-option [id]="'select_medication_' + i" *ngFor="let medication of filteredMedicationOptions | async; let i = index" [value]="medication">
                  {{medication.medication}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="patient_detail" *ngIf="!!selectedMedication">
        <p>{{selectedMedication.medication}}</p>
      </div>

    </div>

    <div class="dialog_row">
      <a id="save_patient_association" class="custom_btn" [mat-dialog-close]="true" (click)="savePatientAssociation()">{{'HOME.ward-component-Patient_Association-Associate' |
        translate}}</a>
    </div>

  </mat-dialog-content>
</div>
