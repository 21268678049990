import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  constructor() {
    // This is intentionally empty
  }

  private _searchedValue = new BehaviorSubject<string>('');
  private _sortBy = new BehaviorSubject<string>('');

  readonly searchedValueObs = this._searchedValue.asObservable();
  readonly sortByObs = this._sortBy.asObservable();

  updateSearchString(value: string) {
    this._searchedValue.next(value);
  }

  updateSortBy(value: string) {
    this._sortBy.next(value);
  }
}
