import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaseLayoutComponent } from './component/base-layout/base-layout.component';
import { EventComponent } from './pages/event/event.component';
import { GeneralReportComponent } from './pages/general-report/general-report.component';
import { PatientDetailViewComponent } from './pages/patient-detail-view/patient-detail-view.component';
import { PcaReportComponent } from './pages/pca-report/pca-report.component';
import { PumpDetailViewComponent } from './pages/pump-detail-view/pump-detail-view.component';
import { TciReportComponent } from './pages/tci-report/tci-report.component';

const routes: Routes = [
  {
    path: '',
    component: BaseLayoutComponent,
  },
  {
    path: 'patient/pump-detail',
    component: PumpDetailViewComponent,
  },
  {
    path: 'patient/general-report',
    component: GeneralReportComponent,
  },
  {
    path: 'patient/tci-report',
    component: TciReportComponent,
  },
  {
    path: 'patient/pca-report',
    component: PcaReportComponent,
  },
  {
    path: 'patient-detail',
    component: PatientDetailViewComponent,
  },
  {
    path: 'event',
    component: EventComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class WardRoutingModule {}
