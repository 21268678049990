import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {
    // This is intentionally empty
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const authToken = this.authService.getAuthToken();
    // to manipulate a request, first clone it. otherwise runs into error
    let headers: HttpHeaders;
    if (authToken) {
    headers = new HttpHeaders({
      'Authorization': `Bearer ${authToken}`
    });
  }
    const authRequest = request.clone({headers});
    return next.handle(authRequest);
  }
}
