import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

const USER_ACTIVITY_TIMEOUT = 0; //in sec

@Injectable({
  providedIn: 'root',
})
export class UserActivityService {
  constructor() {
    // This is intentionally empty
  }

  private _timeoutId;
  private _isUserInactive: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  isUserInactive = this._isUserInactive.asObservable();

  setActivityWatch() {
    if (this._timeoutId) {
      this._clearActivityWatch();
    }
    this._timeoutId = setTimeout(() => {
      this._isUserInactive.next(true);
    }, USER_ACTIVITY_TIMEOUT * 1000);
  }

  private _clearActivityWatch() {
    clearTimeout(this._timeoutId);
    this._isUserInactive.next(false);
    this._timeoutId = undefined;
  }
}
