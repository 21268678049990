import { Directive, ElementRef, HostListener } from '@angular/core';
import { UtilService } from 'src/app/core/services/util.service';

declare const bootstrap: any;

@Directive({
  selector: '[appToolTip]',
})
export class ToolTipDirective {
  private elementRef: ElementRef<any>;
  private toolTip: any;
  private isLocked: boolean = false;
  constructor(el: ElementRef, private _utilService: UtilService) {
    this.elementRef = el;
    this._utilService.isLocked.subscribe((data) => {
      this.isLocked = data;
    });
  }

  hideTooltip(): void {
    if (!!this.toolTip) {
      this.toolTip.dispose();
    }
  }

  @HostListener('mouseover') onMouseEnter() {
    if (!this.isLocked) {
      const tooltip = new bootstrap.Tooltip(this.elementRef.nativeElement, {
        trigger: 'hover focus click',
      });
      tooltip.show();

      this._utilService.updateActiveTooltipRef(tooltip);
      this.toolTip = tooltip;

      this.elementRef.nativeElement.addEventListener('mouseleave', () => {
        tooltip.hide();
        this._utilService.clearActiveTooltip();
      });
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    //empty
  }
}
