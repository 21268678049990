import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Selected_Device_For_Pump_Detail_Page_Key } from '../remaining-time-view/remaining-time-view.component';
import { Selected_Patient_For_Detail_Page_Key } from '../room-card-view/room-card-view.component';

export enum PumpType {
  INFUSION = 'Infusion Pumps',
  TCI = 'TCI',
  PCA = 'PCA',
}

export const PatientSelectedForReportView = 'rpt_pt_slt_dt';

@Component({
  selector: 'app-patient-medication-table-view',
  templateUrl: './patient-medication-table-view.component.html',
  styleUrls: ['./patient-medication-table-view.component.css'],
})
export class PatientMedicationTableViewComponent
  implements OnInit, AfterViewInit
{
  constructor(private _router: Router) {
    // This is intentionally empty
  }

  @Input() set data(value: any[]) {
    this.dataSource = new MatTableDataSource<any[]>(value);
    if (!!this.sort){
      this.sort.sort({ id: null, start: 'desc', disableClear: false });
    }
    this.dataSource.sort = this.sort;
    this.dataSource.sortData = (data: any[], sort: MatSort) => {
      return data.sort((a, b) => {
        if (sort.direction === 'asc') {
          return this.sortASC(a, b, sort.active);
        }

        return this.sortDESC(a, b, sort.active);
      });
    };
  }
  dataSource: MatTableDataSource<any[]>;
  @ViewChild(MatSort) sort: MatSort;

  ngOnInit(): void {
    console.log(this.data);
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  displayedColumns: string[] = [
    'pump',
    'drug_type',
    'drug_class',
    'medication',
    'concentration',
    'infused',
    'remaining',
    'rate',
    'vtbi',
    'vi',
    'message',
  ];

  openPumpDetailView() {
    // This is intentionally empty
  }

  getRowClassString(alarm: string, stale_msg: string) {
    if (alarm) {
      return 'bg_red_row';
    } else if (stale_msg) {
      return 'bg_yellow_row';
    } else {
      return '';
    }
  }

  openReportPage(medication:any) {
    const path = this._getReportPath(medication.pump);
    const patientReportInput = {
      deviceId: medication.device_id,
      medicationId: medication.medication_id,
      patientId: localStorage.getItem(Selected_Patient_For_Detail_Page_Key),
    };
    localStorage.setItem(
      PatientSelectedForReportView,
      JSON.stringify(patientReportInput)
    );
    this._router.navigate([path]);
  }

  _getReportPath(pumpType: string): string {
    let path = '';

    if (pumpType.includes(PumpType.TCI)) {
      path = '/ward/patient/tci-report';
    } else if (pumpType.includes(PumpType.PCA)) {
      path = '/ward/patient/pca-report';
    } else {
      path = '/ward/patient/general-report';
    }

    return path;
  }

  sortASC(a: any, b: any, key: string) {
    if (!!a.alarm && !b.alarm) {
      return -1;
    }

    if (!a.alarm && !!b.alarm) {
      return 1;
    }

    if (!!a.stale_msg && !b.stale_msg) {
      return -1;
    }

    if (!a.stale_msg && !!b.stale_msg) {
      return 1;
    }

    return this.sortStringASC(a[key], b[key]);
  }

  sortDESC(a: any, b: any, key: string) {
    if (!!a.alarm && !b.alarm) {
      return -1;
    }

    if (!a.alarm && !!b.alarm) {
      return 1;
    }

    if (!!a.stale_msg && !b.stale_msg) {
      return -1;
    }

    if (!a.stale_msg && !!b.stale_msg) {
      return 1;
    }

    return this.sortStringDESC(a[key], b[key]);
  }

  sortStringASC(a: string, b: string) {
    if (a > b) {
      return -1;
    }
    if (a < b) {
      return 1;
    }
    return 0;
  }

  sortStringDESC(a: string, b: string) {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  }

  openDetail(item:any) {
    item.id = localStorage.getItem(Selected_Patient_For_Detail_Page_Key);

    localStorage.setItem(
      Selected_Device_For_Pump_Detail_Page_Key,
      JSON.stringify(item)
    );

    const path = '/ward/patient/pump-detail/';
    this._router.navigate([path]);
  }
}
