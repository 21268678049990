<div class="current-header mb-2">
   <div class="search_table w-50 mob_w_100">
      <div class="input-group">
         <span class="input-group-text" id="basic-addon1">
            <img src="../../../../../assets/img/search_icon.png" class="table_icon" alt="Search" /> </span>
         <input id="history_search_input" type="text" class="form-control" placeholder="{{'GLOBAL.Search_Placeholder' | translate}}"
            [formControl]="searchControl">
      </div>
   </div>

</div>

<div class="map_table tab_view_table">
   <table mat-table [dataSource]="dataSource" matSort aria-label="Patient Order History data"
      (matSortChange)="onSort($event)" id="patient_history_data_table">
      <ng-container matColumnDef="drug_type">
         <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'HOME.patient-component-History_Tab_View-Type' |
            translate}} </th>
         <td mat-cell *matCellDef="let element"> {{element.drug_type}} </td>
      </ng-container>

      <ng-container matColumnDef="drug_name">
         <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'HOME.patient-component-History_Tab_View-Medication' |
            translate}}
         </th>
         <td mat-cell *matCellDef="let element"> {{element.drug_name}} </td>
      </ng-container>

      <ng-container matColumnDef="total_volume">
         <th mat-header-cell *matHeaderCellDef> {{'HOME.patient-component-History_Tab_View-TotalVolume' | translate}}
         </th>
         <td mat-cell *matCellDef="let element" class="text-end">
            {{element.total_volume}}
         </td>
      </ng-container>
      <ng-container matColumnDef="dose_rate">
         <th mat-header-cell *matHeaderCellDef> {{'HOME.patient-component-History_Tab_View-Rate' | translate}} </th>
         <td mat-cell *matCellDef="let element" class="text-end"> {{element.dose_rate}} </td>
      </ng-container>
      <ng-container matColumnDef="volume_infused">
         <th mat-header-cell *matHeaderCellDef> {{'HOME.patient-component-History_Tab_View-VI' | translate}} </th>
         <td mat-cell *matCellDef="let element" class="text-end"> {{element.volume_infused}} </td>
      </ng-container>

      <ng-container matColumnDef="time">
         <th mat-header-cell *matHeaderCellDef> {{'HOME.patient-component-Order_Tab_View-Time' | translate}} </th>
         <td mat-cell *matCellDef="let element" class="text-end"> {{element.time}} </td>
      </ng-container>

      <ng-container matColumnDef="pump">
         <th mat-header-cell *matHeaderCellDef> {{'HOME.patient-component-History_Tab_View-Pump' | translate}} </th>
         <td mat-cell *matCellDef="let element" class="min-wdt_200">
            <div *ngIf="!!element.pump" class="table_icon_div">
               <img id="report_detai_form" src="../../../../../assets/img/chart_svg.svg" class="table_icon me-1" alt="Chart"
                  (click)="openReportPage(element)"> {{element.pump}}
               <img id="open_form" src="../../../../../assets/img/alert.svg" class="table_icon ms-1" alt="Alert"
                  (click)="openDetail(element)">
            </div>
         </td>
      </ng-container>
      <ng-container matColumnDef="order_Date">
         <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'HOME.patient-component-History_Tab_View-Date' |
            translate}} </th>
         <td mat-cell *matCellDef="let element"> {{element.order_Date}} - {{element.admin_date}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
   </table>
</div>
<div class="custom_pagination">
   <mat-paginator [length]="totalCount" [pageSizeOptions]="[20, 30, 50, 100]" aria-label="Select page"
      (page)="onPageChange($event)">
   </mat-paginator>
</div>