import { Component, OnInit } from '@angular/core';

const ELEMENT_DATA: any[] = [
  {
    type: 'High',
    class: 'Cardiac',
    medication: 'Midazolam',
    infused:'5%',
    remaining: '120m',
    rate: '2mg',
    vtbi: '50ml',
    vi:'3ml',
    pump: 'Syringe',
    message: 'Alarm: Air in Line'
  },
  {
    type: 'High',
    class: 'Cardiac',
    medication: 'Midazolam',
    infused:'5%',
    remaining: '120m',
    rate: '2mg',
    vtbi: '50ml',
    vi:'3ml',
    pump: 'Syringe',
    message: 'Alarm: Air in Line'
  },
  {
    type: 'High',
    class: 'Cardiac',
    medication: 'Midazolam',
    infused:'5%',
    remaining: '120m',
    rate: '2mg',
    vtbi: '50ml',
    vi:'3ml',
    pump: 'Syringe',
    message: 'Alarm: Air in Line'
  },
  {
    type: 'High',
    class: 'Cardiac',
    medication: 'Midazolam',
    infused:'5%',
    remaining: '120m',
    rate: '2mg',
    vtbi: '50ml',
    vi:'3ml',
    pump: 'Syringe',
    message: 'Alarm: Air in Line'
  },

];


@Component({
  selector: 'app-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.css']
})
export class NotFoundPageComponent implements OnInit {

  constructor() {
    // This is intentionally empty
  }

  ngOnInit(): void {
    // This is intentionally empty
  }

  
  displayedColumns: string[] = [
    'type',
    'class',
    'medication',
    'infused',
    'remaining',
    'rate',
    'vtbi',
    'vi',
    'pump',
    'message'

  ];
  dataSource = ELEMENT_DATA;
}
