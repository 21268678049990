import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IHierarchy } from 'src/app/core/models/Hierarchy';
import { TooltipService } from 'src/app/core/services/tooltip.service';
declare const $: any;
@Component({
  selector: 'app-map-card',
  templateUrl: './map-card.component.html',
  styleUrls: ['./map-card.component.css'],
})
export class MapCardComponent implements OnInit {
  constructor(private _tooltipService: TooltipService) {
    // This is intentionally empty
  }

  @Input() roomHierarchy: IHierarchy;
  selctedBed: any = {};
  @Output() bedSelect = new EventEmitter<any>();

  ngOnInit(): void {
    // This is intentionally empty
  }

  getBoxClassString(isOccupied: boolean, isAlarm?: string, isDelay?: string) {
    if (isAlarm) {
      return 'room_red';
    }

    if (isDelay) {
      return 'room_yellow';
    }

    if (isOccupied) {
      return 'room_green';
    }

    return 'room_grey';
  }

  selectBed(bed: any, room: any) {
    this.selctedBed = bed;
    this.selctedBed['room'] = room;
    this.bedSelect.emit(this.selctedBed);
  }

  onHover(e: MouseEvent, bed: any) {
    const currentTarget = e.currentTarget as HTMLElement;
    if (currentTarget) {
      this._tooltipService.open(currentTarget, bed, { onMapCard: true });
    }
  }

  onHoverOut() {
    this._tooltipService.close();
  }
}
