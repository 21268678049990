<div class="current-header mb-2">
   <div class="filter_div d-flex align-items-center">
      <div class="filter_div me-2 dialog_mat_select">
         <label>{{'HOME.patient-component-Current_Tab_View-Room' | translate}}: {{patientDetail.room}}, </label>
      </div>
      <div class="filter_div dialog_mat_select">
         <label>{{'HOME.patient-component-Current_Tab_View-Bed' | translate}}: {{patientDetail.bed}}</label>
      </div>
   </div>
</div>

<div class="map_table tab_view_table">
   <table mat-table [dataSource]="dataSource" matSort aria-label="Patient Order data" id="patient_order_table"> 
      <ng-container matColumnDef="drug_type">
         <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'HOME.patient-component-Current_Tab_View-Type' | translate}} </th>
         <td mat-cell *matCellDef="let element"> {{element.drug_type}} </td>
      </ng-container>

      <ng-container matColumnDef="drug_name">
         <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'HOME.patient-component-Current_Tab_View-Medication' | translate}}
         </th>
         <td mat-cell *matCellDef="let element"> {{element.drug_name}} </td>
      </ng-container>

      <ng-container matColumnDef="percentage_infused">
         <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'HOME.patient-component-Current_Tab_View-%Remaining' | translate}}
         </th>
         <td mat-cell *matCellDef="let element" class="text-end"> {{element.percentage_infused}} </td>
      </ng-container>

      <ng-container matColumnDef="time_remaining">
         <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'HOME.patient-component-Current_Tab_View-Remaining' | translate}}
         </th>
         <td mat-cell *matCellDef="let element">
            <mat-progress-bar mode="determinate" [value]='element.percentage_infused'></mat-progress-bar>
            <div class="bar_value">
               {{element.time_remaining}}
            </div>
         </td>
      </ng-container>
      <ng-container matColumnDef="dose_rate">
         <th mat-header-cell *matHeaderCellDef> {{'HOME.patient-component-Current_Tab_View-Rate' | translate}} </th>
         <td mat-cell *matCellDef="let element" class="text-end"> {{element.dose_rate}} </td>
      </ng-container>
      <ng-container matColumnDef="volume_tbi">
         <th mat-header-cell *matHeaderCellDef> {{'HOME.patient-component-Current_Tab_View-VTBI' | translate}} </th>
         <td mat-cell *matCellDef="let element" class="text-end"> {{element.volume_tbi}} </td>
      </ng-container>
      <ng-container matColumnDef="volume_infused">
         <th mat-header-cell *matHeaderCellDef> {{'HOME.patient-component-Current_Tab_View-VI' | translate}} </th>
         <td mat-cell *matCellDef="let element" class="text-end"> {{element.volume_infused}} </td>
      </ng-container>
      <ng-container matColumnDef="pump">
         <th mat-header-cell *matHeaderCellDef> {{'HOME.patient-component-Current_Tab_View-Pump' | translate}} </th>
         <td mat-cell *matCellDef="let element" class="min-wdt_200">
            <div *ngIf="!!element.pump" class="table_icon_div">
              <img id="report_detail_btn" src="../../../../../assets/img/chart_svg.svg" class="table_icon me-1" alt="Chart"
              (click)="openReportPage(element)"> {{element.pump}}
           <img id="pump_detail_btn" src="../../../../../assets/img/alert.svg" class="table_icon ms-1" alt="Alert"
              (click)="openDetail(element)">
            </div>
         </td>
      </ng-container>
      <ng-container matColumnDef="message">
         <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'HOME.patient-component-Current_Tab_View-Message' | translate}} </th>
         <td mat-cell *matCellDef="let element"> {{element.message}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
   </table>
</div>
