<div class="main_header">
  <div class="menubar">
    <mat-toolbar class="bg_blue_grey">
      <mat-toolbar-row>
        <button
          id="menu_bar_btn"
          mat-icon-button
          (click)="sidenav.toggle()"
          fxShow="true"
          fxHide.gt-sm
        >
          <mat-icon>menu</mat-icon>
        </button>
        <span class="logo" id="logo_text"> {{'HOME.core-layout-header-ivCare' | translate}} </span>

        <div class="nav_links" fxShow="true" fxHide.lt-md>
          <!-- The following menu items will be hidden on both SM and XS screen sizes -->
          <a id="Ward" routerLink="/ward" routerLinkActive="active_link" mat-button>
            <img
              src="../../../../assets/img/ward_icon.svg"
              class="header_icon"
              alt="Ward"
            />
            {{'HOME.core-layout-header-Wards' | translate}}</a
          >
          <a id="Alert" routerLink="/alert" routerLinkActive="active_link" mat-button>
            <img
              src="../../../../assets/img/alert_top.svg"
              class="header_icon"
              alt="Alert"
            />
            {{'HOME.core-layout-header-Alerts' | translate}}
          </a>
          <a id="Medication" routerLink="/medication" routerLinkActive="active_link" mat-button>
            <img
              src="../../../../assets/img/medicine_icon.svg"
              class="header_icon"
              alt="Medication"
            />
            {{'HOME.core-layout-header-Medications' | translate}}</a
          >
          <a id="Patient" routerLink="/patient" routerLinkActive="active_link" mat-button>
            <img src="../../../../assets/img/patient.svg" class="header_icon" alt="Patient" />
            {{'HOME.core-layout-header-Patients' | translate}}</a
          >
        </div>
        <div class="search_bar">
          <input
            id="header_search"
            type="text"
            [(ngModel)]="search_value"
            (keyup)="onSearchChange()"
          />
          <mat-icon (click)="search()" id="search_icon">search</mat-icon>
        </div>
        <div class="menu_icon">
          <img
            [matMenuTriggerFor]="belowMenu"
            src="../../../../assets/img/bars.svg"
            class="header_icon me-0 cursor-pointer"
            alt="Menu"
            id="menu_bar_right"
          />
          <mat-menu
            #belowMenu="matMenu"
            yPosition="below"
            class="bg-black mt-3"
          >
            <button id="profile_btn" mat-menu-item routerLink="setting/profile">
              <img src="../../../../assets/img/user.svg" class="svg_icon" alt="Profile" />
              {{'HOME.core-layout-header-Profile' | translate}}
            </button>
            <button id="setting_btn" mat-menu-item routerLink="setting">
              <img src="../../../../assets/img/setting.svg" class="svg_icon" alt="Setting" />
              {{'HOME.core-layout-header-Settings' | translate}}
            </button>
            <button id="logout_btn" mat-menu-item (click)="authService.logout()">
              <img
                src="../../../../assets/img/logout.svg"
                class="svg_icon"
                alt="Logout"
              /> {{'HOME.core-layout-header-Logout' | translate}}
            </button>
          </mat-menu>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>

    <mat-sidenav-container fxFlexFill class="responsive_menu">
      <mat-sidenav #sidenav>
        <mat-nav-list>
          <a
            id="ward_phone"
            (click)="sidenav.toggle()"
            routerLink="/ward"
            routerLinkActive="active_link"
            mat-list-item
          >
            <img
              src="../../../../assets/img/ward_icon.svg"
              class="header_icon"
              alt="Ward"
            />
            {{'HOME.core-layout-header-Wards' | translate}}</a
          >
          <a
            id="alert_phone"
            (click)="sidenav.toggle()"
            routerLink="/alert"
            routerLinkActive="active_link"
            mat-list-item
          >
            <img
              src="../../../../assets/img/alert_top.svg"
              class="header_icon"
              alt="Alert"
            />
            {{'HOME.core-layout-header-Alerts' | translate}}
          </a>
          <a
            id="medication_phone"
            (click)="sidenav.toggle()"
            routerLink="/medication"
            routerLinkActive="active_link"
            mat-list-item
          >
            <img
              src="../../../../assets/img/medicine_icon.svg"
              class="header_icon"
              alt="Medicine"
            />
            {{'HOME.core-layout-header-Medications' | translate}}</a
          >
          <a
            id="patient_phone"
            (click)="sidenav.toggle()"
            routerLink="/patient"
            routerLinkActive="active_link"
            mat-list-item
          >
            <img src="../../../../assets/img/patient.svg" class="header_icon" alt="Patient" />
            {{'HOME.core-layout-header-Patients' | translate}}</a
          >
        </mat-nav-list>
      </mat-sidenav>
    </mat-sidenav-container>
  </div>
</div>
