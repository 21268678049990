import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { PatientService } from 'src/app/core/services/patient.service';
import { TooltipService } from 'src/app/core/services/tooltip.service';

const ROOM_VIEW_INDEX = 0;

@Component({
  selector: 'app-room-view',
  templateUrl: './room-view.component.html',
  styleUrls: ['./room-view.component.css'],
})
export class RoomViewComponent implements OnInit, OnChanges {
  constructor(
    private _patientService: PatientService,
    private _tooltipService: TooltipService
  ) {
    this.isPatientDetailOpened = false;
  }

  isPatientDetailOpened: boolean;
  @Input() currentTabIndex: number;
  selectedPatientId: string;
  patientMedicationList: any[] = [];

  ngOnInit(): void {
    this._patientService.patientMedicationFilteredListObs.subscribe((data) => {
      this.patientMedicationList = data;
      //clear previous tooltip (if exist) => To check tootip stuck issue on data refresh
      this._tooltipService.close();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        if (propName == 'currentTabIndex') {
          if (
            changes[propName].previousValue === ROOM_VIEW_INDEX &&
            changes[propName].currentValue !== ROOM_VIEW_INDEX &&
            this.isPatientDetailOpened === true
          ) {
            this.isPatientDetailOpened = false;
          }
        }
      }
    }
  }

  toggleDetailView(e) {
    this.isPatientDetailOpened = !this.isPatientDetailOpened;
    this.selectedPatientId = e;
  }
}
