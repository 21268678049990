<div class="top_bar mb-d-inline">

  <app-hierarchy-filter></app-hierarchy-filter>

  <div class="right_side_filter d-flex">
    <div class="links mr-3">
      <div class="refresh_msg me-2" id="refresh_data">{{'HOME.ward-component-Filter-LastRefresh' | translate}} :
        {{pageRotationService.refreshTimeRemaining}}&nbsp;{{'HOME.ward-component-Filter-SecsAgo' | translate}}</div>
      <a class="hover-effect" id="refresh_icon">
        <img src="../../../../../assets/img/refresh.png" (click)="refreshData()" width="25px" alt="icon">
      </a>
    </div>
    <div class="filter_div rotate_dropdown_class mr-3">
      <label>{{'HOME.ward-component-Filter-Rotate' | translate}}</label>
      <mat-form-field appearance="outline" class="custom_mat_select">
        <mat-select id="rotate_select" placeholder="{{'GLOBAL.Choose_an_option' | translate}}" [(value)]="selectedRotationTime"
          (selectionChange)="changeRotationValue($event)">
          <mat-option value=0 id="do_not_rotate"> {{'HOME.ward-component-Filter-NotRotate' | translate}}</mat-option>
          <mat-option [id]="'select_rotation_' + i" *ngFor="let rotation of setting?.rotation let i = index" value={{rotation}}>
            {{'HOME.ward-component-Filter-AlarmView' | translate}} - {{rotation}}s
          </mat-option>

        </mat-select>
      </mat-form-field>
      <mat-progress-bar id="rotate_progressbar" class="custom_rotate_progress" mode="determinate"
        [value]="pageRotationService.perRotationTimeRemaining"></mat-progress-bar>
    </div>

    <div class="links" id="links_div">

      <a id="link_icon" class="dialog_btn hover-effect" (click)="openDialog()">
        <img src="../../../../../assets/img/link.svg" alt="icon">
      </a>
      <a id="toggle_icon" class="hover-effect" *ngIf="currentTabIndex === mapViewTabIndex &&!isAssignmentView"
        (click)="toggleSideView()">
        <img src="../../../../../assets/img/assignment.svg" alt="icon">
      </a>
      <a id="time_left_icon" class="hover-effect" *ngIf="currentTabIndex === mapViewTabIndex && isAssignmentView"
        (click)="toggleSideView()">
        <img src="../../../../../assets/img/time_left.png" width="25px" alt="icon">
      </a>
    </div>
  </div>
</div>
