import { ConnectedPosition, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector, StaticProvider } from '@angular/core';
import { TooltipOverlayMapCardComponent } from 'src/app/shared/component/tooltip-overlay-map-card/tooltip-overlay-map-card.component';
import { TooltipOverlayComponent } from 'src/app/shared/component/tooltip-overlay/tooltip-overlay.component';
import { TOOLTIP_DATA } from 'src/app/shared/constants/injectionTokens';

@Injectable({
  providedIn: 'root',
})
export class TooltipService {
  constructor(private _overlay: Overlay, private _injector: Injector) {
    // This is intentionally empty
  }
  private overlayRef: OverlayRef;

  open(origin: HTMLElement, data?: any, config?: { onMapCard?: boolean }) {
    if (!!this.overlayRef) {
      this.overlayRef.dispose();
    }
    const positionStrategy = this._overlay
      .position()
      .flexibleConnectedTo(origin)
      .withPositions([
        {
          originX: 'start', // left corner of the button
          originY: 'bottom', // bottom corner of the button
          overlayX: 'start', // left corner of the overlay to the origin
          overlayY: 'top', // top corner of the overlay to the origin
        } as ConnectedPosition,
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        } as ConnectedPosition,
      ])
      .withPush(false);

    const overlayRef = this._overlay.create({
      hasBackdrop: false,
      positionStrategy: positionStrategy,
      maxWidth: 250,
      panelClass:config && config.onMapCard ? 'overlay-map-tooltip' : 'overlay-tooltip-bg',
    });

    this.overlayRef = overlayRef;

    const tooltipPortal = new ComponentPortal(
      config && config.onMapCard
        ? TooltipOverlayMapCardComponent
        : TooltipOverlayComponent,
      null,
      this._createInjector(data)
    );

    overlayRef.attach(tooltipPortal);
  }

  close() {
    if (!!this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = undefined;
    }
  }

  private _createInjector(data: any) {
    const providers: StaticProvider[] = [
      { provide: TOOLTIP_DATA, useValue: data },
    ];

    return Injector.create({ parent: this._injector, providers: providers });
  }
}
