import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PatientService } from 'src/app/core/services/patient.service';
import { DeviceService } from 'src/app/core/services/device.service';
import { async } from 'q';

interface IFeedBackMsg {
  title: string;
  message: string;
}

@Component({
  selector: 'app-user-feedback-dialog',
  templateUrl: './user-feedback-dialog.component.html',
  styleUrls: ['./user-feedback-dialog.component.css'],
})
export class UserFeedbackDialogComponent implements OnInit {
  device_list: Array<any> = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _deviceService: DeviceService,
    private _patientService: PatientService
  ) {
    // This is intentionally empty
  }

  ngOnInit(): void {
    if (this.data.patient_id) {
      this._deviceService
        .getAssociatedDevicesForPatient(this.data.patient_id)
        .subscribe((resp: any) => {
          this.device_list = resp.response.devices;
          this.device_list.forEach((k) => {
            k.checked = false;
          });
          // this.options = resp
          console.log('device_list ', resp);
        });
    }
    // This is intentionally empty
  }

  dissociateDeviceFromPatient = async () => {
    if (!this.data.patient_id) {
      return;
    }
    try {
      console.log('checking device list ', this.device_list);
      var list = [];
      this.device_list.forEach((k) => {
        if (k.checked == true) {
          list.push(parseInt(k.id));
        }
      });
      var force_discharge = list.length == this.device_list.length;
      var resp = await this._patientService
        .dischargePatientByDeviceID(list, this.data.patient_id, force_discharge)
        .subscribe(
          (data) => {
            window.alert('Device dissociated');
          },
          (err) => {
            console.log("error response  ",err)
            // var error_message = err.error.errorMsg || 'Device not dissociated';
            window.alert("Device not dissociated");
          }
        );
    } catch (error) {
      var e = error;
    }
  };
}
