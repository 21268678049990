import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MatDialogConfig
} from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IHierarchy } from 'src/app/core/models/Hierarchy';
import { IPatient } from 'src/app/core/models/Patient';
import { PatientService } from 'src/app/core/services/patient.service';
import { UserFeedbackDialogComponent } from 'src/app/modules/ward/component/user-feedback-dialog/user-feedback-dialog.component';
import { BedSelectorComponent } from 'src/app/shared/component/bed-selector/bed-selector.component';
import { PatientEditorService } from '../../services/patient-editor.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-assigned-patient-table-view',
  templateUrl: './assigned-patient-table-view.component.html',
  styleUrls: ['./assigned-patient-table-view.component.css']
})
export class AssignedPatientTableViewComponent implements OnInit, OnChanges {
  constructor(
    private router: Router,
    private patientService: PatientService,
    private patientEditorService: PatientEditorService,
    private dialog: MatDialog,
    private _langService: TranslateService,
    private _snackBar: MatSnackBar
  ) {
    this._langService
      .get([
        'GLOBAL.Patient_Reassignment_Alert_Message',
        'GLOBAL.Reassignment_Failed_Alert_Msg',
        'GLOBAL.Discharge_Patient_Alert_Message',
        'GLOBAL.Discharge_Patient_Failed_Message',
        'GLOBAL.Select_Room_Bed_Alert',
        'GLOBAL.Assign_Patient_Dialog_Title',
        'GLOBAL.Assign_Patient_Dialog_Msg_Assign',
        'GLOBAL.Assign_Patient_Dialog_Msg_To',
        'GLOBAL.Discharge_Patient_Dialog_Title',
        'GLOBAL.Discharge_Patient_Dialog_Msg_Part1',
        'GLOBAL.Discharge_Patient_Dialog_Msg_Part2'
      ])
      .subscribe(ln => {
        this.Reassignment_Alert_Msg =
          ln['GLOBAL.Patient_Reassignment_Alert_Message'];
        this.Reassignment_Failed_Alert_Msg =
          ln['GLOBAL.Reassignment_Failed_Alert_Msg'];
        this.Discharge_Patient_Alert_Message =
          ln['GLOBAL.Discharge_Patient_Alert_Message'];
        this.Discharge_Patient_Failed_Message =
          ln['GLOBAL.Discharge_Patient_Failed_Message'];
        this.Select_Room_Bed_Alert = ln['GLOBAL.Select_Room_Bed_Alert'];
        this.Assign_Patient_Dialog_Title =
          ln['GLOBAL.Assign_Patient_Dialog_Title'];
        this.Assign_Patient_Dialog_Msg_Assign =
          ln['GLOBAL.Assign_Patient_Dialog_Msg_Assign'];
        this.Assign_Patient_Dialog_Msg_To =
          ln['GLOBAL.Assign_Patient_Dialog_Msg_To'];
        this.Discharge_Patient_Dialog_Title =
          ln['GLOBAL.Discharge_Patient_Dialog_Title'];
        this.Discharge_Patient_Dialog_Msg_Part1 =
          ln['GLOBAL.Discharge_Patient_Dialog_Msg_Part1'];
        this.Discharge_Patient_Dialog_Msg_Part2 =
          ln['GLOBAL.Discharge_Patient_Dialog_Msg_Part2'];
      });
  }

  private Reassignment_Alert_Msg;
  private Reassignment_Failed_Alert_Msg;
  private Discharge_Patient_Alert_Message;
  private Discharge_Patient_Failed_Message;
  private Select_Room_Bed_Alert;
  private Assign_Patient_Dialog_Title;
  private Assign_Patient_Dialog_Msg_Assign;
  private Assign_Patient_Dialog_Msg_To;
  private Discharge_Patient_Dialog_Title;
  private Discharge_Patient_Dialog_Msg_Part1;
  private Discharge_Patient_Dialog_Msg_Part2;

  rawDataSource: any[] = [];
  dataSource = new MatTableDataSource([]);
  userFeedBackDialogRef: MatDialogRef<UserFeedbackDialogComponent>;
  selectBedDialogRef: MatDialogRef<BedSelectorComponent>;
  @Input() assignTo: { room?: string; bed?: string; ward?: string } = {};
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Input() selectedPatientId: string | undefined;
  @Input() isMapView: boolean;

  ngOnInit(): void {
    this.patientService
      .getPatientByAdmissionStatus('assigned')
      .subscribe(patients => {
        this.rawDataSource = patients;
        this.setDataSource();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        if (propName == 'selectedPatientId') {
          this.setDataSource();
        }
      }
    }
  }

  displayedColumns: string[] = [
    'action',
    'room',
    'patient_name',
    'age',
    'height',
    'weight',
    'action'
  ];

  setDataSource() {
    if (this.selectedPatientId) {
      const filteredList = this.rawDataSource.filter(
        val => val.id === this.selectedPatientId
      );
      this.dataSource = new MatTableDataSource(filteredList);
    } else {
      this.dataSource = new MatTableDataSource(this.rawDataSource);
    }
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
    this.patientService.assignedPatientCount = this.dataSource.filteredData.length;
  }

  openFeedbackDialog(type: string, selectedPatient: IPatient) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    if (type === 'REASSIGN') {
      if (!this.assignTo.bed || !this.assignTo.room) {
        dialogConfig.data = {
          title: this.Select_Room_Bed_Alert
        };
        this.selectBedDialogRef = this.dialog.open(
          BedSelectorComponent,
          dialogConfig
        );

        this.selectBedDialogRef
          .afterClosed()
          .subscribe(
            (data: {
              room?: IHierarchy;
              bed?: IHierarchy;
              ward?: IHierarchy;
              canceled?: boolean;
            }) => {
              if (!data.canceled) {
                if (!!data.room.id && !!data.bed.id) {
                  this.assignTo.bed = data.bed.display_text;
                  this.assignTo.room = data.room.display_text;

                  this.openFeedbackDialog(type, selectedPatient);
                } else {
                  this._snackBar.open(this.Select_Room_Bed_Alert);
                  return;
                }
              } else {
                return;
              }
            }
          );
      } else {
        dialogConfig.data = {
          title: this.Assign_Patient_Dialog_Title,
          message: `${this.Assign_Patient_Dialog_Msg_Assign} ${selectedPatient.patient_name} ${this.Assign_Patient_Dialog_Msg_To} ${this.assignTo.room}-${this.assignTo.bed}?`
        };

        this.userFeedBackDialogRef = this.dialog.open(
          UserFeedbackDialogComponent,
          dialogConfig
        );

        this.userFeedBackDialogRef.afterClosed().subscribe(data => {
          if (data) {
            const reqData = {
              id: selectedPatient.id,
              point_of_care: this.assignTo.ward,
              room: this.assignTo.room,
              bed: this.assignTo.bed
            };
            this.patientService.assignPatient(reqData).subscribe(result => {
              if (result) {
                this._snackBar.open(this.Reassignment_Alert_Msg);
                return;
              }
              this._snackBar.open(this.Reassignment_Failed_Alert_Msg);
            });
          }
        });
      }
    }

    if (type === 'DISCHARGE') {
      dialogConfig.data = {
        title: this.Discharge_Patient_Dialog_Title,
        message: `${this.Discharge_Patient_Dialog_Msg_Part1} ${selectedPatient.patient_name}${this.Discharge_Patient_Dialog_Msg_Part2}`,
        patient_id: selectedPatient.id
      };

      this.userFeedBackDialogRef = this.dialog.open(
        UserFeedbackDialogComponent,
        dialogConfig
      );

      this.userFeedBackDialogRef.afterClosed().subscribe(data => {
        if (data) {
          // this.patientService
          //   .dischargePatient(selectedPatient.id)
          //   .subscribe(result => {
          //     if (result) {
          //       alert(this.Discharge_Patient_Alert_Message);
          //       return;
          //     }
          //     alert(this.Discharge_Patient_Failed_Message);
          //   });
        }
      });
    }
  }
}
