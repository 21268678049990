import {
  Component,
  OnInit,
  ElementRef,
  Input,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-stack-barand-linechart',
  templateUrl: './stack-barand-linechart.component.html',
  styleUrls: ['./stack-barand-linechart.component.css'],
})
export class StackBarandLinechartComponent implements OnInit {
  @Input() data: any[] = [];
  deviceObservation: any = {};

  constructor(
    private container: ElementRef,
    private _langService: TranslateService
  ) {
    this._langService
      .get([
        'HOME.ward-pages-PCA_Report-Loading',
        'HOME.ward-pages-PCA_Report-Requested_Not_Delivered',
        'HOME.ward-pages-PCA_Report-Continious',
        'HOME.ward-pages-PCA_Report-Prescribed_Max',
        'HOME.ward-pages-PCA_Report-PCA',
        'HOME.ward-pages-PCA_Report-Total_Administered'
      ])
      .subscribe((ln) => {
        this.Ward_Pages_PCA_Report_Loading =
          ln['HOME.ward-pages-PCA_Report-Loading'];
        this.Ward_Pages_PCA_Report_Requested_Not_Delivered =
          ln['HOME.ward-pages-PCA_Report-Requested_Not_Delivered'];
        this.Ward_Pages_PCA_Report_Continious =
          ln['HOME.ward-pages-PCA_Report-Continious'];
        this.Ward_Pages_PCA_Report_Prescribed_Max =
          ln['HOME.ward-pages-PCA_Report-Prescribed_Max'];
        this.Ward_Pages_PCA_Report_PCA =
          ln['HOME.ward-pages-PCA_Report-PCA'];
        this.Ward_Pages_PCA_Report_Total_Administered =
          ln['HOME.ward-pages-PCA_Report-Total_Administered'];
      });
  }

  private Ward_Pages_PCA_Report_Loading;
  private Ward_Pages_PCA_Report_Requested_Not_Delivered;
  private Ward_Pages_PCA_Report_Continious;
  private Ward_Pages_PCA_Report_Prescribed_Max;
  private Ward_Pages_PCA_Report_PCA;
  private Ward_Pages_PCA_Report_Total_Administered;

  ngOnInit(): void {
    // This is intentionally empty
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        if (propName == 'data') {
          if (this.data.length > 0) {
            this.createGraph();
          } else if (!changes[propName].isFirstChange()) {
            //empty
          }
        }
      }
    }
  }

  createGraph() {
    // set the dimensions and margins of the graph
    const margin = { top: 10, right: 30, bottom: 85, left: 50 };
    const width = 1000 - margin.left - margin.right;
    const height = 400 - margin.top - margin.bottom;

    // append the svg object to the body of the page
    const svg = d3
      .select(this.container.nativeElement)
      .append('svg')

      .attr('width', width)
      .attr('height', height)
      .attr('viewBox', [0, 0, width, height])
      .attr('style', 'max-width: 100%; height: 100%; height: intrinsic;')
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    let data = this.data;

    const subgroups = [
      'pca',
      'continuous',
      'loading',
      'requested_not_delivered',
    ];

    const groups = d3.map(data, function (d) {
      return d.time;
    });

    // Add X axis
    const x = d3
      .scaleBand()
      .domain(groups)
      .range([0, width - margin.left - margin.right])
      .padding([0.2]);
    svg
      .append('g')
      .attr(
        'transform',
        `translate(0, ${height - margin.top - margin.bottom} )`
      )
      .call(
        d3
          .axisBottom(x)
          .tickFormat((d) => {
            return d.split(' ')[1];
          })
          .tickSize(0)
      )
      .selectAll('text')
      .style('text-anchor', 'end')
      .style('font-size', '14px')
      .attr('transform', 'rotate(-60)');

    const y = d3.scaleLinear().range([height - margin.top - margin.bottom, 0]);
    const yScaleForLineVolumeDelivered = d3
      .scaleLinear()
      .domain([
        d3.min(this.data, (d) => {
          return d.total_administered;
        }),

        d3.max(this.data, (d) => {
          return d.total_administered;
        }),
      ])
      .range([height - margin.top - margin.bottom, 0]);

    const yScaleForLinePrescribedMax = d3
      .scaleLinear()
      .domain([
        d3.min(this.data, (d) => {
          return d.prescribed_max;
        }),

        d3.max(this.data, (d) => {
          return d.prescribed_max;
        }),
      ])
      .range([height - margin.top - margin.bottom, 0]);

    // color palette = one color per subgroup
    const color = d3
      .scaleOrdinal()
      .domain(subgroups)
      .range(['#ffc107', '#781C68', '#fd9644', '#d9e2ef']);

    //stack the data? --> stack per subgroup
    const stackedData = d3.stack().keys(subgroups)(data);

    y.domain([
      0,
      d3.max(stackedData[stackedData.length - 1], (data1) => {
        return data1[1];
      }),
    ]);
    svg
      .append('g')
      .call(
        d3
          .axisLeft(y)
          .ticks(5)
          .tickFormat((d) => {
            return d + ' mg';
          })
      )
      .selectAll('text')
      .style('font-size', '14px');

    // Show the bars
    svg
      .append('g')
      .selectAll('g')
      .data(stackedData)
      .enter()
      .append('g')
      .attr('fill', function (d) {
        return color(d.key);
      })
      .selectAll('rect')
      .data(function (d) {
        return d;
      })
      .enter()
      .append('rect')
      .attr('x', function (d) {
        return x(d.data.time);
      })
      .attr('y', function (d) {
        return y(d[1]);
      })
      .attr('height', function (d) {
        return y(d[0]) - y(d[1]);
      })
      .attr('width', x.bandwidth());

    //for line chart

    let rectWidth = x.bandwidth();
    const lineChart = svg.append('g');

    const line = d3.line().x(function (d, i) {
      return x(d.time) + rectWidth / 2;
    });

    line.y(function (d) {
      return yScaleForLineVolumeDelivered(d.total_administered);
    });

    lineChart
      .append('path')
      .datum(data) // Binds data to the line
      .attr('class', 'line') // Assign a class for styling
      .attr('fill', 'none')
      .attr('stroke', '#42ba96')
      .attr('stroke-width', 2)

      .attr('d', line);

    line.y(function (d) {
      return yScaleForLinePrescribedMax(d.prescribed_max);
    });

    lineChart
      .append('path')
      .datum(data) // Binds data to the line
      .attr('class', 'line') // Assign a class for styling
      .attr('fill', 'none')
      .attr('stroke', '#467fd0')
      .attr('stroke-width', 2)
      .attr('d', line);

    const legendsData = [
      {
        label: this.Ward_Pages_PCA_Report_Continious,
        color: '#781C68',
        isCircleType: true,
      },
      {
        label: this.Ward_Pages_PCA_Report_Loading,
        color: '#fd9644',
        isCircleType: true,
      },
      {
        label: this.Ward_Pages_PCA_Report_Requested_Not_Delivered,
        color: '#d9e2ef',
        isCircleType: true,
      },
      {
        label: this.Ward_Pages_PCA_Report_PCA,
        color: '#ffc107',
        isCircleType: true,
      },

      {
        label: this.Ward_Pages_PCA_Report_Total_Administered,
        color: '#42ba96',
        isCircleType: false,
      },
      {
        label: this.Ward_Pages_PCA_Report_Prescribed_Max,
        color: '#467fd0',
        isCircleType: false,
      },
    ];

    const xSclaleForColor = d3
      .scaleLinear()
      .domain([0, 6])
      .range([0, width - margin.left - margin.right]);

    const legend = svg
      .append('g')
      .attr('transform', `translate(${10},${height - margin.bottom + 50})`);

    legend
      .selectAll('circle')
      .data(legendsData)
      .enter()
      .append('circle')
      .attr('cx', (d, i) => {
        return xSclaleForColor(i);
      })
      .attr('cy', 0)
      .attr('r', 5)
      .attr('fill', (d) => {
        console.log(d);

        return d.color;
      });
    legend
      .selectAll('text')
      .data(legendsData)
      .enter()

      .append('text')
      .style('fill', '#ffffff')
      .attr('text-anchor', 'middle')
      .attr('transform', (d, i) => {
        return `translate(${xSclaleForColor(i)},${20})`;
      })
      .style('font-size', '14px')
      .text((d) => {
        return d.label;
      });
  }
}
