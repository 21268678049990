<div id="pdf_container">
  <div class="report-container">
    <div class="chart_heading_div d-flx-spc-btwn mb-3">
      <h1 class="mb-0" id="report-heading">
        {{ "HOME.ward-pages-General_Report-GeneralReport" | translate }}
      </h1>
      <button id="back_btn" type="button" class="icon_btn hover-effect" (click)="goBack()">
        <img src="../../../../../assets/img/back.png" class="table_icon" alt="Back" />
      </button>
    </div>

    <div class="report-row1 row" id="report_row">
      <div class="report_detail col-lg-4">
        <table aria-label="General Report data">
          <tr>
            <th>{{ "HOME.ward-pages-General_Report-Infuser" | translate }}</th>
            <td>{{ deviceObservation.infuser }}</td>
          </tr>
          <tr>
            <th>{{ "HOME.ward-pages-General_Report-Patient" | translate }}</th>
            <td>{{ deviceObservation.patient_name }}</td>
          </tr>
          <tr>
            <th>
              {{ "HOME.ward-pages-General_Report-Medication" | translate }}
            </th>
            <td>{{ deviceObservation.medication }}</td>
          </tr>
          <tr>
            <th>
              {{ "HOME.ward-pages-General_Report-Administration" | translate }}
            </th>
            <td>
              {{ deviceObservation.start_time }} -
              {{ deviceObservation.end_time }}
            </td>
          </tr>
        </table>
      </div>
 
      <div class="chart-component-general col-lg-8" id="chart_report">
        <div class="chart_report">
          <app-general-report-chart
            [data]="dataSource"
          ></app-general-report-chart>
        </div>
      </div>
    </div>

    <div class="report-row2">
      <div class="map_table">
        <div class="export_div">
          <p>{{ "HOME.ward-pages-General_Report-ExportTo" | translate }}:</p>
          <button
            id="excel_btn"
            type="button"
            class="custom_btn primary_btn mr-3"
            (click)="exportToExcel()"
          >
            {{ "HOME.ward-pages-General_Report-Excel" | translate }}
          </button>
          <button
            id="pdf_btn"
            type="button"
            class="custom_btn secondary_btn"
            (click)="exportToPdf()"
          >
            {{ "HOME.ward-pages-General_Report-Pdf" | translate }}
          </button>
        </div>

        <table mat-table [dataSource]="dataSource" id="report_table" aria-label="General report data">
          <ng-container matColumnDef="Time">
            <th mat-header-cell *matHeaderCellDef>
              {{ "HOME.ward-pages-General_Report-Time" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.time }}</td>
          </ng-container>
          <ng-container matColumnDef="Rate">
            <th mat-header-cell *matHeaderCellDef>
              {{ "HOME.ward-pages-General_Report-Rate" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.rate }}</td>
          </ng-container>
          <ng-container matColumnDef="Dose">
            <th mat-header-cell *matHeaderCellDef>
              {{ "HOME.ward-pages-General_Report-Dose" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.dose }}</td>
          </ng-container>
          <ng-container matColumnDef="VTBI">
            <th mat-header-cell *matHeaderCellDef>
              {{ "HOME.ward-pages-General_Report-VTBI" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.volume_tbi }}</td>
          </ng-container>
          <ng-container matColumnDef="VI">
            <th mat-header-cell *matHeaderCellDef>
              {{ "HOME.ward-pages-General_Report-VI" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.VI }} &nbsp;&nbsp;
              <span class="float-end">{{ element.volume_delivered }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="Event">
            <th mat-header-cell *matHeaderCellDef>
              {{ "HOME.ward-pages-General_Report-Event" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.alarm }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
  </div>
</div>
