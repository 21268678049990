import {
  Component,
  OnInit,
  Output,
  Input,
  OnChanges,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import { PatientService } from 'src/app/core/services/patient.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { MatSelectChange } from '@angular/material/select';
import { EnterpriseService } from 'src/app/core/services/enterprise.service';
import { IHierarchy } from 'src/app/core/models/Hierarchy';
import { ReferenceService } from 'src/app/core/services/reference.service';
import { IReference } from 'src/app/core/models/service';

@Component({
  selector: 'app-patient-detail-form',
  templateUrl: './patient-detail-form.component.html',
  styleUrls: ['./patient-detail-form.component.css'],
})
export class PatientDetailFormComponent implements OnInit, OnChanges {
  constructor(
    private patientService: PatientService,
    private _snackbar: MatSnackBar,
    private langService: TranslateService,
    public hierarchyService: EnterpriseService,
    public referenceService: ReferenceService
  ) {
    this.langService
      .get([
        'HOME.patient-component-Patient_Detail_Form-Please_Enter_valid_Values',
        'HOME.patient-component-Patient_Detail_Form-Ids_Save_Successfully',
        'HOME.patient-component-Patient_Detail_Form-Ids_Removed_Successfully',
        'HOME.medication-component-Medication_General_Detail-close',
      ])
      .subscribe((ln) => {
        this.Please_Enter_valid_Values =
          ln[
            'HOME.patient-component-Patient_Detail_Form-Please_Enter_valid_Values'
          ];
        this.Ids_Save_Successfully =
          ln[
            'HOME.patient-component-Patient_Detail_Form-Ids_Save_Successfully'
          ];
        this.Ids_Removed_Successfully =
          ln[
            'HOME.patient-component-Patient_Detail_Form-Ids_Removed_Successfully'
          ];
        this.close =
          ln['HOME.medication-component-Medication_General_Detail-close'];
      });
  }
  selectedWard: any = {};
  wards: IHierarchy[] = [];
  references: IReference[] = [];

  reference: IReference[] = [];

  patient_ide_type: any = [];
  poc_identifier: string = '';
  show_room: boolean = false;
  show_bed: boolean = false;

  patientDetail: any;
  patientObservable: Subscription;
  ids: any = [];
  patientDetailForm = new FormGroup({
    first_name: new FormControl('', Validators.required),
    last_name: new FormControl('', Validators.required),
    middle_name: new FormControl(''),
    dob: new FormControl('', Validators.required),
    age: new FormControl(''),
    height: new FormControl(''),
    weight: new FormControl(''),
    type: new FormControl(''),
    gender: new FormControl('', Validators.required),
    ids: new FormControl(''),
    visit_number: new FormControl('', Validators.required),
    admission_dttm: new FormControl('', Validators.required),
    poc: new FormControl('', Validators.required),
  });

  //alerts message string from lang file
  Please_Enter_valid_Values;
  Ids_Save_Successfully;
  Ids_Removed_Successfully;
  close;

  @Input() isSaved = false;
  @Output() patientData = new EventEmitter<{}>();

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        if (propName == 'isSaved' && this.isSaved) {
          this.patientData.emit({
            ...this.patientDetailForm,
            ids: this.ids,
            id: this.patientDetail?.id || '',
          });
        }
      }
    }
  }

  ngOnInit(): void {
    this._init();
    this.patientObservable = this.patientService.patientDetailIdObs.subscribe(
      (res) => {
        this.getPatientDetail(res);
      }
    );
  }

  private _init() {
    this.hierarchyService.childHierarchies.subscribe((data) => {
      if (data) {
        this.wards = data;
        console.log("da",this.wards)
      }
    });

    this.hierarchyService.selectedWard.subscribe((data) => {
      if (data) {
        this.selectedWard = data;
      }
    });

    this.referenceService.referenceListObs.subscribe((data) => {
      if (data) {
        this.reference = data.filter((v) => {
          return v.ref_type == 'GENDER';
        });
      }
      console.log("refe",this.reference)
    });

    this.referenceService.referenceListObs.subscribe((data) => {
      if (data) {
        this.patient_ide_type = data.filter((v) => {
          return v.ref_type == 'PID_TYPE';
        });
      }
    });
  }

  getPatientDetail(id) {
    const body = { id: id };
    this.patientService.getPatientDetailById(body).subscribe((res) => {
      this.patientDetail = res;
      this.patientDetailForm.patchValue({
        first_name: this.patientDetail.first_name,
        last_name: this.patientDetail.last_name,
        middle_name: this.patientDetail.middle_name,
        dob: this.patientDetail.dob,
        age: this.patientDetail.age,
        gender: this.patientDetail.gender,
        height: this.patientDetail.height,
        weight: this.patientDetail.weight,
        type: this.patientDetail.type,
        ids: '',
        visit_number: this.patientDetail.visit_number,
        admission_dttm: this.patientDetail.admission_dttm,
        poc: this.patientDetail.poc,
      });
      this.ids = this.patientDetail.ids || [];
    });
  }

  savePatienId() {
    if (
      this.patientDetailForm.controls.type.errors ||
      this.patientDetailForm.controls.ids.errors
    ) {
      this._snackbar.open(this.Please_Enter_valid_Values);
      return;
    }
    const ids = {
      type: this.patientDetailForm.controls.type.value,
      id: this.patientDetailForm.controls.ids.value,
      gender: this.patientDetailForm.controls.gender.value,
    };
    this.ids.push(ids);
    this._snackbar.open(this.Ids_Save_Successfully);
  }

  removeIds(index) {
    this.ids.splice(index, 1);
    this._snackbar.open(this.Ids_Removed_Successfully);
  }

  ngOnDestroy() {
    this.patientObservable.unsubscribe();
  }

  onDOBChange(e) {
    console.log(e.target.value);
    const age = this.calcAge(e.target.value);

    this.patientDetailForm.setValue({
      ...this.patientDetailForm.value,
      age: age.toString(),
    });
  }

  calcAge(dateString: string) {
    let birthday = +new Date(dateString);
    return ~~((Date.now() - birthday) / 31557600000);
  }

  onWardChange(e: MatSelectChange) {
    this.selectedWard = e.value;
    this.hierarchyService.updateWardSelection(e.value);
    this.show_room = true;
  }
  onGenderChange(e: MatSelectChange) {
    this.reference = e.value;
    this.hierarchyService.updateWardSelection(e.value);
    // this.show_room = true
  }

  onRoomChange(e: MatSelectChange) {
    this.selectedWard = e.value;
    this.hierarchyService.updateWardSelection(e.value);
    this.show_bed = true;
  }
}
