<app-patient-header></app-patient-header>
<div class="main-content_div">
  <div class="inner-top-head mb-d-inline">
    <div class="d-flex align-items-center justify-content-end">
      <button id="add_patient" type="button" class="custom_btn mb_mar_tp_0" (click)="patientEditorService.openDialog()"><img
          src="../../../../../assets/img/patient.svg" class="table_icon me-1 m_d_none"
          alt="Patient">{{'HOME.patient-pages-Patient_List-Patients' | translate}}</button>
    </div>

  </div>

  <div class="patient_list_main">

    <mat-accordion>
      <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" id="assigned_patient_expansion">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{'HOME.patient-pages-Patient_List-AssignedPatients' | translate}} : <span class="txt-blue ms-2">
              {{patientService.assignedPatientCount}} </span>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <app-assigned-patient-table-view></app-assigned-patient-table-view>

      </mat-expansion-panel>

      <mat-expansion-panel (opened)="unassignedPatientList = true" (closed)="unassignedPatientList = false" id="unassigned_patient_expansion">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{'HOME.patient-pages-Patient_List-UnassignedPatients' | translate}} : <span class="txt-blue ms-2">
              {{patientService.unassignedPatientCount}} </span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-unassigned-patient-table-view *ngIf="unassignedPatientList"></app-unassigned-patient-table-view>
      </mat-expansion-panel>

      <mat-expansion-panel (opened)="dischargedPatientList = true" (closed)="dischargedPatientList = false" id="dissociated_patient_expansion">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{'HOME.patient-pages-Patient_List-DischargePatients' | translate}} : <span class="txt-blue ms-2">
              {{patientService.dischargePatientCount}} </span>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <app-discharge-patient *ngIf="dischargedPatientList"></app-discharge-patient>

      </mat-expansion-panel>
    </mat-accordion>

  </div>

</div>