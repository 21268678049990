<div class="map_table assigned_table">
  <div class="search_table">
    <div class="input-group mb-3">
      <span class="input-group-text" id="basic-addon1">
        <img src="../../../../../assets/img/search_icon.svg" class="table_icon" alt="Search" /> </span>
      <input id="input_search" type="text" class="form-control" placeholder="{{'GLOBAL.Search_Placeholder' | translate}}" (keyup)="applyFilter($event.target.value)">
    </div>
  </div>

  <table mat-table [dataSource]="dataSource" matSort class="w_900" aria-label="Patient List data" id="assigned_table_view">
    <ng-container matColumnDef="room">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'HOME.patient-component-Assigned_Patient_Table_View-Room'
        | translate}} </th>
      <td mat-cell *matCellDef="let element"> {{element.room + "-" + element.bed}} </td>
    </ng-container>

    <ng-container matColumnDef="patient_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{'HOME.patient-component-Assigned_Patient_Table_View-Patient' | translate}} </th>
      <td mat-cell *matCellDef="let element" class="min-wdt_150"> {{element.patient_name}} </td>
    </ng-container>

    <ng-container matColumnDef="age">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{'HOME.patient-component-Assigned_Patient_Table_View-AssignedAge' | translate}} </th>
      <td mat-cell *matCellDef="let element" class="text-end"> {{element.age}} {{'HOME.patient-component-Assigned_Patient_Table_View-Years' | translate}}</td>
    </ng-container>

    <ng-container matColumnDef="height">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{'HOME.patient-component-Assigned_Patient_Table_View-Height' | translate}}</th>
      <td mat-cell *matCellDef="let element" class="text-end"> {{element.height}}
      </td>
    </ng-container>

    <ng-container matColumnDef="weight">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{'HOME.patient-component-Assigned_Patient_Table_View-Weight' | translate}} </th>
      <td mat-cell *matCellDef="let element" class="text-end"> {{element.weight}}
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>
        {{'HOME.patient-component-Assigned_Patient_Table_View-Action' | translate}} </th>
      <td mat-cell *matCellDef="let element">
        <div class="table_icon_div min-wdt_100">
          <img id="discharge_btn" src="../../../../../assets/img/discharge.svg" (click)="openFeedbackDialog('DISCHARGE', element)"
            class="table_icon me-2" alt="Discharge">
          <app-reassign-patient-btn [patientDetail]="element" [isMapView]="isMapView"></app-reassign-patient-btn>
          <img id="edit_btn" src="../../../../../assets/img/edit.svg" class="table_icon" alt="Edit"
            (click)="patientEditorService.openDialog(element.id)">
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="9999">
        {{'HOME.patient-component-Assigned_Patient_Table_View-NoDataAvailable' | translate}} </td>
    </tr>
  </table>
</div>
