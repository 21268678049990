<app-filter [currentTabIndex]="selectedTabIndex" (toggleSideViewEvent)="toggleSideView()"></app-filter>

<div class="tab_div">
  <mat-tab-group (selectedTabChange)="onTabChange($event)" animationDuration="0ms" mat-align-tabs="start">

    <mat-tab label="{{'HOME.ward-component-BaseLayout-Room' | translate}}" class="tab_label">
      <app-room-view [currentTabIndex]="selectedTabIndex"></app-room-view>
    </mat-tab>

    <mat-tab label="{{'HOME.ward-component-BaseLayout-Time' | translate}}" class="tab_label">
      <app-time-view></app-time-view>
    </mat-tab>

    <mat-tab label="{{'HOME.ward-component-BaseLayout-Map' | translate}}" class="tab_label">

      <app-map-view></app-map-view>
    </mat-tab>
  </mat-tab-group>

  <div *ngIf="selectedTabIndex === 2 && (mapViewService.mapViewUIStateObs | async).isReassignmentMode" class="clear_reassing">
    <button class="custom_btn me-2 mb-2 flashing" id="clear_reassignment_mode"
      (click)="clearReassignmentMode()">{{'HOME.ward-component-BaseLayout-ClearReassignmentMode' | translate}}</button>
  </div>

  <div class="sort-by">
    <mat-form-field appearance="outline" class="custom_mat_select">
      <mat-select id="sort_by" placeholder="Sort by: " [(ngModel)]="selectedSortingCriteria"
        (selectionChange)="onSortingCriteriaChange($event)">
        <mat-option value="" id="default"> {{'HOME.ward-component-BaseLayout-Default' | translate}}</mat-option>
        <mat-option value="time_remaining ASC" id="time_remaining_ASC"> {{'HOME.ward-component-BaseLayout-TimeRemainingASC' | translate}}
        </mat-option>
        <mat-option value="time_remaining DESC" id="time_remaining_DESC"> {{'HOME.ward-component-BaseLayout-TimeRemainingDESC' | translate}}
        </mat-option>
        <mat-option value="room ASC" id="room_ASC"> {{'HOME.ward-component-BaseLayout-PatientRoomASC' | translate}} </mat-option>
        <mat-option value="room DESC" id="room_DESC"> {{'HOME.ward-component-BaseLayout-PatientRoomDESC' | translate}}</mat-option>
        <mat-option value="last_name ASC" id="last_name_ASC"> {{'HOME.ward-component-BaseLayout-PatientLastnameASC' | translate}}
        </mat-option>
        <mat-option value="last_name DESC" id="last_name_DESC"> {{'HOME.ward-component-BaseLayout-PatientLastnameDESC' | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
