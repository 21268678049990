import { Component, OnInit } from '@angular/core';
import { PatientService } from 'src/app/core/services/patient.service';

@Component({
  selector: 'app-time-view',
  templateUrl: './time-view.component.html',
  styleUrls: ['./time-view.component.css'],
})
export class TimeViewComponent implements OnInit {
  constructor(public patientService: PatientService) {
    // This is intentionally empty
  }
  
  patientMedicationData: any[];

  ngOnInit(): void {
    this.patientService.medicationFilteredTimeViewObs.subscribe((data) => {
      this.patientMedicationData = data;
    });
  }
}
