<app-filter [currentTabIndex]="1"></app-filter>
<div class="patient_detail" id="patient_details">
    <div class="patient_head mb-d-inline">
        <label>{{selectedPatient?.room}}-{{selectedPatient?.bed}}: {{selectedPatient?.patient_name}}</label>
        <label>{{'HOME.ward-pages-Patient_Detail_View-Age' | translate}}: {{selectedPatient?.age}} {{'HOME.ward-pages-Patient_Detail_View-Years' | translate}}</label>
        <label>{{'HOME.ward-pages-Patient_Detail_View-Height' | translate}}: {{selectedPatient?.height}} </label>
        <label>{{'HOME.ward-pages-Patient_Detail_View-Weight' | translate}}: {{selectedPatient?.weight}} </label>
        <label>
            <div class="table_icon_div">
                <img id="back_btn" src="../../../../../assets/img/back.png" routerLink="/ward" class="table_icon me-2" alt="Back">
                <img id="discharge_btn" src="../../../../../assets/img/discharge.svg" (click)="openFeedbackDialog('DISCHARGE')"
                    class="table_icon me-2" alt="Discharge">
                <app-reassign-patient-btn [patientDetail]="selectedPatient" [isMapView]="false">
                </app-reassign-patient-btn>
            </div>
        </label>
    </div>
    <app-patient-medication-table-view [data]="selectedPatient ? selectedPatient.medications : []">
    </app-patient-medication-table-view>
</div>