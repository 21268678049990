import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { AccessDeniedPageComponent } from './pages/access-denied-page/access-denied-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'ward',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginPageComponent,
  },
  {
    path: 'access-denied',
    component: AccessDeniedPageComponent,
  },
  {
    path: 'ward',
    loadChildren: () =>
      import('./modules/ward/ward.module').then((m) => m.WardModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'alert',
    loadChildren: () =>
      import('./modules/alert/alert.module').then((m) => m.AlertModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'patient',
    loadChildren: () =>
      import('./modules/patient/patient.module').then((m) => m.PatientModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'medication',
    loadChildren: () =>
      import('./modules/medication/medication.module').then(
        (m) => m.MedicationModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'setting',
    loadChildren: () =>
      import('./modules/setting/setting.module').then(
        (m) => m.SettingModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: '**' /*url for 404 page*/,
    component: NotFoundPageComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
