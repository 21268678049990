import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(public router: Router, private _http: HttpClient) {
    this.authToken = localStorage.getItem('act_tk');
    this.userDetail.name = localStorage.getItem('usr_nm');

    this._userStore.accessToken = this.authToken;
    this._userStore.refreshToken = localStorage.getItem('rfs_tkn');
  }
  private authToken: string;
  private userDetail: any = {};
  private _isRefreshing: boolean = false;
  private _userStore = {
    accessToken: '',
    refreshToken: '',
  };

  private _currentUser: BehaviorSubject<any> = new BehaviorSubject<any>(
    this._userStore
  );

  public _refreshToken: Subject<string> = new Subject<string>();

  public refreshTokenObs = this._refreshToken.asObservable();

  /**
   * (Is Authenticated)
   * This function checks whether an access token is present
   * in storage
   */
  public isAuthenticated(): boolean {
    if (this.authToken) {
      return true;
    } else {
      return false;
    }
  }

  public getAuthToken(): string {
    if (this.authToken) {
      return this.authToken;
    } else {
      return '';
    }
  }

  /**
   * setAuthToken
   */
  public setAuthToken(token: string, user: any, refreshToken: string, isRefresh?: boolean) {
    localStorage.setItem('act_tk', token);
    localStorage.setItem('usr_nm', user);
    localStorage.setItem('rfs_tkn', refreshToken);
    this.authToken = token;
    this.userDetail.name = user;
    this._userStore.accessToken = token;
    this._userStore.refreshToken = refreshToken;

    if(isRefresh)
    this._refreshToken.next(this.authToken);
  }

  /**
   * authenticate
   */
  public authenticate(username: string, password: string) {
    return this._http
      .post('/api/v1/user/authenticate', {
        email: username,
        password: password,
      })
      .pipe(
        map((res: any) => {
          if (res.success) {
            this.setAuthToken(
              res.response.token,
              res.response?.user?.item?.user?.first_name +
                ' ' +
                res.response?.user?.item?.user?.middle_name +
                ' ' +
                res.response?.user?.item?.user?.last_name,
              res.response?.user?.item?.refresh_token
            );
            return {
              success: true,
              msg: res.successMsg,
            };
          }

          return {
            success: false,
            msg: res.errorMsg,
          };
        }),
        catchError((err) =>
          of({
            success: false,
            msg: err?.error?.errorMsg,
          })
        )
      );
  }

  getUserDetail() {
    return this.userDetail;
  }

  logout() {
    localStorage.removeItem('act_tk');
    localStorage.removeItem('usr_nm');
    this.authToken = '';
    this.userDetail.name = '';

    this.router.navigateByUrl('/login');

    //window.location.href = '/#/login';
  }

  isRefreshing() {
    return this._isRefreshing;
  }

  getNewToken() {
    
    const refreshToken = localStorage.getItem('rfs_tkn');
    if (!refreshToken) {
      throw new Error('Refresh token not found');
    }

    localStorage.removeItem('rfs_tkn');
    this._isRefreshing = true;

    //send new token request
    this._newTokenRequest(refreshToken);
  }

  private _newTokenRequest(refreshToken: string) {
    const NEW_TOKEN_URL = '/api/v1/user/newAccessToken';
    const refreshRequestBody = {
      refresh_token: refreshToken,
    };

    //http call to refresh the token
    this._http.post(NEW_TOKEN_URL, refreshRequestBody).subscribe(
      (res: any) => {

        if (!res.success) {
          this.logout();
          return;
        }

        this.setAuthToken(
          res.response.token,
          res.response?.user?.item?.user?.first_name +
            ' ' +
            res.response?.user?.item?.user?.middle_name +
            ' ' +
            res.response?.user?.item?.user?.last_name,
          res.response?.user?.item?.refresh_token,
          true
        );

        //set _isRefreshing to false after response
        this._isRefreshing = false;
      },
      (error: any) => {
        this._isRefreshing = false;
        this.logout();
      }
    );
  }
}
