import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WardRoutingModule } from './ward-routing.module';
import { FilterComponent } from './component/filter/filter.component';
import { BaseLayoutComponent } from './component/base-layout/base-layout.component';
import { RoomCardViewComponent } from './component/room-card-view/room-card-view.component';
import { RoomViewComponent } from './pages/room-view/room-view.component';
import { TimeViewComponent } from './pages/time-view/time-view.component';
import { MapViewComponent } from './pages/map-view/map-view.component';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RouterModule } from '@angular/router';
import { TableViewComponent } from './component/table-view/table-view.component';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { PatientAssociationDialogComponent } from './component/patient-association-dialog/patient-association-dialog.component';
import { PatientModule } from '../patient/patient.module';
import { RemainingTimeViewComponent } from './component/remaining-time-view/remaining-time-view.component';
import { PatientDetailViewComponent } from './pages/patient-detail-view/patient-detail-view.component';
import { UserFeedbackDialogComponent } from './component/user-feedback-dialog/user-feedback-dialog.component';
import { PumpDetailViewComponent } from './pages/pump-detail-view/pump-detail-view.component';
import { EventFeedComponent } from './component/event-feed/event-feed.component';
import { PumpChartComponent } from './component/pump-chart/pump-chart.component';
import { PumpLineChartComponent } from './component/pump-line-chart/pump-line-chart.component';
import { EventFilterDialogComponent } from './component/event-filter-dialog/event-filter-dialog.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PatientMedicationTableViewComponent } from './component/patient-medication-table-view/patient-medication-table-view.component';
import { MapCardComponent } from './component/map-card/map-card.component';
import { GeneralReportComponent } from './pages/general-report/general-report.component';
import { TciReportComponent } from './pages/tci-report/tci-report.component';
import { PcaReportComponent } from './pages/pca-report/pca-report.component';
import { StackBarandLinechartComponent } from './component/stack-barand-linechart/stack-barand-linechart.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { GeneralReportChartComponent } from './component/general-report-chart/general-report-chart.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { EventComponent } from './pages/event/event.component';
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { I18nService } from 'src/app/core/services/i18n-service.service';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    FilterComponent,
    BaseLayoutComponent,
    RoomCardViewComponent,
    RoomViewComponent,
    TimeViewComponent,
    MapViewComponent,
    TableViewComponent,
    PatientAssociationDialogComponent,
    RemainingTimeViewComponent,
    PatientDetailViewComponent,
    UserFeedbackDialogComponent,
    PumpDetailViewComponent,
    EventFeedComponent,
    PumpChartComponent,
    PumpLineChartComponent,
    EventFilterDialogComponent,
    PatientMedicationTableViewComponent,
    MapCardComponent,
    GeneralReportComponent,
    TciReportComponent,
    PcaReportComponent,
    StackBarandLinechartComponent,
    GeneralReportChartComponent,
    EventComponent,
  ],
  imports: [
    CommonModule,
    WardRoutingModule,
    MatSelectModule,
    MatFormFieldModule,
    MatIconModule,
    MatTabsModule,
    MatProgressBarModule,
    RouterModule,
    MatTableModule,
    MatDialogModule,
    PatientModule,
    MatMenuModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatSortModule,
    MatInputModule,
    MatButtonModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [
    TableViewComponent,
    PatientMedicationTableViewComponent,
    RoomCardViewComponent,
    UserFeedbackDialogComponent,
    PatientAssociationDialogComponent,
  ],
})
export class WardModule {
  constructor(
    private translate: TranslateService,
    private i18nService: I18nService
  ) {
    this.translate.addLangs(i18nService.getLangs());
    this.i18nService.locale.subscribe((lang) => {
      this.translate.setDefaultLang(lang);
    });
  }
}
