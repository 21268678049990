<div id="container">
  <div class="report-container">
    <div class="chart_heading_div d-flx-spc-btwn mb-3">
      <h1 class="mb-0" id="report-heading">
        {{ "HOME.ward-pages-PCA_Report-PCAReport" | translate }}
      </h1>
      <button id="back_btn" type="button" class="icon_btn hover-effect" (click)="goBack()">
        <img src="../../../../../assets/img/back.png" class="table_icon" alt="Back" />
      </button>
    </div>

    <div class="report-row1 row" id="report_row">
      <div class="report_detail col-lg-4">
        <table aria-label="PCA report data">
          <tr>
            <th>{{ "HOME.ward-pages-PCA_Report-Infuser" | translate }}</th>
            <td>{{ deviceObservation.infuser }}</td>
          </tr>
          <tr>
            <th>{{ "HOME.ward-pages-PCA_Report-Patient" | translate }}</th>
            <td>{{ deviceObservation.patient_name }}</td>
          </tr>
          <tr>
            <th>{{ "HOME.ward-pages-PCA_Report-Medication" | translate }}</th>
            <td>{{ deviceObservation.medication }}</td>
          </tr>
          <tr>
            <th>
              {{ "HOME.ward-pages-PCA_Report-Administration" | translate }}
            </th>
            <td>
              {{ deviceObservation.start_time }} -
              {{ deviceObservation.end_time }}
            </td>
          </tr>
        </table>
      </div>
      <div class="chart-component col-lg-8" id="chart_report">
        <div id="chart_div" class="chart_report">
          <app-stack-barand-linechart
            [data]="dataSource"
          ></app-stack-barand-linechart>
        </div>
      </div>
    </div>

    <div class="report-row2">
      <div class="map_table">
        <div>
          <div class="export_div">
            <p>{{ "HOME.ward-pages-PCA_Report-ExportTo" | translate }}:</p>
            <button
             id="excel_btn"
              type="button"
              class="custom_btn primary_btn mr-3"
              (click)="exportToExcel()"
            >
              {{ "HOME.ward-pages-PCA_Report-Excel" | translate }}
            </button>
            <button
              id="pdf_btn"
              type="button"
              class="custom_btn secondary_btn"
              (click)="exportToPdf()"
            >
              {{ "HOME.ward-pages-PCA_Report-Pdf" | translate }}
            </button>
          </div>
        </div>
        <table id="report_table" mat-table [dataSource]="dataSource" aria-label="PCA report data">
          <ng-container matColumnDef="Time">
            <th mat-header-cell *matHeaderCellDef>
              {{ "HOME.ward-pages-PCA_Report-Time" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.time_interval }}</td>
          </ng-container>

          <ng-container matColumnDef="Dose">
            <th mat-header-cell *matHeaderCellDef>
              {{ "HOME.ward-pages-PCA_Report-Dose" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.dose }}</td>
          </ng-container>
          <ng-container matColumnDef="PCALockoutTime">
            <th mat-header-cell *matHeaderCellDef>
              {{ "HOME.ward-pages-PCA_Report-PCA_Lockout_Time" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.lockout_time }}
            </td>
          </ng-container>
          <ng-container matColumnDef="PrescribedMax">
            <th mat-header-cell *matHeaderCellDef>
              {{ "HOME.ward-pages-PCA_Report-Prescribed_Max" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.prescribed_max }}
            </td>
          </ng-container>
          <ng-container matColumnDef="Continuous">
            <th mat-header-cell *matHeaderCellDef>
              {{ "HOME.ward-pages-PCA_Report-Continious" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.continuous }}</td>
          </ng-container>
          <ng-container matColumnDef="Loading">
            <th mat-header-cell *matHeaderCellDef>
              {{ "HOME.ward-pages-PCA_Report-Loading" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.loading }}</td>
          </ng-container>
          <ng-container matColumnDef="PCA">
            <th mat-header-cell *matHeaderCellDef>
              {{ "HOME.ward-pages-PCA_Report-PCA" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.pca }}</td>
          </ng-container>
          <ng-container matColumnDef="RequestedNotDelivered">
            <th mat-header-cell *matHeaderCellDef>
              {{
                "HOME.ward-pages-PCA_Report-Requested_Not_Delivered" | translate
              }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.requested_not_delivered }}
            </td>
          </ng-container>
          <ng-container matColumnDef="TotalAdministered">
            <th mat-header-cell *matHeaderCellDef>
              {{ "HOME.ward-pages-PCA_Report-Total_Administered" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.total_administered }}
            </td>
          </ng-container>
          <ng-container matColumnDef="VTBI">
            <th mat-header-cell *matHeaderCellDef>
              {{ "HOME.ward-pages-PCA_Report-VTBI" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.volume_remain }}</td>
          </ng-container>

          <ng-container matColumnDef="Event">
            <th mat-header-cell *matHeaderCellDef>
              {{ "HOME.ward-pages-PCA_Report-Event" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.alarm }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
  </div>
</div>
