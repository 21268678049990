<div class="map_table" id="remaining_time_view_table">
   <table mat-table [dataSource]="dataSource" matSort aria-label="Patient Order data">
      <ng-container matColumnDef="room">
         <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'HOME.ward-component-Remaining_Time_View-Room' |
            translate}} </th>
         <td mat-cell *matCellDef="let element"> {{element.room + "-" + element.bed}} </td>
      </ng-container>
      <ng-container matColumnDef="patient_name">
         <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'HOME.ward-component-Remaining_Time_View-Patient' |
            translate}} </th>
         <td mat-cell *matCellDef="let element" class="min-wdt_150"> {{element.patient_name}} </td>
      </ng-container>
      <ng-container matColumnDef="drug_type">
         <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'HOME.ward-component-Remaining_Time_View-Type' |
            translate}} </th>
         <td mat-cell *matCellDef="let element"> {{element.drug_type}} </td>
      </ng-container>
      <ng-container matColumnDef="drug_class">
         <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'HOME.ward-component-Remaining_Time_View-Class' | translate}} </th>
         <td mat-cell *matCellDef="let element"> {{element.drug_class}} </td>
      </ng-container>
      <ng-container matColumnDef="medication">
         <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'HOME.ward-component-Remaining_Time_View-Medication' |
            translate}}</th>
         <td mat-cell *matCellDef="let element" class="min-wdt_200"> {{element.medication}} </td>
      </ng-container>
      <ng-container matColumnDef="concentration">
         <th mat-header-cell *matHeaderCellDef>
            {{'HOME.ward-component-Remaining_Time_View-Concentration' | translate}}</th>
         <td mat-cell *matCellDef="let element"> {{element.concentration}} </td>
      </ng-container>
      <ng-container matColumnDef="percentage_infused">
         <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'HOME.ward-component-Remaining_Time_View-%Remaining' |
            translate}}</th>
         <td mat-cell *matCellDef="let element" class="text-end"> {{element.percentage_infused}} </td>
      </ng-container>
      <ng-container matColumnDef="time_remaining">
         <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'HOME.ward-component-Remaining_Time_View-Remaining' |
            translate}} </th>
         <td mat-cell *matCellDef="let element">
            <mat-progress-bar mode="determinate" [value]='element.percentage_infused'></mat-progress-bar>
            <div class="bar_value">
               {{element.time_remaining}}
            </div>
         </td>
      </ng-container>
      <ng-container matColumnDef="rate">
         <th mat-header-cell *matHeaderCellDef> {{'HOME.ward-component-Remaining_Time_View-Rate' |
            translate}} </th>
         <td mat-cell *matCellDef="let element" class="text-end"> {{element.rate}} </td>
      </ng-container>
      <ng-container matColumnDef="volume_tbi">
         <th mat-header-cell *matHeaderCellDef> {{'HOME.ward-component-Remaining_Time_View-VTBI' |
            translate}} </th>
         <td mat-cell *matCellDef="let element" class="text-end"> {{element.volume_tbi}} </td>
      </ng-container>
      <ng-container matColumnDef="volume_delivered">
         <th mat-header-cell *matHeaderCellDef> {{'HOME.ward-component-Remaining_Time_View-VI' |
            translate}} </th>
         <td mat-cell *matCellDef="let element" class="text-end"> {{element.volume_delivered}} </td>
      </ng-container>
      <ng-container matColumnDef="pump">
         <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'HOME.ward-component-Remaining_Time_View-Pump' |
            translate}} </th>
         <td mat-cell *matCellDef="let element" class="min-wdt_200">
            <div *ngIf="!!element.pump" class="table_icon_div">
               <img id="report_icon" src="../../../../../assets/img/chart_svg.svg" class="table_icon me-1" alt="Chart"
                  (click)="openReportPage(element)"> {{element.pump}}
               <img id="info_icon" src="../../../../../assets/img/alert.svg" class="table_icon ms-1" alt="Alert"
               (click)="openDetail(element)">
            </div>
         </td>
      </ng-container>
      <ng-container matColumnDef="message">
         <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'HOME.ward-component-Remaining_Time_View-Message' | translate}} </th>
         <td mat-cell *matCellDef="let element" class="min-wdt_150"> {{element.message}}
            <img *ngIf="element.alarm===null && !!element.stale_msg"
               src="../../../../../assets/img/hospital_bottle_white.svg" class="table_icon ms-2" alt="Delayed Data">
            <img *ngIf="element.alarm !==null" src="../../../../../assets/img/white_alert.svg" class="table_icon ms-2" alt="Alert">
         </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true;"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
         [ngClass]="getRowClassString(row.alarm, row.stale_msg)" class="cursor-pointer"
         (click)="openPatientDetail(row.id)"></tr>

      <tr class="mat-row" *matNoDataRow>
         <td class="mat-cell" colspan="9999">
            {{'HOME.ward-component-Remaining_Time_View-NoDataAvailable' | translate}} </td>
      </tr>
   </table>
</div>
