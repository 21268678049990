<h2 mat-dialog-title>{{data.title}} <mat-icon class="close_icon" [mat-dialog-close]="false">close</mat-icon>
</h2>
<div class="confirmation">
  <h4>{{data.message}}</h4>

  <div *ngIf="data.patient_id != 0" class="mb-3">
    <div class="col-12" *ngFor="let option of device_list">
      <div class="col-5">
        <mat-checkbox [(ngModel)]="option.checked" class="mb-1 label-field-text-smaller" style="color: aliceblue">
          {{option.display_text}}
        </mat-checkbox>
      </div>
    </div>
  </div>

  <div>
    <button id="yes_confirmation" type="button" class="custom_btn success_btn mr-3"
      (click)="dissociateDeviceFromPatient()"
      [mat-dialog-close]="true">{{'HOME.ward-component-User_Feedback_Dialog-Yes' | translate}}</button>
    <button id="no_confirmation" type="button" class="custom_btn secondary_btn"
      [mat-dialog-close]="false">{{'HOME.ward-component-User_Feedback_Dialog-No' | translate}}</button>
  </div>

</div>