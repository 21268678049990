import { Component, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { EventFilterDialogComponent } from '../event-filter-dialog/event-filter-dialog.component';
import { AlarmService } from 'src/app/core/services/alarm.service';
import { ExportService } from '../../../../core/services/export.service';

import { DeviceService } from 'src/app/core/services/device.service';
import { Selected_Device_For_Pump_Detail_Page_Key } from '../remaining-time-view/remaining-time-view.component';

@Component({
  selector: 'app-event-feed',
  templateUrl: './event-feed.component.html',
  styleUrls: ['./event-feed.component.css'],
})
export class EventFeedComponent implements OnInit {
  alarmList: any[];
  private _alarmListStore: any[];
  devicePatient: any;
  deviceEvents: any;
  private _eventFilter: [];
  private _dateFilter: { start: Date; end: Date };
  private _dateFilterUpdated: { start: Date; end: Date };
  private _deviceId;
  constructor(
    private dialog: MatDialog,
    private _alarmService: AlarmService,
    private exportService: ExportService,
    private _deviceService: DeviceService
  ) {
    // This is intentionally empty
  }

  eventFilterDialogRef: MatDialogRef<EventFilterDialogComponent>;

  ngOnInit(): void {
    const reqData = JSON.parse(
      localStorage.getItem(Selected_Device_For_Pump_Detail_Page_Key)
    );
    this._deviceId = reqData.device_id;
    this.loadAlarmData();
    this._deviceService
      .loadDevicePatients(reqData.device_id)
      .subscribe((data: any) => {
        this.devicePatient = data.response.devicePatients;
      });

    this._deviceService.fetchDeviceEvent().subscribe((res) => {
      res.response.deviceEvents.forEach((event) => {
        event.selected = false;
      });
      this.deviceEvents = res.response.deviceEvents;
    });
  }

  openEventFilterDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      title: 'Angular For Beginners',
    };

    this.eventFilterDialogRef = this.dialog.open(EventFilterDialogComponent, {
      data: { event: this.deviceEvents, date: this._dateFilter },
    });

    this.eventFilterDialogRef.afterClosed().subscribe((data) => {
      if (data.isChanged) {
        this._eventFilter = data.filter;
        let start = this._addDay(data.date.start);
        let end = this._addDay(data.date.end);
        this._dateFilterUpdated = { start: start, end: end };
        this._dateFilter = { start: data.date.start, end: data.date.end };
        this.deviceEvents.forEach((event) => {
          const selected = data.filter.find(
            (val) =>
              val.event_type === event.event_type &&
              val.event_sub_type === event.event_sub_type
          );
          event.selected = !!selected;
        });

        this.loadAlarmData();
      }
    });
  }

  loadAlarmData() {
    this._alarmService
      .loadAlarmList({
        device_id: this._deviceId,
        filter: { date: this._dateFilterUpdated, event: this._eventFilter },
      })
      .subscribe(
        (data: any) => {
          this._alarmListStore = data.response.alarms;
          this.alarmList = this._alarmListStore;
          localStorage.setItem(
            'alarmListCount',
            this._alarmListStore.length.toString()
          );
        },
        (err) => {
          console.log('something wrong occured', err);
        }
      );
  }

  exportToPdf(elementId: string, filename: string) {
    this.exportService.exportToPdf(elementId, filename);
  }

  exportToExcel(elementId: string, filename: string) {
    this.exportService.exportToExcel(elementId, filename);
  }
  exportToCsv(elementId: string, filename: string) {
    this.exportService.exportToCSV(elementId, filename);
  }
  exportToWord(elementId: string, filename: string) {
    this.exportService.exportToWord(elementId, filename);
  }
  exportEventsToExcel() {
    this._alarmListStore.forEach((data, index) => {
      this.exportService.exportToExcel(
        `event_table-${index}`,
        `event-${index + 1}`
      );
    });
  }

  filterAlarmList(text: string) {
    let filteredAlarmLsit = [];
    this._alarmListStore.forEach((val) => {
      let el = {
        date: val.date,
        observations: [],
      };
      el.observations = val.observations.filter((val1) =>
        (val1.event_sub_type + val1.event_desc + val1.event_type)
          .toLowerCase()
          .includes(text.toLowerCase())
      );
      filteredAlarmLsit.push(el);
    });
    this.alarmList = filteredAlarmLsit;
  }

  private _addDay(dateInput) {
    let date = new Date(dateInput);
    date.setDate(date.getDate() + 1);
    return date;
  }
}
