<div class="pump_detail_sec" id="pump_Detail">
    <div class="row">
       <div class="col-lg-3 col-sm-12 col-md-12" id="event_feeds">
         <app-event-feed></app-event-feed>
       </div>
       <div class="col-lg-9 col-sm-12 col-md-12 mt_20" id="pump_charts">
         <app-pump-chart></app-pump-chart>
       </div>
    </div>
 </div>

