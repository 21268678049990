import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, interval, Subscription } from 'rxjs';
import { SettingService } from './setting.service';
import { UserActivityService } from './user-activity.service';
declare const $: any;
const DEFAULT_ROTATION_TIME = 0;
const DEFAULT_REFRESH_TIME = 60;

@Injectable({
  providedIn: 'root',
})
export class RotationService {
  constructor(
    private _router: Router,
    private settingServie: SettingService,
    private _userActivityService: UserActivityService
  ) {
    this._userActivityService.isUserInactive.subscribe((data) => {
      this._userActivityObserver(data);
    });

    this.setRefreshDataEventEmitter();
  }
  private rotationTime: number = DEFAULT_ROTATION_TIME * 1000;
  private rotationSubscription;
  rotateTimeRemainingSubscription: Subscription;
  rotationTimeRemaining: number = 0;
  perRotationTimeRemaining: number = 0;

  //Data refresh timer
  refreshTimeRemaining = 0;
  private refreshTime: number = DEFAULT_REFRESH_TIME;
  refreshSubscription: Subscription;
  private _refreshData = new BehaviorSubject(0);
  refreshDataObs = this._refreshData.asObservable();
  refreshTimeRemainingSubscription: Subscription;
  /**
   * setRotationTime
   * @param time {number} Page rotation time in sec
   */
  public updateRotationTime(time: number) {
    if (isNaN(time)) {
      time = 0;
    }
    this.rotationTime = time * 1000;
    this._setPageRotation();
  }
  /**
   * _setPageRotation
   */
  private _setPageRotation() {
    if (!this.rotationSubscription) {
      if (this.rotationTime == 0) {
        this._removePageRotation();
      } else {
        this.rotationSubscription = setInterval(() => {
          this._rotatePage();
        }, this.rotationTime);
        if (!!this.rotateTimeRemainingSubscription)
          this.rotateTimeRemainingSubscription.unsubscribe();
        this.rotationTimeRemaining = this.getCurrentRotationTime();
        this.perRotationTimeRemaining = 100;
        this.rotateTimeRemainingSubscription = interval(10).subscribe(
          (data) => {
            this.rotationTimeRemaining = this.rotationTimeRemaining - 0.01;
            this.perRotationTimeRemaining =
              (this.rotationTimeRemaining / this.getCurrentRotationTime()) *
              100;
          }
        );
      }
      return;
    }

    this._removePageRotation();
    this._setPageRotation();
  }

  /**
   * _removePageRotation
   */
  private _removePageRotation() {
    clearInterval(this.rotationSubscription);

    //need to make null or undefined otherwise `_setPageRotation` recursive call not stopped
    this.rotationSubscription = undefined;
    this.rotationTimeRemaining = this.getCurrentRotationTime();
    this.perRotationTimeRemaining = 100;
    if (!!this.rotateTimeRemainingSubscription)
      this.rotateTimeRemainingSubscription.unsubscribe();
  }

  /**
   * Navigate between the pages selected for rotation
   */
  private _rotatePage() {
    const WardPageURL = '/ward';
    const AlertPageURL = '/alert';
    if (this._router.url === WardPageURL) {
      this.rotationTimeRemaining = this.getCurrentRotationTime();
      this.perRotationTimeRemaining = 100;

      this._router.navigate([AlertPageURL]);
    }

    if (this._router.url === AlertPageURL) {
      this.rotationTimeRemaining = this.getCurrentRotationTime();
      this.perRotationTimeRemaining = 100;

      this._router.navigate([WardPageURL]);
    }
  }

  /**
   * getCurrentRotationTime
   */
  public getCurrentRotationTime() {
    return this.rotationTime / 1000;
  }

  private _userActivityObserver(isInactive: boolean) {
    if (isInactive) {
      this._setPageRotation();
      return;
    }

    this._removePageRotation();
  }

  refreshDataEvent() {
    this._refreshData.next(0);

    this.setRefreshDataEventEmitter();
  }

  setRefreshDataEventEmitter() {
    this.settingServie.getAppSetting().subscribe((data) => {
      this.refreshTime = parseInt(data?.refreshInterval) * 1000;
      if (!!this.refreshSubscription) this.refreshSubscription.unsubscribe();
      this.refreshSubscription = interval(
        isNaN(this.refreshTime) ? DEFAULT_REFRESH_TIME * 1000 : this.refreshTime
      ).subscribe((data1) => {
        this._refreshData.next(data1);
        this.refreshTimeRemaining = 0;
      });

      if (!!this.refreshTimeRemainingSubscription) {
        this.refreshTimeRemainingSubscription.unsubscribe();
      }
      this.refreshTimeRemaining = 0;
      this.refreshTimeRemainingSubscription = interval(1000).subscribe(
        (data1) => (this.refreshTimeRemaining = this.refreshTimeRemaining + 1)
      );
    });
  }

  clearRefreshDataEventEmitter() {
    //empty
  }
}
