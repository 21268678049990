import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { PatientService } from 'src/app/core/services/patient.service';

@Component({
  selector: 'app-patient-editor',
  templateUrl: './patient-editor.component.html',
  styleUrls: ['./patient-editor.component.css'],
})
export class PatientEditorComponent implements OnInit {
  constructor(
    private _snackBar: MatSnackBar,
    public patientService: PatientService,
    private langService: TranslateService
  ) {
    this.langService
      .get([
        'HOME.patient-component-Patient_Detail_Form-Please_Enter_valid_Values',
        'HOME.medication-component-Medication_General_Detail-close',
      ])
      .subscribe((ln) => {
        this.Please_Enter_valid_Values =
          ln[
            'HOME.patient-component-Patient_Detail_Form-Please_Enter_valid_Values'
          ];
        this.close =
          ln['HOME.medication-component-Medication_General_Detail-close'];
      });
  }

  //alerts message string from lang file
  Please_Enter_valid_Values;
  close;

  ngOnInit(): void {
    if (this.patientService.currentOp == 'Add') {
      document
        .getElementById('editFormPatient')
        .classList.add('patientEditorFlag');
    }
  }

  OnFormData(e) {
    if (
      e.controls.first_name.errors ||
      e.controls.last_name.errors ||
      e.controls.middle_name.errors ||
      e.controls.dob.errors ||
      e.controls.age.errors ||
      e.controls.height.errors ||
      e.controls.weight.errors
    ) {
      this._snackBar.open(this.Please_Enter_valid_Values);
      this.isSaved = false;
      return;
    }
    let reqData = e.value;
    reqData.ids = e.ids;
    reqData.id = e.id;
    this.patientService.savePatientDetail(e.value).subscribe((res) => {
      let message = '';

      if (res.success) {
        message = res.successMsg;
      } else {
        message = res.errorMsg;
      }

      this._snackBar.open(message);
    });
  }

  isSaved = false;

  savePatientDetail() {
    this.isSaved = true;
  }

  goBack() {
    window.history.back();
  }
}
