import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { IResponse } from '../models/HttpRequest';
import { IPatient } from '../models/Patient';
import { IReference } from '../models/service';
import { RotationService } from './rotation.service';

@Injectable({
  providedIn: 'root',
})
export class ReferenceService {
  constructor(
    private _http: HttpClient,
    private _rotationService: RotationService
  ) {
    this._loadReferenceList();

    //refresh data listener
    this._rotationService.refreshDataObs.subscribe((data) => {
      this._loadReferenceList();
    });
  }

 




  
  private _referenceList: BehaviorSubject<IReference[]> = new BehaviorSubject<
    IReference[]
  >([]);

  readonly referenceListObs = this._referenceList.asObservable();

  private _loadReferenceList() {
    this._http.get('/api/v1/reference/getReference').subscribe(
        
      (data: IResponse<any>) => {

        console.log("data",data )

          
        this._referenceList.next(data.response.devices);
      },
      (err) => {
        console.log('some error occured.');
      }
    );
  }

}