import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DeviceService } from 'src/app/core/services/device.service';

import { ExportService } from '../../../../core/services/export.service';
import { Selected_Device_For_Pump_Detail_Page_Key } from '../remaining-time-view/remaining-time-view.component';
import { AlarmService } from 'src/app/core/services/alarm.service';

@Component({
  selector: 'app-pump-chart',
  templateUrl: './pump-chart.component.html',
  styleUrls: ['./pump-chart.component.css'],
})
export class PumpChartComponent implements OnInit {
  constructor(
    private _location: Location,
    private _deviceService: DeviceService,
    private exportService: ExportService,
    private _alarmService: AlarmService
  ) {
    // This is intentionally empty
  }

  dataSource: any[] = [];
  deviceObservation: any = {};
  devicePatient: any;
  devicePressureData: any[] = [];

  ngOnInit(): void {
    this._loadDeviceObservation();
    this._loadDevicePatient();
    this._loadDevicePressure();
  }

  _loadDeviceObservation() {
    const patientData = JSON.parse(
      localStorage.getItem(Selected_Device_For_Pump_Detail_Page_Key)
    );
    const reqData = {
      patientId: patientData.id,
      deviceId: patientData.device_id,
      medicationId: patientData.medication_id,
    };

    this._deviceService.getDeviceObservations(reqData).subscribe((data) => {
      this.deviceObservation = data;
      this.dataSource = data?.observations;
    });
  }

  _loadDevicePatient() {
    const reqData = JSON.parse(
      localStorage.getItem(Selected_Device_For_Pump_Detail_Page_Key)
    );
    this._deviceService
      .loadDevicePatients(reqData.device_id)
      .subscribe((data: any) => {
        this.devicePatient = data.response.devicePatients;
      });
  }

  _loadDevicePressure() {
    const reqData = JSON.parse(
      localStorage.getItem(Selected_Device_For_Pump_Detail_Page_Key)
    );
    this._deviceService
      .loadDevicePressure(reqData.device_id)
      .subscribe((data: any) => {
        this.devicePressureData = data.response.devicePressures;
      });
  }

  goBack() {
    this._location.back();
  }

  exportToPdf(elementId: string, filename: string) {
    this.exportService.exportToPdf(elementId, filename);
  }

  exportVolumeToExcel() {
    this.createTable(this.dataSource, 'exportVolumeDeliveredTable');

    this.exportService.exportToExcel(
      'exportVolumeDeliveredTable',
      'volume-delivered'
    );
  }
  exportDoseRateToExcel() {
    this.createTable(this.dataSource, 'exportDoseRateTable');

    this.exportService.exportToExcel('exportDoseRateTable', 'dose-rate');
  }
  exportDevicePressureToExcel() {
    this.createTable(this.devicePressureData, 'exportDevicePressureTable');

    this.exportService.exportToExcel(
      'exportDevicePressureTable',
      'device-pressure'
    );
  }

  exportEventsToExcel() {
    let alarmListCount = Number(localStorage.getItem('alarmListCount'));

    for (let index = 0; index < alarmListCount; index++) {
      this.exportService.exportToExcel(
        `event_table-${index}`,
        `event-${index + 1}`
      );
    }
  }

  private createTable(tableData, id) {
    let table = document.createElement('table');
    let tableBody = document.createElement('tbody');
    let hrow = document.createElement('tr');

    for (const key in tableData[0]) {
      let cell = document.createElement('th');
      cell.appendChild(document.createTextNode(key));
      hrow.appendChild(cell);
    }
    tableBody.appendChild(hrow);

    tableData.forEach(function (rowData) {
      let row = document.createElement('tr');

      for (const key in rowData) {
        let cell = document.createElement('td');
        cell.appendChild(document.createTextNode(rowData[key]));
        row.appendChild(cell);
      }

      tableBody.appendChild(row);
    });

    table.appendChild(tableBody);
    table.setAttribute('id', id);
    table.style.display = 'none';
    document.body.appendChild(table);
  }
}
