<div class="current-header mb-2 d-flx-spc-btwn mb-d-inline">
   <div class="w-50">
      <div class="search_table">
         <div class="input-group">
            <span class="input-group-text" id="basic-addon1">
               <img src="../../../../../assets/img/search_icon.png" class="table_icon" alt="Search" /> </span>
            <input id="order_search_input" type="text" class="form-control" placeholder="{{'GLOBAL.Search_Placeholder' | translate}}"
               [formControl]="searchControl">
         </div>
      </div>
   </div>
</div>

<div class="map_table tab_view_table">
   <table mat-table [dataSource]="dataSource" matSort aria-label="Patient Order data" (matSortChange)="onSort($event)" id="patient_order_data_table">
      <ng-container matColumnDef="drug_type">
         <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'HOME.patient-component-Order_Tab_View-Type' |
            translate}} </th>
         <td mat-cell *matCellDef="let element"> {{element.drug_type}} </td>
      </ng-container>

      <ng-container matColumnDef="drug_name">
         <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'HOME.patient-component-Order_Tab_View-Medication' |
            translate}} </th>
         <td mat-cell *matCellDef="let element"> {{element.drug_name}} </td>
      </ng-container>

      <ng-container matColumnDef="concentration">
         <th mat-header-cell *matHeaderCellDef> {{'HOME.patient-component-Order_Tab_View-Concentration' | translate}}
         </th>
         <td mat-cell *matCellDef="let element" class="text-end"> {{element.concentration}} </td>
      </ng-container>

      <ng-container matColumnDef="total_volume">
         <th mat-header-cell *matHeaderCellDef> {{'HOME.patient-component-Order_Tab_View-TotalVolume' | translate}}
         </th>
         <td mat-cell *matCellDef="let element" class="text-end">
            {{element.total_volume}}
         </td>
      </ng-container>
      <ng-container matColumnDef="dose_rate">
         <th mat-header-cell *matHeaderCellDef> {{'HOME.patient-component-Order_Tab_View-DoseRate' | translate}} </th>
         <td mat-cell *matCellDef="let element" class="text-end"> {{element.dose_rate}} </td>
      </ng-container>
      <ng-container matColumnDef="volume_tbi">
         <th mat-header-cell *matHeaderCellDef> {{'HOME.patient-component-Order_Tab_View-VTBI' | translate}} </th>
         <td mat-cell *matCellDef="let element" class="text-end"> {{element.volume_tbi}} </td>
      </ng-container>
      <ng-container matColumnDef="time">
         <th mat-header-cell *matHeaderCellDef> {{'HOME.patient-component-Order_Tab_View-Time' | translate}} </th>
         <td mat-cell *matCellDef="let element" class="text-end"> {{element.time}} </td>
      </ng-container>
      <ng-container matColumnDef="order_Date">
         <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'HOME.patient-component-Order_Tab_View-OrderDate' |
            translate}} </th>
         <td mat-cell *matCellDef="let element"> {{element.order_Date}} </td>
      </ng-container>
      <ng-container matColumnDef="admin_date">
         <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'HOME.patient-component-Order_Tab_View-AdminDate' |
            translate}} </th>
         <td mat-cell *matCellDef="let element"> {{element.admin_date}} </td>
      </ng-container>
      <ng-container matColumnDef="status">
         <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'HOME.medication-component-Medication_Order_Detail-Status' | translate}} </th>
         <td mat-cell *matCellDef="let element"> {{element.status}} </td>
      </ng-container>
      <ng-container matColumnDef="action">
         <th mat-header-cell *matHeaderCellDef> {{'HOME.patient-component-Order_Tab_View-Action' | translate}} </th>
         <td mat-cell *matCellDef="let element">
            <div class="table_icon_div min-wdt_200">
               <ng-container *ngIf="!!element.pump">
                  <img id="report_detail_btn" src="../../../../../assets/img/chart_svg.svg" class="table_icon me-1" alt="Chart"
                     (click)="openReportPage(element)"> {{element.pump}}
                  <img id="open_detail_form" src="../../../../../assets/img/alert.svg" class="table_icon ms-1" alt="Alert"
                     (click)="openDetail(element)">
               </ng-container>
               <img *ngIf="element.status!='COMPLETE' && element.alarm" src="../../../../../assets/img/red_alert.svg"
                  class="table_icon" alt="Alert">
               <img *ngIf="!element.alarm && element.status!='COMPLETE' && element.stale_msg"
                  src="../../../../../assets/img/hospital_bottle.svg" class="table_icon" alt="Delayed Data">
               <img *ngIf="element.status=='COMPLETE'" src="../../../../../assets/img/tick_green.svg" class="table_icon"
                  alt="Complete">
            </div>
         </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
   </table>
</div>
<div class="custom_pagination">
   <mat-paginator [length]="totalCount" [pageSizeOptions]="[20, 30, 50, 100]" aria-label="Select page"
      (page)="onPageChange($event)">
   </mat-paginator>
</div>