import { Component, Inject, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface Event {
  type: string;
  completed: boolean;
  color: ThemePalette;
  subtype?: Event[];
}

@Component({
  selector: 'app-event-filter-dialog',
  templateUrl: './event-filter-dialog.component.html',
  styleUrls: ['./event-filter-dialog.component.css'],
})
export class EventFilterDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<EventFilterDialogComponent>
  ) {
    this._initEventTree(data.event);
    this.startDate = data.date?.start;
    this.endDate = data.date?.end;
  }

  startDate: Date;
  endDate: Date;

  ngOnInit(): void {
    // This is intentionally empty
  }

  eventList: Event[] = [
    {
      type: 'ALERTS',
      completed: false,
      color: 'primary',
      subtype: [],
    },
    {
      type: 'EVENTS',
      completed: false,
      color: 'primary',
      subtype: [],
    },
  ];
  allComplete: boolean = false;

  updateAllComplete() {
    for (let event of this.eventList) {
      const isComplete =
        event.subtype.length !== 0 &&
        event.subtype.every((t) => t.completed);
      if (!isComplete) {
        this.allComplete = false;
        return;
      }
      this.allComplete = true;
    }
  }

  someComplete(): boolean {
    if (this.allComplete) {
      return false;
    }

    if(this.eventList.length > 0){
      const event = this.eventList[0];
      return event.subtype.filter((t) => t.completed).length > 0;
    }

    return false;
  }

  setAll(completed: boolean) {
    this.allComplete = completed;

    for (let event of this.eventList) {
      event.completed = completed;
      event.subtype.forEach((el) => {
        el.completed = completed;
      });
    }
  }

  private _initEventTree(eventList: any) {
    let allSelected = true;
    eventList.forEach((event) => {
      if (event.event_type === 'ALERTS') {
        this.eventList[0].subtype.push({
          type: event.event_sub_type,
          completed: event.selected,
          color: 'primary',
          subtype: [],
        });
      }
      if (event.event_type === 'EVENTS') {
        this.eventList[1].subtype.push({
          type: event.event_sub_type,
          completed: event.selected,
          color: 'primary',
          subtype: [],
        });
      }

      if (!event.selected) {
        allSelected = false;
      }
    });
    this.allComplete = allSelected;
  }

  public clearAllSelection() {
    this.setAll(false);
    this.startDate = undefined;
    this.endDate = undefined;
    this.dialogRef.close({
      isChanged: true,
      filter: [],
      date: { start: this.startDate, end: this.endDate },
    });
  }

  applyFilter() {
    let filter = [];
    this.eventList.forEach((event) => {
      event.subtype.forEach((subEvent) => {
        if (subEvent.completed) {
          filter.push({
            event_type: event.type,
            event_sub_type: subEvent.type,
          });
        }
      });
    });
    this.dialogRef.close({
      isChanged: true,
      filter: filter,
      date: { start: this.startDate, end: this.endDate },
    });
  }
}
