import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-access-denied-page',
  templateUrl: './access-denied-page.component.html',
  styleUrls: ['./access-denied-page.component.css']
})
export class AccessDeniedPageComponent implements OnInit {

  constructor() {
    // This is intentionally empty
  }

  ngOnInit(): void {
    // This is intentionally empty
  }

}
