import { Component, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { IHierarchy } from 'src/app/core/models/Hierarchy';
import { EnterpriseService } from 'src/app/core/services/enterprise.service';

@Component({
  selector: 'app-hierarchy-filter',
  templateUrl: './hierarchy-filter.component.html',
  styleUrls: ['./hierarchy-filter.component.css'],
})
export class HierarchyFilterComponent implements OnInit {
  constructor(public hierarchyService: EnterpriseService) {
    // This is intentionally empty
  }

  enterprises: IHierarchy[] = [];
  wards: IHierarchy[] = [];
  selectedEnterprise: any = {};
  selectedWard: any = {};

  ngOnInit(): void {
    this._init();
  }

  private _init() {
    this.hierarchyService.enterpriseHierarchy.subscribe((data) => {
      if (data) {
        this.enterprises = data;
      }
    });

    this.hierarchyService.childHierarchies.subscribe((data) => {
      if (data) {
        this.wards = data;
      }
    });

    this.hierarchyService.selectedHospital.subscribe((data) => {
      if (data) {
        this.selectedEnterprise = data;
      }
    });

    this.hierarchyService.selectedWard.subscribe((data) => {
      if (data) {
        this.selectedWard = data;
      }
    });
  }

  onHospitalChange(e: MatSelectChange) {
    this.selectedEnterprise = e.value;
    this.hierarchyService.updateHospitalSelection(e.value);
  }

  onWardChange(e: MatSelectChange) {
    this.selectedWard = e.value;
    this.hierarchyService.updateWardSelection(e.value);
  }
}
