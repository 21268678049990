import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, ObservableInput, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse && error.status === 401 && !error.url.includes("user/authenticate")) {
          console.log(error);
          //handle 401 error response
          return this._handle401Error(request, next);
        }
        console.log('HTTP Error Intercepted.');

        return throwError(error);
      })
    );
  }

  private _handle401Error(
    request: HttpRequest<any>,
    next: HttpHandler
  ): ObservableInput<any> {
    if (!this.authService.isRefreshing()) {
      this.authService.getNewToken();
      return this.authService.refreshTokenObs.pipe(
        switchMap((token: any) => {
          const headers = new HttpHeaders({
            'Authorization': `Bearer ${token}`
          });
          return next.handle(request.clone({headers}));
        })
      );
    }
    return this.authService.refreshTokenObs.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => {
        const headers = new HttpHeaders({
          'Authorization': `Bearer ${token}`
        });
        return next.handle(request.clone({headers}));
      })
    );
  }
}
