import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {  map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SettingService {
  constructor( private _http: HttpClient,) {
    // This is intentionally empty
  }

  setAppSetting(data: any) {
    return this._http
      .post('/api/v1/setting/saveSetting', data)
      .pipe<any>(map<any, any>((response) => response.response));

  }

  getAppSetting() {
    return this._http
    .get('/api/v1/setting/getSetting')
    .pipe<any>(map<any, any>((response) => response.response));

  }
}
