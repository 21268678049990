import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { IHierarchy } from '../models/Hierarchy';
import { IResponse } from '../models/HttpRequest';
import { EnterpriseService } from './enterprise.service';
import { FilterService } from './filter.service';
import { RotationService } from './rotation.service';

@Injectable({
  providedIn: 'root',
})
export class AlarmService {
  private _sortBy: string;
  constructor(
    private _http: HttpClient,
    private _enterpriseService: EnterpriseService,
    private _filterService: FilterService,
    private _rotationService: RotationService
  ) {
    //Hierarchy filter listeners
    this._setHierarchyFilterListner();

    //Listen for search text changes and fetch data accordingly
    this._setSearchListnerForAlarmList();

    this._setSortCriteriaListner();

    //refresh data listener
    this._rotationService.refreshDataObs.subscribe((data) => {
      this._loadpatientAlarmList();
    });
  }

  private _patientAlarmList: BehaviorSubject<any> = new BehaviorSubject<any>(
    {}
  );
  private _patientFilteredAlarmList: BehaviorSubject<any> =
    new BehaviorSubject<any>({});
  public patientAlarmList = this._patientAlarmList.asObservable();
  public patientFilteredAlarmList =
    this._patientFilteredAlarmList.asObservable();
  private _hierarchyFilter: any = {};

  private _setHierarchyFilterListner() {
    this._enterpriseService.selectedHospital.subscribe((val) => {
      this._setHospitalFilter(val);
    });
    this._enterpriseService.selectedWard.subscribe((val) => {
      this._setWardFilter(val);
    });
  }

  private _setHospitalFilter(selection: IHierarchy) {
    if (!!selection) {
      if (
        (!!this._hierarchyFilter['hospital'] &&
          selection.id !== this._hierarchyFilter['hospital'].id) ||
        !this._hierarchyFilter['hospital']
      ) {
        this._hierarchyFilter['hospital'] = {
          id: selection.id,
          display_text: selection.display_text,
        };
      }
    }
  }

  private _setWardFilter(selection: IHierarchy) {
    if (!!selection) {
      if (
        (!!this._hierarchyFilter['ward'] &&
          selection.id !== this._hierarchyFilter['ward'].id) ||
        !this._hierarchyFilter['ward']
      ) {
        this._hierarchyFilter['ward'] = {
          id: selection.id,
          display_text: selection.identifier,
        };

        //fetch the data after setting the filter
        this._loadpatientAlarmList();
      }
    }
  }

  private _setSearchListnerForAlarmList() {
    combineLatest([this._filterService.searchedValueObs, this.patientAlarmList])
      .pipe(
        map((combinedResult) => {
          const searchedText = combinedResult[0].toLowerCase().trim();
          const patientAlarmList = !!combinedResult[1].patientAlarms
            ? combinedResult[1].patientAlarms
            : [];

          const filteredValue = patientAlarmList.filter((val: any) =>
            (val.room + '-' + val.bed + ': ' + val.patient_name)
              .toLowerCase()
              .includes(searchedText)
          );
          return { patientAlarms: filteredValue };
        })
      )
      .subscribe((data: any) => {
        this._patientFilteredAlarmList.next(data);
      });
  }

  private _loadpatientAlarmList() {
    if(Object.keys(this._hierarchyFilter).length === 0){
      return;
    }
    this._http
      .post('/api/v1/alarm/listPatientAlarms', {
        filter: this._hierarchyFilter,
        sortBy: this._sortBy,
      })
      .subscribe(
        (data: IResponse<any>) => {
          this._patientAlarmList.next(data.response);
        },
        (err) => {
          console.log('some error occured.');
        }
      );
  }

  getSinglePatientAlert(id) {
    return this._http.post(`/api/v1/alarm/singlePatientAlarms`, id).pipe(
      map((data: IResponse<any>) => {
        return data.response;
      })
    );
  }

  private _setSortCriteriaListner() {
    this._filterService.sortByObs.subscribe((criteria) => {
      this._sortBy = criteria;

      this._loadpatientAlarmList();
    });
  }

  public loadAlarmList(requestBody: any) {
    return this._http.post('/api/v1/alarm/listalarms', requestBody);
  }
}
