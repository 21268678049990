import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IHierarchy } from 'src/app/core/models/Hierarchy';
import { AlarmService } from 'src/app/core/services/alarm.service';
import { EnterpriseService } from 'src/app/core/services/enterprise.service';
import {
  MapViewService,
  ViewType,
} from 'src/app/core/services/map-view.service';
import { PatientService } from 'src/app/core/services/patient.service';
import {
  PatientSelectedForReportView,
  PumpType,
} from '../../component/patient-medication-table-view/patient-medication-table-view.component';
import { Selected_Device_For_Pump_Detail_Page_Key } from '../../component/remaining-time-view/remaining-time-view.component';
import { Selected_Patient_For_Detail_Page_Key } from '../../component/room-card-view/room-card-view.component';

@Component({
  selector: 'app-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.css'],
})
export class MapViewComponent implements OnInit, OnDestroy {
  constructor(
    public patientService: PatientService,
    private _enterpriseService: EnterpriseService,
    private _alarmService: AlarmService,
    private _router: Router,
    public mapViewService: MapViewService
  ) {
    // This is intentionally empty
  }

  wardhierarchy: IHierarchy[] = [];
  currentSideView: string = ViewType.RemainingTimeView;
  unassignedBedSelected = false;
  unassignedSelectedBedDetail: any = {};
  assignedBedSelected = false;
  assignedSelectedBedDetail: any = {};
  medicationsTimeVIew: any[] = [];
  currentWard: Partial<IHierarchy> = {};
  patientAlarms: any[] = [];

  ngOnInit(): void {
    this._enterpriseService.selectedWard.subscribe((ward) => {
      if (!!ward) {
        this.currentWard = ward;
        const requestData = {
          identifier: ward.identifier,
          ent_type: ward.ent_type,
          multilevel: true,
        };

        this.mapViewService.getRoomList(requestData).subscribe((data) => {
          this.wardhierarchy = data;
        });
      }
    });

    this.patientService.medicationFilteredTimeViewObs.subscribe((data) => {
      this.medicationsTimeVIew = data;
    });

    this._alarmService.patientAlarmList.subscribe((data) => {
      this.patientAlarms = data.patientAlarms;
    });

    this.mapViewService.mapViewUIStateObs.subscribe((change) => {
      if (!change.isReassignmentMode) {
        this.currentSideView = change.currentSideView;
        this.unassignedBedSelected = false;
        this.unassignedSelectedBedDetail = {};
        this.assignedBedSelected = false;
        this.assignedSelectedBedDetail = {};
      }
    });
  }

  ngOnDestroy(): void {
    this.mapViewService.clearReassignmentMode();
    this.mapViewService.toggleSideView(ViewType.RemainingTimeView);
  }

  onBedSelect(bedDetail: any) {
    this.mapViewService.toggleSideView(ViewType.PatientView);
    bedDetail['ward'] = this.currentWard.display_text;
    this.mapViewService.handleBedSelection(bedDetail);
    if (bedDetail.patient.id) {
      this.assignedBedSelected = true;
      this.assignedSelectedBedDetail = bedDetail;
    } else {
      this.unassignedBedSelected = true;
      this.unassignedSelectedBedDetail = bedDetail;
    }
  }

  openPatientDetail(id) {
    localStorage.setItem(Selected_Patient_For_Detail_Page_Key, id);
    if (this._router.url === '/ward') {
      this._router.navigate(['ward/patient-detail']);
    }

    if (this._router.url === '/alert') {
      this._router.navigate(['alert/patient-detail']);
    }
  }

  openReportPage(patient) {
    const path = this._getReportPath(patient.pump);
    const patientReportInput = {
      deviceId: patient.device_id,
      medicationId: patient.medication_id,
      patientId: patient.id,
    };
    localStorage.setItem(
      PatientSelectedForReportView,
      JSON.stringify(patientReportInput)
    );
    this._router.navigate([path]);
  }

  _getReportPath(pumpType: string): string {
    let path = '';

    if (pumpType.includes(PumpType.TCI)) {
      path = '/ward/patient/tci-report';
    } else if (pumpType.includes(PumpType.PCA)) {
      path = '/ward/patient/pca-report';
    } else {
      path = '/ward/patient/general-report';
    }

    return path;
  }

  openDetail(item) {
    localStorage.setItem(
      Selected_Device_For_Pump_Detail_Page_Key,
      JSON.stringify(item)
    );

    const path = '/ward/patient/pump-detail/';
    this._router.navigate([path]);
  }
}
