import { Injectable } from '@angular/core';
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';

@Injectable({
  providedIn: 'root',
})
export class ExportService {
  private exportAsPdf: ExportAsConfig;
  private exportAsExcel: ExportAsConfig;
  private exportAsCSV: ExportAsConfig;
  private exportAsWord: ExportAsConfig;
  constructor(private exportAsService: ExportAsService) {}

  exportToPdf(elementId: string, filename: string) {
    this.exportAsPdf = {
      type: 'pdf',
      elementIdOrContent: elementId,
    };
    this.exportAsService.save(this.exportAsPdf, filename).subscribe(() => {
      //empty
    });
  }

  exportToExcel(elementId: string, filename: string) {
    this.exportAsExcel = {
      type: 'xlsx',
      elementIdOrContent: elementId,
    };
    this.exportAsService.save(this.exportAsExcel, filename).subscribe(() => {
      console.log('file downloaded');
    });
  }

  exportToCSV(elementId: string, filename: string) {
    this.exportAsCSV = {
      type: 'csv',
      elementIdOrContent: elementId,
    };
    this.exportAsService.save(this.exportAsCSV, filename).subscribe(() => {
      console.log('file downloaded');
    });
  }

  exportToWord(elementId: string, filename: string) {
    this.exportAsWord = {
      type: 'docx',
      elementIdOrContent: elementId,
    };

    this.exportAsService.save(this.exportAsWord, filename).subscribe(() => {
      console.log('file downloaded');
    });
  }
}
