import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { IResponse } from '../models/HttpRequest';
import { IPatient } from '../models/Patient';
import { RotationService } from './rotation.service';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  constructor(
    private _http: HttpClient,
    private _rotationService: RotationService
  ) {
    this._loadDeviceList();

    //refresh data listener
    this._rotationService.refreshDataObs.subscribe((data) => {
      this._loadDeviceList();
    });
  }

  private _deviceList: BehaviorSubject<IPatient[]> = new BehaviorSubject<
    IPatient[]
  >([]);

  readonly deviceListObs = this._deviceList.asObservable();

  private _loadDeviceList() {
    this._http.post(`/api/v1/device/listDevices`, {}).subscribe(
      (data: IResponse<any>) => {
        this._deviceList.next(data.response.devices);
      },
      (err) => {
        console.log('some error occured.');
      }
    );
  }

  getDeviceObservations(data: {
    patientId: string;
    deviceId: string;
    medicationId: string;
  }) {
    const reqData = {
      patient_id: data.patientId,
      device_id: data.deviceId,
      medication_id: data.medicationId,
    };
    return this._http.post('/api/v1/device/deviceObservations', reqData).pipe(
      map<any, any>((item) => {
        return item.response.deviceObservations;
      })
    );
  }

  getAssociatedDevicesForPatient(patientId: number) {
    return this._http.post(`/api/v1/device/listAssociatedDevices`, {
      id: patientId 
    });
  }

  public loadDevicePatients(deviceId: number) {
    return this._http.post(`/api/v1/device/devicePatients`, {
      device_id: deviceId,
    });
  }

  public loadDevicePressure(deviceId: number) {
    return this._http.post(`/api/v1/device/devicePressures`, {
      device_id: deviceId,
    });
  }

  public fetchDeviceEvent() {
    return this._http.post<any>(
      'https://ifc-test.arachnomesh.com/api/v1/device/listDeviceEvents',
      {}
    );
  }
}
