import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pump-detail-view',
  templateUrl: './pump-detail-view.component.html',
  styleUrls: ['./pump-detail-view.component.css']
})
export class PumpDetailViewComponent implements OnInit {

  constructor() {
    // This is intentionally empty
  }

  ngOnInit(): void {
    // This is intentionally empty
  }

}
