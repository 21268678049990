import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IPatient } from 'src/app/core/models/Patient';
import { TooltipService } from 'src/app/core/services/tooltip.service';
import { PatientAssociationService } from '../../services/patient-association.service';
declare const $: any;

export const Selected_Patient_For_Detail_Page_Key = 'slt_p_dt_id';
@Component({
  selector: 'app-room-card-view',
  templateUrl: './room-card-view.component.html',
  styleUrls: ['./room-card-view.component.css'],
})
export class RoomCardViewComponent implements OnInit {
  constructor(
    private _patientAssociationService: PatientAssociationService,
    private _router: Router,
    private _tooltipService: TooltipService
  ) {
    // This is intentionally empty
  }
  
  @Input() patientDetail: IPatient;

  ngOnInit(): void {
    // This is intentionally empty
  }
      

  openPatientDetail() {
    localStorage.setItem(
      Selected_Patient_For_Detail_Page_Key,
      this.patientDetail.id
    );
    this._tooltipService.close();
    if (this._router.url === '/ward') {
      this._router.navigate(['ward/patient-detail']);
    }

    if (this._router.url === '/alert') {
      this._router.navigate(['alert/patient-detail']);
    }
  }

  getBoxClassString(alarm: string, stale_msg: string) {
    let className = 'content_box';

    if (alarm) {
      return className + ' brdr-red';
    } else if (stale_msg) {
      return className + ' brdr-yellow';
    } else {
      return className;
    }
  }

  selectPatientForAssociation() {
    this._patientAssociationService.selectPatient(this.patientDetail);

    $('.content_box').click(function () {
      $('.content_box').removeClass('active_content');
      $(this).addClass('active_content');
    });
  }

  onHover(e: MouseEvent) {
    const currentTarget = e.currentTarget as HTMLElement;
    const firstChild = currentTarget.firstChild as HTMLElement;
    if(firstChild){
      this._tooltipService.open(firstChild, this.patientDetail);
    }
  }

  onHoverOut() {
    this._tooltipService.close();
  }
}
