import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { IHierarchy } from 'src/app/core/models/Hierarchy';
import { EnterpriseService } from 'src/app/core/services/enterprise.service';
import { PatientService } from 'src/app/core/services/patient.service';
import {
  PatientSelectedForReportView,
  PumpType,
} from 'src/app/modules/ward/component/patient-medication-table-view/patient-medication-table-view.component';
import { UserFeedbackDialogComponent } from 'src/app/modules/ward/component/user-feedback-dialog/user-feedback-dialog.component';
import { Selected_Device_For_Pump_Detail_Page_Key } from 'src/app/modules/ward/component/remaining-time-view/remaining-time-view.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-current-tab-view',
  templateUrl: './current-tab-view.component.html',
  styleUrls: ['./current-tab-view.component.css'],
})
export class CurrentTabViewComponent implements OnInit {
  patientObservable: Subscription;
  displayedColumns: string[] = [
    'pump',
    'drug_type',
    'drug_name',
    'percentage_infused',
    'time_remaining',
    'dose_rate',
    'volume_tbi',
    'volume_infused',
    'message',
  ];
  listProps = {
    page: 0,
    limit: 5000,
    sortBy: '',
    sort: 'asc',
    filter: '',
  };
  dataSource: MatTableDataSource<any[]>;
  @ViewChild(MatSort) sort: MatSort;
  patientDetail: any;
  constructor(
    private patientService: PatientService,
    private _enterpriseService: EnterpriseService,
    private dialog: MatDialog,
    private _router: Router,
    private _langService: TranslateService,
    private _snackBar: MatSnackBar
  ) {
    this._langService
      .get([
        'GLOBAL.Patient_Reassignment_Alert_Message',
        'GLOBAL.Reassignment_Failed_Alert_Msg',
        'GLOBAL.ReAssign_Patient_Dialog_Title',
        'GLOBAL.ReAssign_Patient_Dialog_Msg_Assign',
      ])
      .subscribe((ln) => {
        this.Reassignment_Alert_Msg =
          ln['GLOBAL.Patient_Reassignment_Alert_Message'];
        this.Reassignment_Failed_Alert_Msg =
          ln['GLOBAL.Reassignment_Failed_Alert_Msg'];
        this.ReAssign_Patient_Dialog_Title =
          ln['GLOBAL.ReAssign_Patient_Dialog_Title'];
        this.ReAssign_Patient_Dialog_Msg_Assign =
          ln['GLOBAL.ReAssign_Patient_Dialog_Msg_Assign'];
      });
  }

  private Reassignment_Alert_Msg;
  private Reassignment_Failed_Alert_Msg;
  private ReAssign_Patient_Dialog_Title;
  private ReAssign_Patient_Dialog_Msg_Assign;

  @Input() data;
  roomFormControl = new FormControl();
  bedFormControl = new FormControl();
  selectedRoom: Partial<IHierarchy> = {};
  selectedBed: Partial<IHierarchy> = {};
  currentWard: IHierarchy;
  dataBed: IHierarchy[] = [];
  wardhierarchy: IHierarchy[] = [];
  userFeedBackDialogRef: MatDialogRef<UserFeedbackDialogComponent>;

  ngOnInit(): void {
    this.patientObservable = this.patientService.patientDetailIdObs.subscribe(
      (res) => {
        this.getPatientDetail(res);
      }
    );

    this._enterpriseService.selectedWard.subscribe((ward) => {
      if (!!ward) {
        this.currentWard = ward;
        const requestData = {
          identifier: ward.identifier,
          ent_type: ward.ent_type,
          multilevel: true,
        };
        this.patientService
          .getWardChildrenWithStatus(requestData)
          .subscribe((data) => {
            this.wardhierarchy = data.filter(
              (val) => val.children.filter((t) => !t.isOccupied).length > 0
            );
          });
      }
    });
  }

  getPatientDetail(id) {
    const body = { id: id };
    this.patientService
      .getPatientMedicationOrderDetailById(id, 'current', this.listProps)
      .subscribe((res) => {
        this.dataSource = new MatTableDataSource<any[]>(res.orders);
        if (!!this.sort){
          this.sort.sort({ id: null, start: 'desc', disableClear: false });
        }
        this.dataSource.sort = this.sort;
        this.dataSource.sortData = (data: any[], sort: MatSort) => {
          return data.sort((a, b) => {
            if (sort.direction === 'asc') {
              return this.sortASC(a, b, sort.active);
            }
    
            return this.sortDESC(a, b, sort.active);
          });
        };
      });

    this.patientService.getPatientDetailById(body).subscribe((res) => {
      this.patientDetail = res;
      this.roomFormControl.setValue({
        display_text: this.patientDetail.room,
      });
      this.bedFormControl.setValue({
        display_text: this.patientDetail.bed,
      });
    });
  }

  displayFn(hierarchy: IHierarchy): string {
    return hierarchy && hierarchy.display_text ? hierarchy.display_text : '';
  }

  onRoomSelected(selected: MatAutocompleteSelectedEvent) {
    this.selectedRoom = selected.option.value;

    this.dataBed = this.selectedRoom.children;
  }

  onBedSelected(selected: MatAutocompleteSelectedEvent) {
    this.selectedBed = selected.option.value;
    this.openFeedbackDialog();
  }

  openFeedbackDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: this.ReAssign_Patient_Dialog_Title,
      message: `${this.ReAssign_Patient_Dialog_Msg_Assign} ${this.selectedRoom.display_text}-${this.selectedBed.display_text}?`,
    };

    this.userFeedBackDialogRef = this.dialog.open(
      UserFeedbackDialogComponent,
      dialogConfig
    );

    this.userFeedBackDialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.patientObservable =
          this.patientService.patientDetailIdObs.subscribe((res) => {
            if (!!res) {
              const reqData = {
                id: res,
                point_of_care: this.currentWard.display_text,
                room: this.selectedRoom.display_text,
                bed: this.selectedBed.display_text,
              };
              this.patientService.assignPatient(reqData).subscribe((result) => {
                if (result) {
                  this._snackBar.open(this.Reassignment_Alert_Msg);
                  return;
                }
                this._snackBar.open(this.Reassignment_Failed_Alert_Msg);
              });
            }
          });
      }
    });
  }

  openReportPage(patient) {
    const path = this._getReportPath(patient.pump);

    const patientReportInput = {
      deviceId: patient.device_id,
      medicationId: patient.medication_id,
      patientId: patient.id,
    };
    localStorage.setItem(
      PatientSelectedForReportView,
      JSON.stringify(patientReportInput)
    );
    this.dialog.closeAll();
    this._router.navigate([path]);
  }

  _getReportPath(pumpType: string): string {
    let path = '';

    if (pumpType.includes(PumpType.TCI)) {
      path = '/ward/patient/tci-report';
    } else if (pumpType.includes(PumpType.PCA)) {
      path = '/ward/patient/pca-report';
    } else {
      path = '/ward/patient/general-report';
    }

    return path;
  }

  openDetail(item) {
    localStorage.setItem(
      Selected_Device_For_Pump_Detail_Page_Key,
      JSON.stringify(item)
    );
    const path = '/ward/patient/pump-detail/';
    this.dialog.closeAll();
    this._router.navigate([path]);
  }

  sortASC(a: any, b: any, key: string) {
    return this.sortStringASC(a[key], b[key]);
  }

  sortDESC(a: any, b: any, key: string) {
    return this.sortStringDESC(a[key], b[key]);
  }

  sortStringASC(a: string, b: string) {
    if (a > b) {
      return -1;
    }
    if (a < b) {
      return 1;
    }
    return 0;
  }

  sortStringDESC(a: string, b: string) {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  }
}
