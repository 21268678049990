<div class="map_card" id="card_map">
  <h3>{{roomHierarchy.display_text}}</h3>
  <ng-container *ngFor="let bed of roomHierarchy.children">
    <span id="bed_select" (mouseover)="onHover($event, bed)" (mouseleave)="onHoverOut()" *ngIf="!!bed.patient && !!bed.patient.id"
      [ngClass]="getBoxClassString(bed.isOccupied, bed.patient.alarm, bed.patient.stale_msg)"
      (click)="selectBed(bed, roomHierarchy.display_text)">{{bed.display_text}}
    </span>

    <span id="patient_bed" *ngIf="!bed.patient || !bed.patient.id" [ngClass]="getBoxClassString(bed.isOccupied)"
      (click)="selectBed(bed, roomHierarchy.display_text)">{{bed.display_text}}
    </span>
  </ng-container>
</div>
