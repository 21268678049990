import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ElementRef,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-general-report-chart',
  templateUrl: './general-report-chart.component.html',
  styleUrls: ['./general-report-chart.component.css'],
})
export class GeneralReportChartComponent implements OnInit, OnChanges {
  constructor(
    private container: ElementRef,
    private _langService: TranslateService
  ) {
    this._langService
      .get([
        'HOME.ward-pages-General_Report-Rate',
        'HOME.ward-pages-General_Report-Time'
      ])
      .subscribe((ln) => {
        this.Ward_Pages_General_Report_Rate =
          ln['HOME.ward-pages-General_Report-Rate'];
        this.Ward_Pages_General_Report_Time =
          ln['HOME.ward-pages-General_Report-Time'];
      });
  }

  private Ward_Pages_General_Report_Rate;
  private Ward_Pages_General_Report_Time;

  @Input() data: any[] = [];

  ngOnInit(): void {
    // This is intentionally empty
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        if(propName == 'data'){
          if (this.data.length > 0) {
            d3.selectAll('svg > *').remove();
            this.createGraph();
          }
        }
      }
    }
  }

  createGraph() {
    // set the dimensions and margins of the graph
    const margin = { top: 10, right: 30, bottom: 100, left: 50 };
    const width = 800;
    const height = 300;

    let timeData = d3.map(this.data, function (d) {
      return d.time.split(' ')[1];
    });

    const uniqueTime = new Set();

    timeData.forEach((element) => {
      uniqueTime.add(element);
    });
    timeData = [...uniqueTime];

    let xValuesTicks = timeData.filter((element, i) => {
      let number = Math.floor(timeData.length / 10);

      if (i % number === 0) {
        return true;
      }
    });

    const svg = d3
      .select(this.container.nativeElement)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .attr('viewBox', [0, 0, width, height])
      .attr('style', 'max-width: 100%; height: auto; height: intrinsic;')
      .style('font', '10px sans-serif')
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    const xScale = d3
      .scaleBand()
      .domain(timeData)
      .range([0, width - margin.left - margin.right]);

    const yScale = d3
      .scaleLinear()
      .domain([
        0,
        d3.max(this.data, (d) => {
          return d.rate + (20 * d.rate) / 100;
        }),
      ])
      .range([height - margin.top - margin.bottom, 0]);

    const yAxis = d3.axisLeft(yScale).ticks(5).tickSizeOuter(0);
    const xAxis = d3.axisBottom(xScale).tickValues(xValuesTicks).tickSize(0);

    svg
      .append('g')
      .attr('transform', `translate(0, ${height - margin.bottom} )`)
      .call(xAxis)
      .selectAll('text')
      .style('text-anchor', 'end')
      .style('font-size', '1.5em')
      .attr('transform', 'rotate(-60)');
    svg
      .append('g')
      .call(yAxis)
      .attr('transform', `translate(0, ${margin.top} )`)
      .selectAll('text')
      .style('font-size', '1.5em');

    const barGroup = svg.append('g');
    barGroup
      .selectAll('rect')
      .data(this.data)
      .enter()
      .append('rect')
      .attr('x', (d, i) => {
        return xScale(d.time.split(' ')[1]);
      })
      .attr('y', (d) => {
        return yScale(d.rate);
      })
      .attr('width', xScale.bandwidth() + 1)
      .attr('height', (d) => {
        return height - margin.bottom - yScale(d.rate);
      })
      .attr('fill', '#e1e7e8');

    svg
      .append('g')
      .append('text')
      .attr('x', width / 2 - margin.left - margin.right)
      .attr('y', height - margin.bottom + margin.bottom / 2 + 20)
      .style('fill', '#ffffff')
      .style('font-size', '1.5em')
      .text(this.Ward_Pages_General_Report_Time);

    svg
      .append('g')
      .append('text')
      .style('fill', '#ffffff')
      .attr(
        'transform',
        `translate(${-margin.left + 20},${
          (height - margin.bottom - margin.top) / 2 + margin.top * 4
        }) rotate(270)`
      )
      .style('font-size', '1.5em')

      .text(this.Ward_Pages_General_Report_Rate+' (mL/hr)');
  }
}
