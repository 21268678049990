import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HierarchyFilterComponent } from './component/hierarchy-filter/hierarchy-filter.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { BedSelectorComponent } from './component/bed-selector/bed-selector.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToolTipDirective } from './directive/tool-tip.directive';
import { TooltipOverlayComponent } from './component/tooltip-overlay/tooltip-overlay.component';
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { I18nService } from '../core/services/i18n-service.service';
import { TooltipOverlayMapCardComponent } from './component/tooltip-overlay-map-card/tooltip-overlay-map-card.component';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    HierarchyFilterComponent,
    BedSelectorComponent,
    ToolTipDirective,
    TooltipOverlayComponent,
    TooltipOverlayMapCardComponent,
  ],
  imports: [
    CommonModule,
    MatSelectModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [
    HierarchyFilterComponent,
    BedSelectorComponent,
    ToolTipDirective,
    TooltipOverlayComponent,
  ],
  providers: [{provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {
    horizontalPosition: 'right',
    verticalPosition: 'top',
    duration: 3000,
  }}]
})
export class SharedModule {
  constructor(
    private translate: TranslateService,
    private i18nService: I18nService
  ) {
    this.translate.addLangs(i18nService.getLangs());
    this.i18nService.locale.subscribe((lang) => {
      this.translate.setDefaultLang(lang);
    });
  }
}
