import { Component, Inject, OnInit } from '@angular/core';
import { TOOLTIP_DATA } from '../../constants/injectionTokens';

@Component({
  selector: 'app-tooltip-overlay-map-card',
  templateUrl: './tooltip-overlay-map-card.component.html',
  styleUrls: ['./tooltip-overlay-map-card.component.css'],
})
export class TooltipOverlayMapCardComponent implements OnInit {
  constructor(@Inject(TOOLTIP_DATA) public data: any) {
    // This is intentionally empty
  }

  ngOnInit(): void {
    // This is intentionally empty
  }
}
