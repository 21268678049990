import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-patient-header',
  templateUrl: './patient-header.component.html',
  styleUrls: ['./patient-header.component.css']
})
export class PatientHeaderComponent implements OnInit {

  constructor() {
    // This is intentionally empty
  }

  ngOnInit(): void {
    // This is intentionally empty
  }

}
