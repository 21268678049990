<div id="pdf_container">
  <div class="report-container">
    <div class="chart_heading_div d-flx-spc-btwn mb-3">
      <h1 class="mb-0" id="report-heading">
        {{ "HOME.ward-pages-TCI_Report-TCIReport" | translate }}
      </h1>
      <button id="back_btn" type="button" class="icon_btn hover-effect" (click)="goBack()">
        <img src="../../../../../assets/img/back.png" class="table_icon" alt="Back"/>
      </button>
    </div>

    <div class="report-row1 row"  id="report_row">
      <div class="report_detail col-lg-4">
        <table aria-label="TCI report data">
          <tr>
            <th>{{ "HOME.ward-pages-TCI_Report-Infuser" | translate }}</th>
            <td>{{ deviceObservation.infuser }}</td>
          </tr>
          <tr>
            <th>{{ "HOME.ward-pages-TCI_Report-Patient" | translate }}</th>
            <td>{{ deviceObservation.patient_name }}</td>
          </tr>
          <tr>
            <th>{{ "HOME.ward-pages-TCI_Report-Medication" | translate }}</th>
            <td>{{ deviceObservation.medication }}</td>
          </tr>
          <tr>
            <th>
              {{ "HOME.ward-pages-TCI_Report-Administration" | translate }}
            </th>
            <td>
              {{ deviceObservation.start_time }} -
              {{ deviceObservation.end_time }}
            </td>
          </tr>
        </table>
      </div>

      <div class="chart-component col-lg-8" id="chart_report">
        <div class="chart_report">
          <app-pump-line-chart
            [height]="300"
            [width]="1000"
            [data]="dataSource"
            [yLabel]="'volume_delivered'"
            [yAxisLabel]="'HOME.ward-pages-TCI_Report-Volumedelivered' | translate"
          ></app-pump-line-chart>
        </div>

        <div class="tci_report">
          <div class="chart_report">
            <app-pump-line-chart
              [height]="300"
              [width]="500"
              [data]="dataSource"
              [yLabel]="'rate'"
              [yAxisLabel]="'HOME.ward-pages-TCI_Report-Rate' | translate"
            ></app-pump-line-chart>
          </div>
          <div class="chart_report">
            <app-pump-line-chart
              [height]="300"
              [width]="500"
              [data]="dataSource"
              [yLabel]="'volume_tbi-volume_delivered'"
              [yAxisLabel]="'HOME.ward-pages-TCI_Report-Volumeremaining' | translate"
            ></app-pump-line-chart>
          </div>
        </div>
      </div>
    </div>
    <div class="report-row2">
      <div class="map_table">
        <div class="export_div">
          <p>{{ "HOME.ward-pages-TCI_Report-ExportTo" | translate }}:</p>
          <button
            id="excel_btn"
            type="button"
            class="custom_btn primary_btn mr-3"
            (click)="exportToExcel()"
          >
            {{ "HOME.ward-pages-TCI_Report-Excel" | translate }}
          </button>
          <button
           id="pdf_btn"
            type="button"
            class="custom_btn secondary_btn"
            (click)="exportToPdf()"
          >
            {{ "HOME.ward-pages-TCI_Report-Pdf" | translate }}
          </button>
        </div>
        <table mat-table [dataSource]="dataSource" id="report_table" aria-label="TCI report data">
          <ng-container matColumnDef="Time">
            <th mat-header-cell *matHeaderCellDef>
              {{ "HOME.ward-pages-TCI_Report-Time" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.time }}</td>
          </ng-container>
          <ng-container matColumnDef="Rate">
            <th mat-header-cell *matHeaderCellDef>
              {{ "HOME.ward-pages-TCI_Report-Rate" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.rate }}</td>
          </ng-container>
          <ng-container matColumnDef="Dose">
            <th mat-header-cell *matHeaderCellDef>
              {{ "HOME.ward-pages-TCI_Report-Dose" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.dose }}</td>
          </ng-container>
          <ng-container matColumnDef="VTBI">
            <th mat-header-cell *matHeaderCellDef>
              {{ "HOME.ward-pages-TCI_Report-VTBI" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.volume_tbi }}</td>
          </ng-container>
          <ng-container matColumnDef="VI">
            <th mat-header-cell *matHeaderCellDef>
              {{ "HOME.ward-pages-TCI_Report-VI" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.VI }} &nbsp;&nbsp;
              <span class="float-end">{{ element.volume_delivered }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="Event">
            <th mat-header-cell *matHeaderCellDef>
              {{ "HOME.ward-pages-TCI_Report-Event" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.alarm }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
  </div>
</div>
